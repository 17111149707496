// eslint-disable-next-line import/prefer-default-export
export const enrollmentStats = {
  orgStats: {
    enrollments: 78,
    attempts: 64,
    activeAssessments: 12,
    score_distribution: [
      { percentage: '0%', candidates: 5 },
      { percentage: '10%', candidates: 12 },
      { percentage: '20%', candidates: 23 },
      { percentage: '30%', candidates: 34 },
      { percentage: '40%', candidates: 42 },
      { percentage: '50%', candidates: 54 },
      { percentage: '60%', candidates: 43 },
      { percentage: '70%', candidates: 32 },
      { percentage: '80%', candidates: 25 },
      { percentage: '90%', candidates: 12 },
      { percentage: '100%', candidates: 4 },
    ],
  },
  litmustestStats: {
    enrollments: 578,
    attempts: 312,
    activeAssessments: 43,
    score_distribution: [
      { percentage: '0%', candidates: 31 },
      { percentage: '10%', candidates: 20 },
      { percentage: '20%', candidates: 72 },
      { percentage: '30%', candidates: 72 },
      { percentage: '40%', candidates: 31 },
      { percentage: '50%', candidates: 100 },
      { percentage: '60%', candidates: 60 },
      { percentage: '70%', candidates: 90 },
      { percentage: '80%', candidates: 90 },
      { percentage: '90%', candidates: 30 },
      { percentage: '100%', candidates: 10 },
    ],
  },
};

export const questionStats = {
  'questions_passed': 0,
  'questions_total': 20,
  'questions_failed': 0,
  'questions_ongoing': 0,
};
