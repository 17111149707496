/* eslint-disable no-param-reassign */

import { createAction, mirrorKeysWithPfx, reducerFactory } from '@utils';

// ACTION-TYPES
const pureTypes = {
  FETCH_QUESTIONS_REQUEST: null,
  FETCH_QUESTIONS_SUCCESS: null,
  FETCH_QUESTIONS_FAILURE: null,
  CLEAR_QUESTIONS_LISTING: null,

  UPDATE_QUESTIONS_REQUEST: null,
  UPDATE_QUESTIONS_SUCCESS: null,
  UPDATE_QUESTIONS_FAILURE: null,

  FETCH_QUESTIONS_COUNT_REQUEST: null,
  FETCH_QUESTIONS_COUNT_SUCCESS: null,
  FETCH_QUESTIONS_COUNT_FAILURE: null,
  CLEAR_QUESTIONS_COUNT: null,

  FETCH_ASSESSMENT_COUNT_REQUEST: null,
  FETCH_ASSESSMENT_COUNT_SUCCESS: null,
  FETCH_ASSESSMENT_COUNT_FAILURE: null,
  CLEAR_ASSESSMENT_COUNT: null,

  DELETE_QUESTIONS_REQUEST: null,
  DELETE_QUESTIONS_SUCCESS: null,
  DELETE_QUESTIONS_FAILURE: null,

  RESTORE_QUESTIONS_REQUEST: null,
  RESTORE_QUESTIONS_SUCCESS: null,
  RESTORE_QUESTIONS_FAILURE: null,

  UPLOAD_QUESTIONS_FILE_REQUEST: null,
  UPLOAD_QUESTIONS_FILE_SUCCESS: null,
  UPLOAD_QUESTIONS_FILE_FAILURE: null,

  EXPORT_QUESTIONS_REQUEST: null,
  EXPORT_QUESTIONS_SUCCESS: null,
  EXPORT_QUESTIONS_FAILURE: null,
};
export const types = mirrorKeysWithPfx('questions', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchQuestionsListings: createAction(types.FETCH_QUESTIONS_REQUEST, 'fetchQuestions'),
  fetchQuestionsCount: createAction(types.FETCH_QUESTIONS_COUNT_REQUEST, 'fetchQuestionsCount'),
  clearQuestionsCount: createAction(types.FETCH_QUESTIONS_COUNT_REQUEST),
  fetchAssessmentCount: createAction(types.FETCH_ASSESSMENT_COUNT_REQUEST, 'fetchAssessmentsCount'),
  clearAssessmentCount: createAction(types.CLEAR_ASSESSMENT_COUNT),
  removeQuestionsListings: createAction(types.CLEAR_QUESTIONS_LISTING),
  uploadQuestionsFile: createAction(types.UPLOAD_QUESTIONS_FILE_REQUEST, 'uploadQuestionsFile'),
  updateQuestion: createAction(types.UPDATE_QUESTIONS_REQUEST, 'updateQuestion'),
  deleteQuestion: createAction(types.DELETE_QUESTIONS_REQUEST, 'deleteQuestion'),
  restoreQuestion: createAction(types.RESTORE_QUESTIONS_REQUEST, 'restoreQuestion'),
  exportQuestions: createAction(types.EXPORT_QUESTIONS_REQUEST, 'exportQuestions'),
};

// REDUCER
const INITIAL_STATE = {
  questions: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  currentQuestionsCount: 0,
  assessmentCount: 0,
};

const questionListingsReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_QUESTIONS_SUCCESS]: (state, action) => {
    state.questions = action.payload;
  },
  [types.FETCH_QUESTIONS_COUNT_SUCCESS]: (state, action) => {
    state.currentQuestionsCount = action.payload.count;
  },
  [types.CLEAR_QUESTIONS_LISTING]: (state) => {
    state.questions = INITIAL_STATE.questions;
  },
  [types.CLEAR_QUESTION_COUNT]: (state) => {
    state.currentQuestionsCount = 0;
  },
  [types.CLEAR_ASSESSMENT_COUNT]: (state) => {
    state.assessmentCount = 0;
  },
  [types.FETCH_ASSESSMENT_COUNT_SUCCESS]: (state, { payload }) => {
    state.assessmentCount = payload.count;
  },
});

export default questionListingsReducer;
