import { takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { types } from './ducks';

export default function* questionsDetailsSagas() {
  yield takeEvery(types.FETCH_QUESTION_DETAILS_REQUEST, fetchApiSaga);
  yield takeLatest(types.REEVALUATE_QUESTION_REQUEST, fetchApiSaga);
  yield takeLatest(types.REVIEW_QUESTION_REQUEST, fetchApiSaga);
}
