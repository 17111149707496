import { createBrowserHistory } from 'history';

export const browserHistory = createBrowserHistory();

export const updateLocation = (url) => {
  browserHistory.push(url, { from: window.location.pathname });
};

export const updateLocationWithQueryParams = (url) => {
  browserHistory.replace(url, { from: window.location.pathname });
};
