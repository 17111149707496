import { ListItem } from '@material-ui/core';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  navLinks: {
    color: '#fff',
    textDecoration: 'none',
  },
  listIcon: {
    minWidth: theme.spacing(4),
  },
  listItem: {
    margin: theme.spacing(1.25, 0),
    '& span': {
      fontSize: '.812rem',
      lineHeight: '20px',
    },
  },
}));

export const StyledListItem = withStyles((theme) => ({
  root: {
    width: 'auto',
    paddingTop: 6,
    paddingBottom: 6,
    margin: 'auto 6px',
    borderRadius: 5,
    color: alpha(theme.palette.light.main, 0.6),
    '&:hover': {
      color: theme.palette.light.main,
      '& .MuiListItemIcon-root': {
        color: theme.palette.light.main,
      },
    },
    '& .MuiListItemIcon-root': {
      color: alpha(theme.palette.light.main, 0.6),
    },
  },
  selected: {
    backgroundColor: `${theme.palette.light.main} !important`,
    color: theme.palette.primary.bright,
    '& .MuiListItemIcon-root': { color: theme.palette.primary.bright },
    '& .MuiListItemText-root span': { fontWeight: 600 },
    '&:hover': {
      color: theme.palette.primary.bright,
      '& .MuiListItemIcon-root': { color: theme.palette.primary.bright },
    },
  },
}))(ListItem);
