import { combineReducers } from 'redux';

import rubricTemplatesListingReducer from './listing/ducks';
import rubricTemplatesCreateReducer from './create/ducks';
import rubricTemplateDetailsReducer from './details/ducks';

export default combineReducers({
  listings: rubricTemplatesListingReducer,
  create: rubricTemplatesCreateReducer,
  details: rubricTemplateDetailsReducer,
});
