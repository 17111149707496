import React, { lazy, Suspense } from 'react';

import SideNavLayout from 'layouts/SideNavLayout';
import FormLayout from 'layouts/FormLayout';
import ErrorBoundary from './ErrorBoundary';

const LoginLazy = lazy(() => import('containers/login'));
const ResetPasswordLazy = lazy(() => import('containers/reset-password'));
const VerifyAccountLazy = lazy(() => import('containers/verify-account'));
const SignupLazy = lazy(() => import('containers/signup'));
const AssessmentDetailsLazy = lazy(() => import('containers/templates/details'));
const TemplatesListingsLazy = lazy(() => import('containers/templates/listings'));
const EditTemplateLazy = lazy(() => import('containers/templates/edit'));
const CreateTemplateLazy = lazy(() => import('containers/templates/create'));
const DashboardLazy = lazy(() => import('containers/dashboard'));
const ReportsDetailsLazy = lazy(() => import('containers/reports/details'));
const ReportsListingsLazy = lazy(() => import('containers/reports/listings'));
const RubricEvaluationLazy = lazy(() => import('containers/candidate-report/components/EvaluateRubric/'));
const QuestionsListingLazy = lazy(() => import('containers/questions/listing'));
const QuestionsCreateLazy = lazy(() => import('containers/questions/create'));
const QuestionDetailsLazy = lazy(() => import('containers/questions/details'));
const QuestionEditLazy = lazy(() => import('containers/questions/edit'));
const TeamLazy = lazy(() => import('containers/team'));
const ProfileLazy = lazy(() => import('containers/profile'));
const BillingLazy = lazy(() => import('containers/billing'));
const AssessmentsDetailssLazy = lazy(() => import('containers/assessments/details'));
const AssessmentsListingsLazy = lazy(() => import('containers/assessments/listings'));
const CandidateReportLazy = lazy(() => import('containers/candidate-report'));
const CheckoutLazy = lazy(() => import('containers/checkout'));
const RubricTemplatesListingLazy = lazy(() => import('containers/rubric-templates/listing'));
const RubricTemplateCreateLazy = lazy(() => import('containers/rubric-templates/create'));
const RubricTemplateDetailsLazy = lazy(() => import('containers/rubric-templates/details'));
const RubricTemplateEditLazy = lazy(() => import('containers/rubric-templates/edit'));

const withSuspense = ({
  component: WrappedComponent,
  layout: SiteLayout,
}) => (props) => (
  <>
    <ErrorBoundary>
      <Suspense fallback={<SiteLayout loading />}>
        <SiteLayout>
          <WrappedComponent {...props} />
        </SiteLayout>
      </Suspense>
    </ErrorBoundary>
  </>
);

export const Login = withSuspense({
  component: LoginLazy, layout: FormLayout,
});
export const ResetPassword = withSuspense({
  component: ResetPasswordLazy, layout: FormLayout,
});
export const VerifyAccount = withSuspense({
  component: VerifyAccountLazy, layout: FormLayout,
});
export const Signup = withSuspense({
  component: SignupLazy, layout: FormLayout,
});
export const AssessmentDetails = withSuspense({
  component: AssessmentDetailsLazy, layout: SideNavLayout,
});
export const TemplatesListings = withSuspense({
  component: TemplatesListingsLazy, layout: SideNavLayout,
});
export const EditTemplate = withSuspense({
  component: EditTemplateLazy, layout: SideNavLayout,
});
export const CreateTemplate = withSuspense({
  component: CreateTemplateLazy, layout: SideNavLayout,
});
export const CandidateReport = withSuspense({
  component: CandidateReportLazy, layout: SideNavLayout,
});
export const Dashboard = withSuspense({
  component: DashboardLazy, layout: SideNavLayout,
});
export const ReportsDetails = withSuspense({
  component: ReportsDetailsLazy, layout: SideNavLayout,
});
export const ReportsListings = withSuspense({
  component: ReportsListingsLazy, layout: SideNavLayout,
});
export const QuestionsListing = withSuspense({
  component: QuestionsListingLazy, layout: SideNavLayout,
});
export const QuestionsCreate = withSuspense({
  component: QuestionsCreateLazy, layout: SideNavLayout,
});
export const QuestionDetails = withSuspense({
  component: QuestionDetailsLazy, layout: SideNavLayout,
});
export const QuestionEdit = withSuspense({
  component: QuestionEditLazy, layout: SideNavLayout,
});
export const RubricEvaluation = withSuspense({
  component: RubricEvaluationLazy, layout: SideNavLayout,
});
export const Team = withSuspense({
  component: TeamLazy, layout: SideNavLayout,
});
export const Profile = withSuspense({
  component: ProfileLazy, layout: SideNavLayout,
});
export const Billing = withSuspense({
  component: BillingLazy, layout: SideNavLayout,
});
export const AssessmentsListings = withSuspense({
  component: AssessmentsListingsLazy, layout: SideNavLayout,
});
export const AssessmentsDetails = withSuspense({
  component: AssessmentsDetailssLazy, layout: SideNavLayout,
});

export const Checkout = withSuspense({
  component: CheckoutLazy, layout: SideNavLayout,
});

export const RubricTemplatesListing = withSuspense({
  component: RubricTemplatesListingLazy, layout: SideNavLayout,
});

export const RubricTemplatesCreate = withSuspense({
  component: RubricTemplateCreateLazy, layout: SideNavLayout,
});

export const RubricTemplatesDetails = withSuspense({
  component: RubricTemplateDetailsLazy, layout: SideNavLayout,
});

export const RubricTemplatesEdit = withSuspense({
  component: RubricTemplateEditLazy, layout: SideNavLayout,
});
