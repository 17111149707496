import { createSelector } from 'reselect';
import _intersection from 'lodash/intersection';

import { getActionKey } from '../utils';

const selectErrorState = (state) => state.error;

const selectAction = (_, actionType) => actionType;
const selectActions = (_, actionTypes) => actionTypes;

/**
 * Check if a certain action has an associated error.
 *
 * @param {Object} state -
 * @param {Array} actions - An array of actionTypes
 * @returns {Boolean}
 */
export const selectHasError = createSelector(
  [selectErrorState, selectActions],
  (errorState, actionTypes) => (
    _intersection(
      Object.keys(errorState), actionTypes.map(getActionKey),
    ).length !== 0
  ),
);

/**
 * Get the full error of a certain action.
 *
 * @param {Object} state -
 * @param {string} action - An actionType string
 * @returns {Object}
 */
export const selectErrorStatus = createSelector(
  [selectErrorState, selectAction],
  (errorState, actionType) => (
    errorState[getActionKey(actionType)]
  ),
);
