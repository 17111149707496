import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.bright,
    minHeight: '100vh',
  },
  circularProgressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',

    '& .MuiCircularProgress-colorPrimary': {
      color: 'white',
    },
  },
}));
