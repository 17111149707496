import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  fourOhFour: {
    fontWeight: 600,
    fontStyle: 'italic',
    color: theme.palette.primary.main,
  },
  missing: {
    fontWeight: 600,
  },
  root: {
    height: '100vh',
    textAlign: 'center',
  },
  logoutLink: {
    background: 'no-repeat',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    fontWeight: 100,
    color: 'navy',
    textDecoration: 'underline',
  },
}));
