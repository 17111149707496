/* eslint-disable no-param-reassign */

import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

// ACTION-TYPES
const pureTypes = {
  FETCH_QUESTIONS_STATS_REQUEST: null,
  FETCH_QUESTIONS_STATS_SUCCESS: null,
  FETCH_QUESTIONS_STATS_FAILURE: null,
  CLEAR_QUESTIONS_STATS: null,
  FETCH_LT_STATS_REQUEST: null,
  FETCH_LT_STATS_SUCCESS: null,
  FETCH_LT_STATS_FAILURE: null,
  CLEAR_LT_STATS: null,
  FETCH_ORG_STATS_REQUEST: null,
  FETCH_ORG_STATS_SUCCESS: null,
  FETCH_ORG_STATS_FAILURE: null,
  CLEAR_ORG_STATS: null,
  FETCH_ACTIVE_ASSESSMENTS_REQUEST: null,
  FETCH_ACTIVE_ASSESSMENTS_SUCCESS: null,
  FETCH_ACTIVE_ASSESSMENTS_FAILURE: null,
};
export const types = mirrorKeysWithPfx('dashboards', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchQuestionsStats: createAction(types.FETCH_QUESTIONS_STATS_REQUEST, 'fetchQuestionsStats'),
  clearQuestionsStats: createAction(types.CLEAR_QUESTIONS_STATS),
  fetchLTStats: createAction(types.FETCH_LT_STATS_REQUEST, 'fetchLTStats'),
  clearLTStats: createAction(types.CLEAR_LT_STATS),
  fetchOrgStats: createAction(types.FETCH_ORG_STATS_REQUEST, 'fetchOrgStats'),
  clearOrgStats: createAction(types.CLEAR_ORG_STATS),
};

// REDUCER
const INITIAL_STATE = {
  questionsStats: {
    questionsTotal: 0,
    questionsPassed: 0,
    questionsFailed: 0,
    questionsOngoing: 0,
  },
  monthlyContributions: {},
  ltStats: {
    enrollments: -1,
    attempts: -1,
    activeAssessments: -1,
    scoreDistribution: [
      { percentage: '0%', candidates: -1 },
      { percentage: '10%', candidates: -1 },
      { percentage: '20%', candidates: -1 },
      { percentage: '30%', candidates: -1 },
      { percentage: '40%', candidates: -1 },
      { percentage: '50%', candidates: -1 },
      { percentage: '60%', candidates: -1 },
      { percentage: '70%', candidates: -1 },
      { percentage: '80%', candidates: -1 },
      { percentage: '90%', candidates: -1 },
      { percentage: '100%', candidates: -1 },
    ],
  },
  orgStats: {
    enrollments: -1,
    attempts: -1,
    activeAssessments: -1,
    scoreDistribution: [
      { percentage: '0%', candidates: -1 },
      { percentage: '10%', candidates: -1 },
      { percentage: '20%', candidates: -1 },
      { percentage: '30%', candidates: -1 },
      { percentage: '40%', candidates: -1 },
      { percentage: '50%', candidates: -1 },
      { percentage: '60%', candidates: -1 },
      { percentage: '70%', candidates: -1 },
      { percentage: '80%', candidates: -1 },
      { percentage: '90%', candidates: -1 },
      { percentage: '100%', candidates: -1 },
    ],
  },
};

const detailsReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_QUESTIONS_STATS_SUCCESS]: (state, action) => {
    state.questionsStats = action.payload.questionStats;
    state.monthlyContributions = action.payload.monthlyContributions;
  },
  [types.CLEAR_QUESTIONS_STATS]: (state) => {
    state.questionsStats = INITIAL_STATE.questionsStats;
    state.monthlyContributions = INITIAL_STATE.monthlyContributions;
  },
  [types.FETCH_LT_STATS_SUCCESS]: (state, action) => {
    state.ltStats = action.payload;
  },
  [types.CLEAR_LT_STATS]: (state) => {
    state.ltStats = INITIAL_STATE.ltStats;
  },
  [types.FETCH_ORG_STATS_SUCCESS]: (state, action) => {
    state.orgStats = action.payload;
  },
  [types.CLEAR_ORG_STATS]: (state) => {
    state.orgStats = INITIAL_STATE.orgStats;
  },
});

export default detailsReducer;
