import faker from '@faker-js/faker';
import {
  random, range, sample, sampleSize,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { PLATFORM_NAME } from 'core/constants/organization';

const DIFFICULTY_OPTIONS = ['Easy', 'Medium', 'Hard'];
const CATEGORY_OPTIONS = ['Objective', 'Coding'];
const QUALITY_STATUS_OPTIONS = ['Passed', 'Failed', 'Ongoing'];
const POSSIBLE_TAGS = [
  'OOP',
  'DSA',
  'problem solving',
  'general ability',
  '101',
];
const ORGANIZATION_OPTIONS = ['shared', 'others'];

function getRandomQuestion(id) {
  const metadata = {
    sample: {},
    staff: {},
  };
  let possibleScore = 1;
  let totalPossibleScore = 1;
  let rubricPossibleScore = null;
  let isRandomized = false;
  let qualityCheckScore = null;
  let qualityCheckStatus = 'None';
  let subCategory = null;

  const category = sample(CATEGORY_OPTIONS);

  if (category === 'Coding') {
    // Generate random number int 0 or 1
    const isDesignProblem = random(0, 1) === 1;
    if (isDesignProblem) {
      subCategory = 'design_problem';
      rubricPossibleScore = random(1, 10);
      totalPossibleScore += rubricPossibleScore;
    } else {
      range(1, random(2, 5)).forEach((testCase) => {
        metadata.sample[testCase] = {
          input: `Sample Input ${testCase}`,
          output: `Sample Output ${testCase}`,
        };
        metadata.staff[testCase] = {
          input: `Staff Input ${testCase}`,
          output: `Staff Output ${testCase}`,
        };
      });
      possibleScore = Object.keys(metadata.sample).length + Object.keys(metadata.staff).length;
      totalPossibleScore = possibleScore;
    }
  } else {
    isRandomized = random(0, 1) === 1;
    if (isRandomized) {
      subCategory = 'randomized';
      qualityCheckScore = random(0, 100);
      qualityCheckStatus = sample(QUALITY_STATUS_OPTIONS);
    }
  }

  return {
    id,
    created: faker.date.past(2).toISOString(),
    content: `<problem>\n  <multiplechoiceresponse>\n    <p>${faker.lorem.paragraph()}</p>\n    \n    <choicegroup type="MultipleChoice">\n      <choice correct="true">Incorrect</choice>\n      <choice correct="false">Correct</choice>\n    </choicegroup>\n  </multiplechoiceresponse>\n</problem>`,
    estimated_time: null,
    title: faker.lorem.words(random(2, 10)),
    short_description: faker.lorem.lines(1),
    question_uuid: uuidv4(),
    question_tags: JSON.stringify(
      sampleSize(POSSIBLE_TAGS, random(1, 3, false)),
    ),
    category,
    difficulty: sample(DIFFICULTY_OPTIONS),
    metadata: JSON.stringify(metadata),
    possible_score: possibleScore,
    is_deleted: false,
    assessment_templates: [],
    organization: sample(ORGANIZATION_OPTIONS),
    platform: PLATFORM_NAME,
    total_possible_score: totalPossibleScore,
    rubric_possible_score: rubricPossibleScore,
    is_randomized: isRandomized,
    quality_check_score: qualityCheckScore,
    quality_check_status: qualityCheckStatus,
    sub_category: subCategory,
  };
}

export const questionsList = range(1, 21).map((number) => getRandomQuestion(number)).sort(
  ({ id: aId }, { id: bId }) => bId - aId,
);

export const questionCreatedResponse = () => getRandomQuestion(1);

export const questionUpdatedResponse = () => getRandomQuestion(1);

export const questionDeletedResponse = undefined;

export const addAssessmentTemplate = (
  questionId,
  { id: assessmentTemplateId, title },
) => {
  questionsList
    .find(({ id }) => id === questionId)
    .assessment_templates.push({
      id: assessmentTemplateId,
      title,
    });
};
