import * as Yup from 'yup';

import questionTypes, { questionSubTypes } from 'core/utils/question-types';
import feedbackOptions from 'core/utils/feedback-options';
import automatedCriterias from 'core/constants/automatedCriterias';
import rubricScoringMethods from 'core/constants/rubricScoringMethods';

export const typeValidator = {
  [questionTypes.Objective]: {
    statement: Yup
      .string()
      .when('subCategory', {
        is: (subCategory) => subCategory === questionSubTypes.Objective.Randomized,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Statement is required').min(2),
      }),
    options: Yup.array()
      .of(
        Yup.object()
          .shape({
            name: Yup.string().required('Required'),
            value: Yup.string().required('Required'),
          }),
      )
      .when('subCategory', {
        is: (subCategory) => subCategory === questionSubTypes.Objective.Randomized,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => (
          schema.min(2, 'At least 2 options should be entered')
            .test({
              name: 'one-true-correct',
              message: 'At least 1 correct options should be entered',
              test: (optionsList) => optionsList.filter((optionItem) => optionItem.name === 'Correct').length,
            })
            .test({
              name: 'correct-incorrect-match',
              message: 'Any of the two options should not be same',
              test: (optionsList) => {
                const result = optionsList.map((optionItem) => optionItem.value);
                return result.length === new Set(result).size;
              },
            })
        ),
      }),
  },
  [questionTypes.Coding]: {
    statement: Yup.string().required('Statement is required').min(2),
    subCategory: Yup.string(),
    possibleScore: Yup.number().when('subCategory', {
      is: (subCategory) => subCategory === questionSubTypes.Coding.DesignProblem,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.min(1, 'The total score must be at least 1.')
        .max(100, 'Total Score should not exceed 100'),
    }),
    testCases: Yup.array()
      .of(
        Yup.object()
          .shape({
            type: Yup.string().required('Type is Required'),
            input: Yup.string().required('Input is Required'),
            output: Yup.string().required('Output is Required'),
            points: Yup.number()
              .min(1, 'Test case should have at least 1 point')
              .test('check-points', 'Points cannot exceed the total possible score', function checkPoints(value) {
                const score = this.options.context.possibleScore;
                return value <= score;
              }),
          }),
      ).when('subCategory', {
        is: (subCategory) => subCategory === questionSubTypes.Coding.DesignProblem,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.min(1, 'At least 1 test case should be entered')
          .test('sum-of-points', 'The sum of test case points must be equal to the total score',
            function sumOfTestCasesPoints(testCases) {
              const { possibleScore } = this.parent;
              const totalPoints = testCases
                .reduce((sum, testCase) => sum + (testCase?.points || 0), 0);
              return totalPoints === possibleScore;
            }),
      }),
    rubricCriterias: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required('Name is Required'),
          prompt: Yup.string().required('Prompt is Required'),
          feedback: Yup.string().required('Required'),
          isAutomated: Yup.boolean(),
          automatedCriteria: Yup.string().when(['isAutomated', 'name'], {
            is: (isAutomated, name) => {
              if (isAutomated && (!name)) {
                return true;
              }
              return false;
            },
            then: Yup.string().required('Select at least one criteria'),
            otherwise: Yup.string().notRequired(),
          }),
          options: Yup.array().min(1, 'There should be at least 1 option').of(
            Yup.object().shape({
              label: Yup.string().required('Option Name is Required'),
              explanation: Yup.string().required('Explanation is Required'),
              points: Yup.number()
                .integer('Must be an integer')
                .required('Points are required')
                .min(0, 'Option should have greater than or equal to 0 points')
                .max(100, 'Option should have at most 100 points'),
            }),
          )
            .test({
              name: 'same-options-match',
              message: 'Any two option name should not be same',
              test: (optionsList) => {
                if (!optionsList) return true;
                const result = optionsList.map((option) => option.label);
                return result.length === new Set(result).size;
              },
            })
            .test({
              name: 'same-options-points-match',
              message: 'Any two option points should not be same',
              test: (optionsList) => {
                if (!optionsList) return true;
                const result = optionsList.map((option) => option.points);
                return result.length === new Set(result).size;
              },
            })
            .test({
              name: 'total-more-than-zero',
              message: 'There should be at least 1 option with more than 0 points',
              test: (optionsList) => {
                const result = optionsList.reduce(
                  (total, option) => total + parseInt(option.points),
                  0,
                );
                return result > 0;
              },
            }),
        }),
      )
      .test({
        name: 'same-criteria-match',
        message: 'Any two criteria name should not be same',
        test: (criteriaList) => {
          const result = criteriaList.map((criteriaItem) => criteriaItem.label);
          return result.length === new Set(result).size;
        },
      })
      .test({
        name: 'same-auto-criteria-match',
        message: 'Any two automated criteria should not be same',
        test: (criteriaList) => {
          const result = criteriaList
            .filter((criteria) => criteria.isAutomated)
            .map((criteria) => criteria.automatedCriteria || criteria.name);
          return result.length === new Set(result).size;
        },
      }),
  },
};

export const recalculateTestCasesPoints = (newPossibleScore, testCases, setFieldValue) => {
  const numTestCases = testCases.length;
  const initialPointsPerCase = parseFloat((newPossibleScore / numTestCases).toFixed(2));
  const testCasePoints = Array(numTestCases).fill(initialPointsPerCase);
  const possibleScore = parseFloat(Number(newPossibleScore).toFixed(2));

  let sum = parseFloat(testCasePoints.reduce((acc, curr) => acc + curr, 0).toFixed(2));
  while (sum !== possibleScore) {
    if (sum < possibleScore) {
      testCasePoints[0] = parseFloat((testCasePoints[0] + 0.01).toFixed(2));
    } else {
      testCasePoints[0] = parseFloat((testCasePoints[0] - 0.01).toFixed(2));
    }
    sum = parseFloat(testCasePoints.reduce((acc, curr) => acc + curr, 0).toFixed(2));
  }

  const updatedTestCases = testCases.map((testCase, index) => ({
    ...testCase,
    points: testCasePoints[index],
  }));

  setFieldValue('testCases', [...updatedTestCases]);
};

export default class QuestionUtils {
  static addOption(
    type,
    options,
    setFieldValue,
  ) {
    if (
      type === 'Correct'
      && options.filter((e) => e.name === 'Correct').length
    ) {
      return;
    }
    const newOptions = ([
      ...options,
      {
        name: `${type}`,
        value: '',
      },
    ]);
    setFieldValue('options', newOptions);
  }

  static removeOption(
    id,
    options,
    setFieldValue,
  ) {
    options.splice(id, 1);
    setFieldValue('options', options);
  }

  static handleOptionChange(
    e,
    options,
    setFieldValue,
  ) {
    const index = e.target.id;
    const newOptions = options;
    newOptions[index].value = e.target.value;
    setFieldValue('options', newOptions);
  }

  static addRubricCriteria(
    criterias,
    setFieldValue,
  ) {
    const newRubricCriteria = [
      ...criterias,
      {
        label: '',
        prompt: '',
        feedback: feedbackOptions[0].value,
        isAutomated: false,
        automatedCriteria: '',
        options: [{
          label: '',
          points: 0,
          explanation: '',
        }],
      },
    ];
    setFieldValue('rubricCriterias', newRubricCriteria);
  }

  static removeRubricCriteria(
    id,
    criterias,
    setFieldValue,
  ) {
    criterias.splice(id, 1);
    setFieldValue('rubricCriterias', criterias);
  }

  static clearRubricCriteria(
    setFieldValue,
  ) {
    setFieldValue('rubricCriterias', []);
  }

  static addRubricCriteriaOption(
    criteriaId,
    criterias,
    setFieldValue,
  ) {
    const newRubricCriteria = [...criterias];
    if (newRubricCriteria[criteriaId].options) {
      newRubricCriteria[criteriaId].options = [
        ...newRubricCriteria[criteriaId].options,
        {
          'label': '',
          'points': 0,
          'explanation': '',
        },
      ];
    } else {
      newRubricCriteria[criteriaId].options = [
        {
          'label': '',
          'points': 0,
          'explanation': '',
        },
      ];
    }
    setFieldValue('rubricCriterias', newRubricCriteria);
  }

  static removeRubricCriteriaOption(
    criteriaId,
    optionId,
    criterias,
    setFieldValue,
  ) {
    const newRubricCriterias = JSON.parse(JSON.stringify(criterias));
    newRubricCriterias[criteriaId].options.splice(optionId, 1);
    setFieldValue('rubricCriterias', newRubricCriterias);
  }

  static handleRubricCriteriaChange(
    type,
    index,
    e,
    criterias,
    setFieldValue,
  ) {
    const newRubricCriterias = JSON.parse(JSON.stringify(criterias));

    if (type === 'isAutomated') {
      newRubricCriterias[index][type] = !newRubricCriterias[index][type];
    } else if (type === 'automatedCriteria') {
      newRubricCriterias[index][type] = e.target.value;
      newRubricCriterias[index] = {
        ...newRubricCriterias[index],
        ...automatedCriterias[e.target.value],
      };
    } else {
      newRubricCriterias[index][type] = e.target.value;
    }
    setFieldValue('rubricCriterias', newRubricCriterias);
  }

  static handleRubricCriteriaOptionChange(
    type,
    index,
    optionIndex,
    e,
    criterias,
    setFieldValue,
  ) {
    const { value } = e.target;

    const newRubricCriterias = JSON.parse(JSON.stringify(criterias));
    newRubricCriterias[index].options[optionIndex][type] = value;

    setFieldValue('rubricCriterias', newRubricCriterias);
  }

  static addTestCase(
    type,
    testCases,
    possibleScore,
    setFieldValue,
  ) {
    const testCasePoints = (possibleScore / (testCases.length + 1)).toFixed(2);
    const newTestCase = {
      input: '',
      output: '',
      points: testCasePoints,
      type: `${type}`,
    };
    const updatedTestCases = testCases.map((testCase) => ({
      ...testCase,
      points: testCasePoints,
    }));
    updatedTestCases.push(newTestCase);
    const newPossibleScore = !possibleScore ? 0 : possibleScore;
    recalculateTestCasesPoints(newPossibleScore, updatedTestCases, setFieldValue);
  }

  static removeTestCase(
    id,
    testCases,
    setFieldValue,
  ) {
    testCases.splice(id, 1);
    setFieldValue('testCases', testCases);
  }

  static handleTestCaseChange(
    type,
    e,
    testCases,
    setFieldValue,
  ) {
    const index = e.target.id;
    const newTestCase = testCases;
    newTestCase[index][type] = e.target.value;
    setFieldValue('testCases', testCases);
  }

  static handleTypeChange(
    value,
    setQuestionType,
    setFieldValue,
  ) {
    setQuestionType(value);
    setFieldValue('questionType', value);
    setFieldValue('possibleScore', 1);
    setFieldValue('subCategory', 'None');
  }

  static handleSubTypeChange(
    value,
    setFieldValue,
  ) {
    setFieldValue('possibleScore', 1);
    setFieldValue('testCases', []);
    setFieldValue('subCategory', value);
  }
}

export const calculatePossibleRubricScore = (rubricCriterias) => {
  const possibleRubricScore = rubricCriterias?.reduce(
    (total, criteria) => (
      criteria?.options?.length > 0
        ? total + Math.max(...criteria?.options?.map(
          (option) => +option.points,
        ))
        : total
    ),
    0,
  );
  return possibleRubricScore;
};

export const calculateScoringMethod = (rubricCriterias) => {
  const automatedCount = rubricCriterias.reduce((count, criteria) => (
    count + (criteria.isAutomated ? 1 : 0)
  ), 0);

  let scoringMethod;
  if (automatedCount === rubricCriterias.length) {
    scoringMethod = rubricScoringMethods.Automated;
  } else if (automatedCount === 0) {
    scoringMethod = rubricScoringMethods.Manual;
  } else {
    scoringMethod = rubricScoringMethods.Mixed;
  }

  return scoringMethod;
};

export const calculatePossibleTestCasesScore = (testCases) => {
  const possibleScore = testCases?.reduce(
    (total, testCase) => (
      total + +testCase.points
    ),
    0,
  );
  return Math.round(possibleScore);
};

export const randomizedQuestionDefaultCode = [
  {
    id: 1,
    readOnly: true,
    data: (
      'import random\n'
      + '\nfrom typing import List, Any\n'
      + '\n# Import necessary modules here'
      + '\n# **********'
    ),
  },
  {
    id: 2,
    readOnly: false,
    data: 'pass',
  },
  {
    id: 3,
    readOnly: true,
    data: (
      '# **********\n'
      + '\n# Define global variables for random value ranges\n# Define any other required global variables'
      + '\n# DEFINE RANDOM VARIABLES HERE:'
      + '\n# **********'
    ),
  },
  {
    id: 4,
    readOnly: false,
    data: 'random_value1 = random.randint(1, 3)\nrandom_value2 = random.randint(2, 5)',
  },
  {
    id: 5,
    readOnly: true,
    data: (
      '# **********\n'
      + '\n# Function to calculate correct answer\ndef get_correct_answer('
      + '\n  # ADD ALL YOUR RANDOM VARIABLES HERE'
      + '\n  # **********'
    ),
  },
  {
    id: 6,
    readOnly: false,
    indentValue: 1,
    data: (
      '  random_value1,'
      + '\n  random_value2,'
    ),
  },
  {
    id: 7,
    readOnly: true,
    data: (
      '  # **********\n'
      + ') -> Any:'
      + '\n  # Implement logic to generate correct answer using the random values generated above'
      + '\n  # ONLY MAKE CHANGES HERE:'
      + '\n  # **********'
    ),
  },
  {
    id: 8,
    readOnly: false,
    indentValue: 1,
    data: '  pass',
  },
  {
    id: 9,
    readOnly: true,
    data: (
      '  # **********\n'
      + '\n# Function to calculate incorrect answers\ndef get_incorrect_answers(correct_answer: Any) -> List[Any]:'
      + '\n  # Implement logic to generate incorrect answers based on the correct answer and the random values'
      + '\n  # ONLY MAKE CHANGES HERE:'
      + '\n  # **********'
    ),
  },
  {
    id: 10,
    readOnly: false,
    indentValue: 1,
    data: '  pass',
  },
  {
    id: 11,
    readOnly: true,
    data: '  # **********',
  },
  {
    id: 12,
    readOnly: true,
    data: (
      '\n# Define your options here using the get_correct_answer and get_incorrect_answers'
      + '\n# DEFINE OPTIONS HERE:'
      + '\n# **********'
    ),
  },
  {
    id: 13,
    readOnly: false,
    data: (
      'ans = get_correct_answer(\n'
      + '  # PASS ALL YOUR RANDOM VARIABLES HERE\n'
      + '  random_value1,\n'
      + '  random_value2,\n'
      + ')\n'
      + '\nincorrect_answers = get_incorrect_answers(ans)'
      + '\nopt1 = incorrect_answers[0]'
      + '\nopt2 = incorrect_answers[1]'
      + '\nopt3 = incorrect_answers[2]'
    ),
  },
  {
    id: 14,
    readOnly: true,
    data: '# **********',
  },
];

export const randomizedQuestionHTML = (
  `<p>`
  + `\n  Question Statement`
  + `\n</p>`
  + `\n<multiplechoiceresponse>`
  + `\n  <choicegroup type="MultipleChoice" shuffle="true">`
  + `\n    <choice correct="true">$ans</choice>`
  + `\n    <choice correct="false">$opt1</choice>`
  + `\n    <choice correct="false">$opt2</choice>`
  + `\n    <choice correct="false">$opt3</choice>`
  + `\n  </choicegroup>`
  + `\n</multiplechoiceresponse>`
);
