/* eslint-disable no-param-reassign */

import { isEqual } from 'lodash';
import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

// ACTION-TYPES
const pureTypes = {
  ENROLL_CANDIDATES_REQUEST: null,
  ENROLL_CANDIDATES_SUCCESS: null,
  ENROLL_CANDIDATES_FAILURE: null,

  UNENROLL_CANDIDATES_REQUEST: null,
  UNENROLL_CANDIDATES_SUCCESS: null,
  UNENROLL_CANDIDATES_FAILURE: null,

  FETCH_ASSESSMENT_DETAILS_REQUEST: null,
  FETCH_ASSESSMENT_DETAILS_SUCCESS: null,
  FETCH_ASSESSMENT_DETAILS_FAILURE: null,

  FETCH_ENROLLMENTS_REQUEST: null,
  FETCH_ENROLLMENTS_SUCCESS: null,
  FETCH_ENROLLMENTS_FAILURE: null,

  UPDATE_ASSESSMENT_REQUEST: null,
  UPDATE_ASSESSMENT_SUCCESS: null,
  UPDATE_ASSESSMENT_FAILURE: null,

  DELETE_ASSESSMENT_REQUEST: null,
  DELETE_ASSESSMENT_SUCCESS: null,
  DELETE_ASSESSMENT_FAILURE: null,

  CLEAR_ASSESSMENT_DETAILS: null,
  CLEAR_ENROLLMENTS: null,
  CLEAR_NEW_SCHEDULED_ASSESSMENT_URL: null,
};
export const types = mirrorKeysWithPfx('assessments/details', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchAssessmentDetails: createAction(
    types.FETCH_ASSESSMENT_DETAILS_REQUEST,
    'fetchAssessmentDetails',
  ),
  fetchEnrollments: createAction(
    types.FETCH_ENROLLMENTS_REQUEST,
    'fetchEnrollments',
  ),
  unenrollCandidate: createAction(
    types.UNENROLL_CANDIDATES_REQUEST,
    'unenrollCandidates',
  ),
  enrollCandidates: createAction(
    types.ENROLL_CANDIDATES_REQUEST,
    'enrollCandidates',
  ),
  updateAssessment: createAction(
    types.UPDATE_ASSESSMENT_REQUEST,
    'updateAssessment',
  ),
  deleteAssessment: createAction(
    types.DELETE_ASSESSMENT_REQUEST,
    'deleteAssessment',
  ),
  clearAssessmentDetails: createAction(
    types.CLEAR_ASSESSMENT_DETAILS,
  ),
  clearEnrollments: createAction(
    types.CLEAR_ENROLLMENTS,
  ),
};

// REDUCER
const INITIAL_STATE = {
  data: {},
  enrollments: [],
  enrollmentEmails: [],
};

const assessmentDetailsReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_ASSESSMENT_DETAILS_SUCCESS]: (state, action) => {
    if (!action.payload.showCustomInstructions) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = action.payload.instructions;
      const data = tempElement.querySelector('p').textContent;
      const payloadCopy = { ...action.payload, instructions: data };
      state.data = payloadCopy;
    } else {
      state.data = action.payload;
    }
  },
  [types.FETCH_ENROLLMENTS_SUCCESS]: (state, action) => {
    state.enrollments = action.payload;

    const newEmails = action.payload.results.map(({ email }) => email);
    if (!isEqual(state.enrollmentEmails, newEmails)) {
      state.enrollmentEmails = newEmails;
    }
  },
  [types.CLEAR_ASSESSMENT_DETAILS]: (state) => {
    state.data = INITIAL_STATE.data;
  },
  [types.CLEAR_ENROLLMENTS]: (state) => {
    state.enrollments = INITIAL_STATE.enrollments;
  },
});

export default assessmentDetailsReducer;
