import { fork } from 'redux-saga/effects';

import templateSaga from 'containers/templates/listings/sagas';
import templateDetailsSaga from 'containers/templates/details/sagas';
import createTemplateSaga from 'containers/templates/create/sagas';
import editTemplateSaga from 'containers/templates/edit/sagas';

export default function* templatesSagas() {
  yield fork(templateSaga);
  yield fork(templateDetailsSaga);
  yield fork(createTemplateSaga);
  yield fork(editTemplateSaga);
}
