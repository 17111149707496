import jwtDecode from 'jwt-decode';

/* eslint-disable import/prefer-default-export */

/**
 * This function is to be used to index an array of objects.
 *
 * @param {Array} arr - An array of objects.
 * @param {string} idKey - The key to be used as ID for the new object.
 * @returns {object}
 */
export const transformArrayToIdMap = (arr, idKey = 'id') => {
  const result = {};
  arr.forEach((a) => {
    const arrId = a[idKey];
    result[arrId] = a;
  });
  return result;
};

/**
 * This function is to be used to check JWT token is stored in
 * local storage.
 */
export const isAuthenticated = () => !!localStorage.token;

/**
 * This function is to be used to check JWT token stored in
 * local storage is expired or not.
 */
export const isTokenExpired = () => {
  if (localStorage.token) {
    const decodedToken = jwtDecode(localStorage.token);
    const currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return true;
    }
  }
  return false;
};

/**
 * This function is to be used to convert object to form data.
 */
export const constructFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return formData;
};

/**
 * Parse and return `tags` if it is a JSON string. If tags is a list of strings, return it as is.
 *
 * @param {String | Array<String>} tags A json string or list of tag strings.
 * @returns List of string tags.
 */
export const parseTags = (tags) => {
  if (!tags) {
    return [];
  }

  if (!Array.isArray(tags)) {
    try {
      return tags && JSON.parse(tags);
    } catch (error) {
      return ['Error Parsing Tags'];
    }
  }
  return tags;
};

/**
 * Converts camel case to snake case
 *
 * @param {String} word a string in camel case
 * @returns a string in snake case.
 */
export const toSnakeCase = (word) => word.split(/(?=[A-Z])/).join('_').toLowerCase();

/**
 * Formats currency
 *
 * @param {Number} amount
 * @param {String} currency default: USD
 * @returns amount formatted with provided currency
 */
export const formatCurrency = (amount, currency = 'usd') => {
  if (amount === 0) {
    return (Math.abs(amount).toLocaleString('en-US', { style: 'currency', currency }));
  }
  return amount.toLocaleString('en-US', { style: 'currency', currency });
};

/**
 * Capitalizes string
 *
 * @param {string} string
 * @returns Capitalized string
 */
export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
