/* eslint-disable no-param-reassign */

import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

// ACTION-TYPES
const pureTypes = {
  FETCH_ASSESSMENTS_REQUEST: null,
  FETCH_ASSESSMENTS_SUCCESS: null,
  FETCH_ASSESSMENTS_FAILURE: null,
  CLEAR_ASSESSMENTS: null,
};
export const types = mirrorKeysWithPfx('assessments', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchAssessments: createAction(types.FETCH_ASSESSMENTS_REQUEST, 'fetchAssessments'),
  clearAssessments: createAction(types.CLEAR_ASSESSMENTS),
};

// REDUCER
const INITIAL_STATE = {
  assessments: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

const assessmentsListingsReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_ASSESSMENTS_SUCCESS]: (state, action) => {
    state.assessments = action.payload;
  },
  [types.CLEAR_ASSESSMENTS]: (state) => {
    state.assessments = INITIAL_STATE.assessments;
  },
});

export default assessmentsListingsReducer;
