import { faker } from '@faker-js/faker';
import { random, range, sample } from 'lodash';

const FEEDBACK_OPTIONS = ['optional', 'required'];

const getRandomOption = () => (
  {
    points: random(1, 100),
    label: faker.lorem.words(random(2, 5)),
    explanation: faker.lorem.lines(1),
  }
);

const getRandomCriterias = () => (
  {
    options: range(1, random(2, 5)).map(() => getRandomOption()),
    label: faker.lorem.words(random(2, 5)),
    prompt: faker.lorem.words(random(2, 5)),
    feedback: sample(FEEDBACK_OPTIONS),
  }
);

const getRandomRubricTemplate = (id) => (
  {
    id,
    name: faker.lorem.words(random(2, 5)),
    criterias: range(1, random(2, 5)).map(() => getRandomCriterias()),
  }
);

export const rubricTemplates = range(1, 21).map((number) => getRandomRubricTemplate(number));

export const rubricCreateResponse = getRandomRubricTemplate(random(1, 21));

export const rubricUpdateResponse = getRandomRubricTemplate(random(1, 21));

export const rubricDeleteResponse = undefined;

export const mockedRubricTemplates = [
  {
    id: 1,
    name: 'Rubric Evaluation',
    criterias: [
      {
        options: [
          {
            points: 10,
            label: 'Good',
            explanation: 'Good explanation',
          },
          {
            points: 0,
            label: 'Bad',
            explanation: 'Bad explanation',
          },
        ],
        isAutomated: false,
        label: 'Criteria 1',
        name: '',
        prompt: 'Criteria 1',
        feedback: FEEDBACK_OPTIONS[0],
      },
      {
        options: [
          {
            points: 10,
            label: 'Good',
            explanation: 'Good explanation',
          },
          {
            points: 0,
            label: 'Bad',
            explanation: 'Bad explanation',
          },
        ],
        isAutomated: false,
        label: 'Criteria 2',
        name: '',
        prompt: 'Criteria 2',
        feedback: FEEDBACK_OPTIONS[0],
      },
    ],
  },
];
