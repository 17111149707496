import { fork } from 'redux-saga/effects';

import questionListingSaga from './listing/sagas';
import questionsDetailsSagas from './details/sagas';
import questionCreateSaga from './create/sagas';

export default function* questionsSagas() {
  yield fork(questionListingSaga);
  yield fork(questionsDetailsSagas);
  yield fork(questionCreateSaga);
}
