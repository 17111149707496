/**
 * Given a list of items, returns the item that matches the `id` in the
 * request params.
 *
 * @param {Request} request An msw request object.
 * @param {Array} list A collection of items. All elements must have an 'id' key.
 * @returns A single item with the matching `id` or undefined.
 */
export const fecthObjectWithId = (request, list) => list.find(
  ({ id }) => id.toString() === request.params.id.toString(),
);

/**
 * Given a list of items, returns a callback which takes in an msw request
 * object and returns the item in the list that matches the id in the request.
 *
 * @param {Array} list A collection of items. All elements must have an 'id' key.
 * @returns A single item with the matching `id`.
 * @raises Error if no matching item found.
 */
export const detailResponseCreator = (list) => (request) => {
  const response = fecthObjectWithId(request, list);
  if (response === undefined) {
    const error = new Error('Not Found');
    error.status = 404;
    error.response = 'Not Found';
  }
  return response;
};

/**
 * Given a list of items, returns a callback which takes in an msw request
 * object and returns a paginated list response.
 *
 * This pagination corresponds to the pagination on the backend.
 *
 * @param {Array} list A collection of items. All elements must have an 'id' key.
 * @returns A object of paginated list response.
 */
export const listResponseCreator = (list) => ({ url }) => {
  let page = parseInt(url.searchParams.get('page') || 1);
  const pageSize = parseInt(url.searchParams.get('page_size') || 0);

  if (!pageSize) {
    return list;
  }

  const totalPages = Math.ceil(list.length / pageSize);

  let nextPage = page + 1;
  let previousPage = page - 1;

  if (page > totalPages) {
    const error = new Error('Invalid page.');
    error.response = { detail: 'Invalid page.' };
    error.status = 404;
    throw error;
  } else if (page === totalPages) {
    page = totalPages;
    nextPage = null;
  } else if (!page) {
    page = 1;
    previousPage = null;
  }

  const pageData = list.slice(
    previousPage ? previousPage * pageSize : 0,
    nextPage ? page * pageSize : undefined,
  );

  return {
    count: list.length,
    next: nextPage
      ? `${url.origin}${url.pathname}?page=${nextPage}&page_size=${pageSize}`
      : null,
    previous: previousPage
      ? `${url.origin}${url.pathname}?page=${previousPage}&page_size=${pageSize}`
      : null,
    results: pageData,
  };
};
