import { combineReducers } from 'redux';

import questionListingsReducer from './listing/ducks';
import questionDetailsReducer from './details/ducks';
import questionCreateReducer from './create/ducks';

export default combineReducers({
  listings: questionListingsReducer,
  details: questionDetailsReducer,
  create: questionCreateReducer,
});
