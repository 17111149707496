/* eslint-disable no-param-reassign */

import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

// ACTION-TYPES
const pureTypes = {
  FETCH_MEMBERS_REQUEST: null,
  FETCH_MEMBERS_SUCCESS: null,
  FETCH_MEMBERS_FAILURE: null,
  CLEAR_MEMBERS: null,

  ADD_MEMBERS_REQUEST: null,
  ADD_MEMBERS_SUCCESS: null,
  ADD_MEMBERS_FAILURE: null,

  EDIT_MEMBERS_REQUEST: null,
  EDIT_MEMBERS_SUCCESS: null,
  EDIT_MEMBERS_FAILURE: null,

  FETCH_USER_ROLES_REQUEST: null,
  FETCH_USER_ROLES_SUCCESS: null,
  FETCH_USER_ROLES_FAILURE: null,
  CLEAR_USER_ROLES: null,
};
export const types = mirrorKeysWithPfx('users', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchMembers: createAction(types.FETCH_MEMBERS_REQUEST, 'fetchMembers'),
  addMember: createAction(types.ADD_MEMBERS_REQUEST, 'addMembers'),
  editMember: createAction(types.EDIT_MEMBERS_REQUEST, 'editMembers'),
  clearMembers: createAction(types.CLEAR_MEMBERS),
  fetchUserRoles: createAction(types.FETCH_USER_ROLES_REQUEST, 'fetchUserRoles'),
  clearUserRoles: createAction(types.CLEAR_USER_ROLES),
};

// REDUCER
const INITIAL_STATE = {
  members: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  addNew: { email: '', roles: [] },
  userRoles: [],
};

const teamReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_MEMBERS_SUCCESS]: (state, action) => {
    state.members = action.payload;
  },
  [types.CLEAR_MEMBERS]: (state) => {
    state.members = INITIAL_STATE.members;
  },
  [types.FETCH_USER_ROLES_SUCCESS]: (state, action) => {
    state.userRoles = action.payload;
  },
  [types.CLEAR_USER_ROLES]: (state) => {
    state.userRoles = INITIAL_STATE.userRoles;
  },
});

export default teamReducer;
