import { takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { types } from './ducks';
import {
  downloadReportSaga as downloadAssessmentReportSaga,
} from '../../shared/reportDownload/report-download-saga';

export default function* reportsSaga() {
  yield takeEvery(types.FETCH_REPORT_DETAILS_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_GRADED_CANDIDATES_REQUEST, fetchApiSaga);
  yield takeEvery(types.DOWNLOAD_REPORT_REQUEST, downloadAssessmentReportSaga);
  yield takeEvery(types.RESET_ATTEMPT_REQUEST, fetchApiSaga);
  yield takeEvery(types.RECALCULATE_SCORES_REQUEST, fetchApiSaga);
  yield takeEvery(types.START_GRADING_REQUEST, fetchApiSaga);
}
