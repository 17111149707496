import APIClient from '@utils/axios';
import API_URLS from './urls';

const headers = {
  'Content-Type': 'application/json',
};

export const fetchMembers = (data) => {
  let url = data.pageSize
    ? `${API_URLS.ORG_MEMBERS}?page=${data.page}&page_size=${data.pageSize}`
    : `${API_URLS.ORG_MEMBERS}?page=${data.page}&page_size=0`;

  url = data.searchText
    ? `${url}&search=${data.searchText}`
    : url;

  url = data.sorting
    ? `${url}&ordering=${data.sorting}`
    : url;

  url = data.filtering
    ? `${url}&${data.filtering}`
    : url;

  return APIClient.get(url);
};

export const addMembers = (values) => {
  const formData = { userDetails: [values] };
  formData.userDetails[0].roles = values.roles.map((role) => role.value);
  return APIClient.post(API_URLS.ORG_MEMBERS, formData, { headers });
};

export const editMembers = (values) => {
  const formData = values;
  formData.roles = values.roles.map((role) => role.value);
  return APIClient.put(API_URLS.ORG_MEMBERS, formData, { headers });
};

export const fetchUserRoles = () => (
  APIClient.get(API_URLS.USER_ROLES)
);
