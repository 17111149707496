import { takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { types } from './ducks';

export default function* templateDetailsSaga() {
  yield takeLatest(types.FETCH_TEMPLATE_DETAILS_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_ASSESSMENT_COUNT_REQUEST, fetchApiSaga);
  yield takeEvery(types.SCHEDULE_ASSESSMENT_REQUEST, fetchApiSaga);
}
