import APIClient from '@utils/axios';

import API_URLS from './urls';

const headers = {
  'Content-Type': 'application/json',
};

export const fetchPaymentPlans = () => (
  APIClient.get(`${API_URLS.PAYMENT_PLANS}?active=true`)
);

export const fetchCurrentPlan = () => (
  APIClient.get(API_URLS.CURRENT_PLAN)
);

export const fetchPaymentMethods = (data = {}) => {
  const url = data?.page && data?.pageSize
    ? `${API_URLS.PAYMENT_METHODS}?page=${data?.page}&page_size=${data?.pageSize}`
    : `${API_URLS.PAYMENT_METHODS}?page=1`;

  return (
    APIClient.get(url)
  );
};

export const addPaymentMethod = (data) => (
  APIClient.post(API_URLS.PAYMENT_METHODS, data, { headers })
);

export const deletePaymentMethod = (id) => (
  APIClient.delete(`${API_URLS.PAYMENT_METHODS}${id}/`, { headers })
);

export const setDefaultPaymentMethod = (data) => (
  APIClient.post(`${API_URLS.PAYMENT_METHODS}set_default_method/`, data, { headers })
);

export const fetchPaymentHistory = (data = {}) => {
  let url = data?.page && data?.pageSize
    ? `${API_URLS.PAYMENT_HISTORY}?page=${data?.page}&page_size=${data?.pageSize}`
    : `${API_URLS.PAYMENT_HISTORY}?page=1`;

  url = data.filtering
    ? `${url}&${data.filtering}`
    : url;

  url = !url.includes('ordering') && data.sorting
    ? `${url}&ordering=${data.sorting}`
    : url;

  return (
    APIClient.get(url)
  );
};

export const cancelPlan = () => (
  APIClient.delete(API_URLS.CANCEL_PLAN)
);

export const reactivatePlan = () => (
  APIClient.post(API_URLS.REACTIVATE_PLAN)
);
