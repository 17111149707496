import { call } from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';

// eslint-disable-next-line import/prefer-default-export
export function* downloadReportSaga(action) {
  const { fileName } = action.payload;
  const serviceResponse = yield call(fetchApiSaga, action);

  if (serviceResponse.success) {
    const url = window.URL.createObjectURL(new Blob([serviceResponse.message]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
