export const organizationMembers = [
  {
    name: 'Robyn',
    email: 'Robyn@example.com',
    roles: ['Hiring_Manager'],
    date_joined: new Date().toISOString(),
    is_active: true,
  },
  {
    name: 'Barney',
    email: 'Barney@example.com',
    roles: ['Recruiter_L1'],
    date_joined: new Date().toISOString(),
    is_active: true,
  },
  {
    name: 'Ted',
    email: 'Ted@example.com',
    roles: ['Recruiter_L2'],
    date_joined: new Date().toISOString(),
    is_active: false,
  },
  {
    name: 'Lilly',
    email: 'Lilly@example.com',
    roles: ['Recruiter_L3'],
    date_joined: new Date().toISOString(),
    is_active: true,
  },
  {
    name: 'Marshall',
    email: 'Marshall@example.com',
    roles: ['Sales'],
    date_joined: new Date().toISOString(),
    is_active: false,
  },
  {
    name: 'Tracy',
    email: 'TracyMosby@example.com',
    roles: ['Recruiter_L2'],
    date_joined: new Date().toISOString(),
    is_active: false,
  },
];

export const memberAddedSuccess = {
  '<email>': ['<role>'],
};

export const memberUpdatedSuccess = {
  '<email>': ['<role>'],
};

export const userRoles = [
  'Hiring_Manager',
  'Author',
];

export const userDetails = {
  'email': 'testing@example.com',
  'first_name': 'Litmus',
  'last_name': 'User',
  'user_roles': ['Hiring_Manager'],
  'last_login': new Date().toISOString(),
  'organization': 'Litmustest',
  'org_key': 'litmustest',
};
