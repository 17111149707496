import { ROUTES } from 'core/routes/constants';

import hasUserAccess from './user-roles';

const createUserRouteAccess = (userRoles) => {
  const userRouteAccess = {};
  const routes = Object.values(ROUTES);

  routes.forEach((route) => {
    userRouteAccess[route] = (
      hasUserAccess(route, userRoles)
    );
  });

  return userRouteAccess;
};

export default createUserRouteAccess;
