import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

const pureTypes = {
  FETCH_RUBRIC_TEMPLATE_DETAILS_REQUEST: null,
  FETCH_RUBRIC_TEMPLATE_DETAILS_SUCCESS: null,
  FETCH_RUBRIC_TEMPLATE_DETAILS_FAILURE: null,
  CLEAR_RUBRIC_TEMPLATE_DETAILS: null,
};

export const types = mirrorKeysWithPfx('rubricTemplates', pureTypes);

export const actions = {
  fetchRubricTemplateDetails: createAction(
    types.FETCH_RUBRIC_TEMPLATE_DETAILS_REQUEST,
    'fetchRubricTemplateDetails',
  ),
  clearRubricTemplateDetails: createAction(types.CLEAR_RUBRIC_TEMPLATE_DETAILS),
};

const INITIAL_STATE = {
  data: {
    id: 0,
    name: '',
    possibleScore: 0,
    feedbackPrompt: '',
    feedbackDefaultText: '',
    criterias: [],
  },
};

const rubricTemplateDetailsReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_RUBRIC_TEMPLATE_DETAILS_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
  }),
  [types.CLEAR_RUBRIC_TEMPLATE_DETAILS]: () => INITIAL_STATE,
});

export default rubricTemplateDetailsReducer;
