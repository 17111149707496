import React from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssessmentIcon from '@material-ui/icons/Assignment';
import LinearScale from '@material-ui/icons/LinearScale';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import GroupIcon from '@material-ui/icons/Group';
import NotesIcon from '@material-ui/icons/Notes';

import CircularProgress from 'components/CircularProgress';
import { List } from 'components/MaterialUI';

import { selectUserRouteAccess } from 'containers/shared/auth/selectors';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import NavItem from './components/NavItem';

import litmustestLogo from '../../assets/common/litmustest_white.png';
import { ROUTES } from '../../core/routes/constants';

const SideNavLayout = ({ children, loading }) => {
  const classes = useStyles();
  const userRouteAccess = useSelector(selectUserRouteAccess);

  const navItems = [
    [
      { link: ROUTES.dashboard, title: 'Dashboard', icon: DashboardIcon },
    ],
    [
      { link: ROUTES.questions, title: 'Questions', icon: QuestionAnswerIcon },
    ],
    [
      { link: ROUTES.templates, title: 'Templates', icon: AssessmentIcon },
    ],
    [
      { link: ROUTES.assessments, title: 'Assessments', icon: LinearScale },
    ],
    [
      { link: ROUTES.reports, title: 'Reports', icon: AssessmentRoundedIcon },
    ],
    [
      { link: ROUTES.team, title: 'Team', icon: GroupIcon },
    ],
    [
      { link: ROUTES.rubricTemplates, title: 'Rubric Templates', icon: NotesIcon },
    ],
  ];

  return (
    <div className={classes.root} id="side-nav">
      <Drawer
        aria-labelledby="side-nav-bar"
        variant="permanent"
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <div id="logoWrapper">
          <a href="/dashboard">
            <img
              src={litmustestLogo}
              className={classes.logo}
              alt="litmustest"
            />
          </a>
        </div>

        <br />

        <List
          className={classes.nav}
          component="nav"
          aria-labelledby="main-nav-list"
        >
          {navItems.map((navList) => (
            navList.map((navItem) => (
              userRouteAccess[navItem.link]
                ? (
                  <NavItem
                    title={navItem.title}
                    link={navItem.link}
                    key={navItem.link}
                    icon={navItem.icon}
                  />
                ) : null
            ))
          ))}

        </List>

      </Drawer>

      <div id="main" className={classes.mainContent}>
        {
          loading
            ? <div className={classes.circularProgressContainer}><CircularProgress /></div>
            : children
        }
      </div>
    </div>
  );
};

SideNavLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loading: PropTypes.bool,
};

SideNavLayout.defaultProps = {
  children: null,
  loading: false,
};

export default SideNavLayout;
