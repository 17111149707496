import {
  createAction,
} from '@utils';

import { getActionKey } from '../utils';

const initialReducerState = {};

const clearErrorProptype = 'CLEAR_ERROR_STATES_REQUEST';

export const actions = {
  clearErrorStates: createAction(clearErrorProptype),
};

export default function errorReducer(state = initialReducerState, action) {
  const matches = /(.*)_FAILURE/.exec(action.type);
  if (matches === null && action.type !== clearErrorProptype) {
    return state;
  }
  const actionKey = getActionKey(action.type);
  switch (action.type) {
    case clearErrorProptype: {
      const { [action.payload]: Null, ...rest } = state;
      return rest;
    }
    default:
      return {
        ...state,
        [actionKey]: action.payload,
      };
  }
}
