/* eslint-disable no-param-reassign */

import { createAction, mirrorKeysWithPfx, reducerFactory } from '@utils';

// ACTION-TYPES
const pureTypes = {
  FETCH_PAYMENT_PLANS_REQUEST: null,
  FETCH_PAYMENT_PLANS_SUCCESS: null,
  FETCH_PAYMENT_PLANS_FAILURE: null,
  CLEAR_PAYMENT_PLANS_LISTING: null,

  FETCH_CURRENT_PLAN_REQUEST: null,
  FETCH_CURRENT_PLAN_SUCCESS: null,
  FETCH_CURRENT_PLAN_FAILURE: null,
  CLEAR_CURRENT_PLAN: null,

  FETCH_PAYMENT_METHODS_REQUEST: null,
  FETCH_PAYMENT_METHODS_SUCCESS: null,
  FETCH_PAYMENT_METHODS_FAILURE: null,
  CLEAR_PAYMENT_METHODS: null,

  ADD_PAYMENT_METHOD_REQUEST: null,
  ADD_PAYMENT_METHOD_SUCCESS: null,
  ADD_PAYMENT_METHOD_FAILURE: null,

  DELETE_PAYMENT_METHOD_REQUEST: null,
  DELETE_PAYMENT_METHOD_SUCCESS: null,
  DELETE_PAYMENT_METHOD_FAILURE: null,

  SET_DEFAULT_PAYMENT_METHOD_REQUEST: null,
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS: null,
  SET_DEFAULT_PAYMENT_METHOD_FAILURE: null,

  FETCH_PAYMENT_HISTORY_REQUEST: null,
  FETCH_PAYMENT_HISTORY_SUCCESS: null,
  FETCH_PAYMENT_HISTORY_FAILURE: null,
  CLEAR_PAYMENT_HISTORY: null,

  CANCEL_PLAN_REQUEST: null,
  CANCEL_PLAN_SUCCESS: null,
  CANCEL_PLAN_FAILURE: null,

  REACTIVATE_PLAN_REQUEST: null,
  REACTIVATE_PLAN_SUCCESS: null,
  REACTIVATE_PLAN_FAILURE: null,
};
export const types = mirrorKeysWithPfx('billing', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchPaymentPlansListings: createAction(types.FETCH_PAYMENT_PLANS_REQUEST, 'fetchPaymentPlans'),
  clearPaymentPlansListings: createAction(types.CLEAR_PAYMENT_PLANS_LISTING),

  fetchCurrentPlan: createAction(types.FETCH_CURRENT_PLAN_REQUEST, 'fetchCurrentPlan'),
  fetchCurrentPlanFailure: createAction(types.FETCH_CURRENT_PLAN_FAILURE),
  fetchCurrentPlanSuccess: createAction(types.FETCH_CURRENT_PLAN_SUCCESS),
  clearCurrentPlan: createAction(types.CLEAR_CURRENT_PLAN),

  fetchPaymentMethodsListing: createAction(types.FETCH_PAYMENT_METHODS_REQUEST, 'fetchPaymentMethods'),
  fetchPaymentMethodsFailure: createAction(types.FETCH_PAYMENT_METHODS_FAILURE),
  fetchPaymentMethodsSuccess: createAction(types.FETCH_PAYMENT_METHODS_SUCCESS),
  clearPaymentMethods: createAction(types.CLEAR_PAYMENT_METHODS),

  addPaymentMethod: createAction(types.ADD_PAYMENT_METHOD_REQUEST, 'addPaymentMethod'),
  addPaymentMethodFailure: createAction(types.ADD_PAYMENT_METHOD_FAILURE),
  addPaymentMethodSuccess: createAction(types.ADD_PAYMENT_METHOD_SUCCESS),

  deletePaymentMethod: createAction(types.DELETE_PAYMENT_METHOD_REQUEST, 'deletePaymentMethod'),
  deletePaymentMethodFailure: createAction(types.DELETE_PAYMENT_METHOD_FAILURE),
  deletePaymentMethodSuccess: createAction(types.DELETE_PAYMENT_METHOD_SUCCESS),

  setDefaultPaymentMethod: createAction(types.SET_DEFAULT_PAYMENT_METHOD_REQUEST, 'setDefaultPaymentMethod'),
  fetchPaymentHistory: createAction(types.FETCH_PAYMENT_HISTORY_REQUEST, 'fetchPaymentHistory'),
  clearPaymentHistory: createAction(types.CLEAR_PAYMENT_HISTORY),

  cancelPlan: createAction(types.CANCEL_PLAN_REQUEST, 'cancelPlan'),
  reactivatePlan: createAction(types.REACTIVATE_PLAN_REQUEST, 'reactivatePlan'),
};

// REDUCER
const INITIAL_STATE = {
  paymentMethods: [],
  paymentPlans: [],
  currentPlan: {},
  paymentHistory: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

const billingReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_PAYMENT_PLANS_SUCCESS]: (state, action) => {
    state.paymentPlans = action.payload;
  },
  [types.CLEAR_PAYMENT_PLANS_LISTING]: (state) => {
    state.paymentPlans = INITIAL_STATE.paymentPlans;
  },
  [types.FETCH_CURRENT_PLAN_SUCCESS]: (state, action) => {
    state.currentPlan = action.payload;
  },
  [types.CLEAR_CURRENT_PLAN]: (state) => {
    state.currentPlan = INITIAL_STATE.currentPlan;
  },
  [types.FETCH_PAYMENT_METHODS_SUCCESS]: (state, action) => {
    state.paymentMethods = action.payload;
  },
  [types.CLEAR_PAYMENT_METHODS]: (state) => {
    state.paymentMethods = INITIAL_STATE.paymentMethods;
  },
  [types.FETCH_PAYMENT_HISTORY_SUCCESS]: (state, action) => {
    state.paymentHistory = action.payload;
  },
  [types.CLEAR_PAYMENT_HISTORY]: (state) => {
    state.paymentHistory = INITIAL_STATE.paymentHistory;
  },
});

export default billingReducer;
