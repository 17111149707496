import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  fourOhFour: {
    fontWeight: 600,
    fontStyle: 'italic',
    color: theme.palette.primary.main,
  },
  missing: {
    fontWeight: 600,
  },
  root: {
    height: '100vh',
    textAlign: 'center',
  },
}));
