import { DateTime, Duration } from 'luxon';

const userTimezone = localStorage?.userInfo ? JSON.parse(localStorage?.userInfo)?.timezone : 'Asia/Karachi';

/**
 * Returns a human friendly format of `miliseconds` in the form of hours, minutes and seconds.
 *
 * @param {number} miliseconds
 * @returns a string.
 */
export const milliSecondsToDisplayFormat = (miliseconds) => {
  const duration = Duration.fromMillis(miliseconds).shiftTo(
    'hours',
    'minutes',
    'seconds',
  );

  let text = duration.hours > 0
    ? `${duration.hours} hour${duration.hours === 1 ? '' : 's'}`
    : '';
  text = duration.minutes > 0
    ? `${text} ${duration.minutes} minute${duration.minutes === 1 ? '' : 's'}`
    : text;
  text = duration.seconds > 0
    ? `${text} ${duration.seconds} second${duration.seconds === 1 ? '' : 's'}`
    : text;

  return text.trim();
};

/**
 * Formats given ISO date string to the specified `format` and returns it.
 *
 * @param {string} isoDateString ISO format date string.
 * @param {string} format format of the returned date string.
 * @param {boolean} asTodayTomorrow indicates whether to return nominal date (Today, Tomorrow).
 * @returns a formatted date string.
 */
export const formatISODate = (
  isoDateString,
  format = 'dd LLLL, yy',
  asTodayTomorrow = true,
) => {
  const date = DateTime.fromISO(
    isoDateString,
    { zone: userTimezone },
  );

  if (asTodayTomorrow) {
    const relativeDate = date.toRelativeCalendar();

    if (relativeDate === 'today' || relativeDate === 'tomorrow') {
      return `${relativeDate.charAt(0).toUpperCase()}${relativeDate.substr(1)}`;
    }
  }

  return date.toFormat(format);
};

/**
 * Formats given ISO date string to the specified `format` and returns it.
 *
 * @param {string} isoDateTimeString ISO format date time string.
 * @param {string} format format of the returned date time string.
 * @returns a formatted date string.
 */
export const formatISOTime = (isoDateTimeString, format = 'hh:mm a') => (
  DateTime.fromISO(
    isoDateTimeString,
    { zone: userTimezone },
  ).toFormat(format)
);

/**
 * Returns list of time zones.
 *
 * @returns a list of objects of shape { label: <string>, value: <string> }.
 */
export const getTimeZones = () => (
  Intl.supportedValuesOf('timeZone')
    .map((timezone) => ({
      value: timezone,
      label: timezone,
    }))
);

/**
 * Formats given Javscript date to specified format and returns it.
 *
 * @param {Date} JSDate Javascript Date object.
 * @param {string} format format of the returned date string.
 * @returns Formated date string.
 */
export const formatDate = (JSDate, format) => (
  DateTime.fromJSDate(
    JSDate,
    { zone: userTimezone },
  ).toFormat(format)
);

/**
 * Returns current local date time.
 *
 * @returns current local DateTime of type luxon.DateTime.
 */
export const getLocalDateTime = () => (
  DateTime.local().setZone(userTimezone)
);

/**
 * Parses `dateString` according to the given `format` and returns a Luxon DateTime object.
 *
 * @param {string} dateString
 * @param {string} format format of the `dateString`.
 * @returns parsed date of type luxon.DateTime.
 */
export const parseDate = (dateString, format) => (
  DateTime.fromFormat(dateString, format)
);

/**
 * Parses `dateString` according to the given `format` and returns a JavaScript Date object.
 *
 * @param {string} dateString
 * @param {string} format format of the `dateString`.
 * @returns parsed date of type Date.
 */
export const parseJSDate = (dateString, format) => (
  DateTime.fromFormat(dateString, format).toJSDate()
);

/**
 * Parses `isoDateString` and return a luxon.DateTime object.
 *
 * @param {string} dateString
 * @returns parsed date of type luxon.DateTime
 */
export const parseISODateTime = (isoDateTimeString) => (
  DateTime.fromISO(isoDateTimeString, { zone: userTimezone })
);

/**
 * Returns ISO string representation of `dateTime` object.
 *
 * @param {DateTime} dateTime DateTime object
 * @returns ISO formatted date string.
 */
export const toISO = (dateTime) => new Date(dateTime).toISOString();

/**
 * Adds time indicated by `option` to `dateTime` and returns the result.
 *
 * @param {DateTime} dateTime luxon.DateTime object.
 * @param {Duration | Object | number} option https://moment.github.io/luxon/api-docs/index.html#datetimeplus
 * @returns resultant luxon.DateTime object
 */
export const dateTimePlus = (dateTime, option) => dateTime.plus(option);

/**
 * Converts JS Date into luxon.DateTime.
 *
 * @param {Date} jsDate A JS Date object.
 * @returns luxon.DateTime object.
 */
export const jsDateToDateTime = (jsDate) => DateTime.fromJSDate(
  jsDate,
  { zone: userTimezone },
);

/**
 * Converts luxon.DateTime into JS Date.
 *
 * @param {Date} dateTime A luxon.DateTime object.
 * @returns JS Date object.
 */
export const dateTimeToJSDate = (dateTime) => dateTime.toJSDate();

/**
 * Formats time in seconds to the specified `format` and returns it.
 *
 * @param {number} seconds ISO format date time string.
 * @returns a formatted date string.
 */
export const timeFromSeconds = (seconds) => (
  Duration.fromMillis(seconds * 1000).toFormat('hh:mm:ss')
);

/**
 * Formats time in seconds to the specified `format` and returns it.
 *
 * @param {date} timestamp Unix timestamp
 * @returns a formatted date string.
 */
export const formatUnixTimestamp = (timestamp) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString('en-GB', options);
};
