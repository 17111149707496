/* eslint-disable no-param-reassign */

import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

// ACTION-TYPES
const pureTypes = {
  FETCH_REPORTS_REQUEST: null,
  FETCH_REPORTS_SUCCESS: null,
  FETCH_REPORTS_FAILURE: null,
  CLEAR_REPORTS_LISTINGS: null,
};
export const types = mirrorKeysWithPfx('reports', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchReportListings: createAction(types.FETCH_REPORTS_REQUEST, 'fetchReports'),
  clearReportListings: createAction(types.CLEAR_REPORTS_LISTINGS),
};

// REDUCER
const INITIAL_STATE = {
  reports: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

const listingsReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_REPORTS_SUCCESS]: (state, action) => {
    state.reports = action.payload;
  },
  [types.CLEAR_REPORTS_LISTINGS]: () => INITIAL_STATE,
});

export default listingsReducer;
