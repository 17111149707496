import { takeLatest } from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { types } from './ducks';

export default function* teamSaga() {
  yield takeLatest(types.FETCH_MEMBERS_REQUEST, fetchApiSaga);
  yield takeLatest(types.ADD_MEMBERS_REQUEST, fetchApiSaga);
  yield takeLatest(types.EDIT_MEMBERS_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_USER_ROLES_REQUEST, fetchApiSaga);
}
