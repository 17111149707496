import { faker } from '@faker-js/faker';
import { random, sampleSize, sumBy } from 'lodash';
import { enrollmentsMap } from './enrollments';
import { scheduledAssessmentList } from './scheduled-assessments';

export const scheduledAssessmentReportsList = scheduledAssessmentList.map(
  (scheduledAssessment) => ({
    id: scheduledAssessment.id,
    title: scheduledAssessment.title,
    assessment_template: scheduledAssessment.assessment_template,
    start_date: scheduledAssessment.start_date,
    end_date: scheduledAssessment.end_date,
    mean_score: scheduledAssessment.mean_score,
    is_graded: scheduledAssessment.is_graded,
    total_enrolled_candidates: scheduledAssessment.total_enrolled_candidates,
    assessment_tags: scheduledAssessment.assessment_tags,
    duration: scheduledAssessment.duration,
  }),
);

const candidateAttemptsMap = {};
const candidateReportsList = [];
const candidateReportsDetailsList = [];

let questionGradeCount = 0;

scheduledAssessmentList.forEach((scheduledAssessment) => {
  let individualReports = enrollmentsMap[scheduledAssessment.id].map(
    (enrollment, index) => {
      const pointsTotal = sumBy(
        scheduledAssessment.question_pool,
        'possible_score',
      );
      const attemptedQuestionsCount = random(
        0,
        scheduledAssessment.question_pool.length,
      );
      const attemptedQuestions = sampleSize(
        scheduledAssessment.question_pool,
        attemptedQuestionsCount,
      );
      const gradebreakdown = attemptedQuestions.map((question) => {
        const pointsEarned = random(0, question.possible_score);
        questionGradeCount += 1;
        return {
          id: questionGradeCount,
          question: question.id,
          report: candidateReportsList.length + index,
          points_earned: pointsEarned,
          points_total: question.possible_score,
          solution: null,
          submitted_at: faker.date.past(2).toISOString(),
          metadata: question.metadata,
          percentage: pointsEarned,
        };
      });
      const pointsEarned = sumBy(gradebreakdown, 'points_earned');
      return {
        id: candidateReportsList.length + index,
        points_earned: pointsEarned,
        points_total: pointsTotal,
        percentage: pointsEarned / pointsTotal,
        time_taken: random(0, 200), // Not being used yet
        user: enrollment,
        scheduled_assessment: scheduledAssessmentReportsList.find(
          ({ id }) => scheduledAssessment.id === id,
        ),
        questions: scheduledAssessment.question_pool,
        grade_breakdown: gradebreakdown,
      };
    },
  );
  // Simulate behaviour where all candidates have not attempted.
  individualReports = sampleSize(
    individualReports,
    random(0, individualReports.length),
  );
  const meanScore = sumBy(individualReports, 'percentage') / individualReports.length;
  scheduledAssessmentReportsList.find(
    ({ id }) => id === scheduledAssessment.id,
  ).mean_score = meanScore;
  candidateReportsDetailsList.push(...individualReports);
  candidateAttemptsMap[scheduledAssessment.id] = individualReports.map(
    ({ questions, grade_breakdown: _, ...others }) => others,
  );
  candidateReportsList.push(...candidateAttemptsMap[scheduledAssessment.id]);
});

export {
  candidateAttemptsMap,
  candidateReportsList,
  candidateReportsDetailsList,
};
