import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Grid, Typography } from 'components/MaterialUI';

import { isAuthenticated, updateLocation } from '@utils';

import useStyles from './styles';
import { ROUTES } from '../constants';

const NotFoundPage = () => {
  const classes = useStyles();

  const delay = 4;
  const [text, link] = isAuthenticated()
    ? ['Dashboard', ROUTES.dashboard] : ['Login', ROUTES.login];

  useEffect(() => {
    const timer = setTimeout(() => updateLocation(link), delay * 1000);
    return () => clearTimeout(timer);
  }, [link]);

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      justifyContent="center"
      className={classes.root}
    >
      <Typography variant="h3" className={classes.fourOhFour}>404</Typography>
      <Typography variant="h4" className={classes.missing} gutterBottom>OOPS! NOTHING WAS FOUND</Typography>
      <Typography>
        The page that you were looking for was not found.
        <br />
        You will be redirected to&nbsp;
        <Link to={link}>{text}</Link>
        &nbsp;shortly.
      </Typography>
    </Grid>
  );
};

export default NotFoundPage;
