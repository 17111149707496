import { encode } from 'html-entities';
import questionTypes, { questionSubTypes } from './question-types';

const mcqOlxGenerator = ({ statement, options }) => (
  `<problem>
  <multiplechoiceresponse>
    <div>${statement}
    </div>
    <choicegroup type="MultipleChoice">
${options.reduce(
    (previous, current) => {
      let olx = '';
      if (current.name === 'Correct') {
        olx = `      <choice correct="true">${encode(current.value)}</choice>\n`;
      } else {
        olx = `      <choice correct="false">${encode(current.value)}</choice>\n`;
      }
      return `${previous}${olx}`;
    },
    '',
  )
  }    </choicegroup>
  </multiplechoiceresponse>
</problem>`
);

const codingOlxGenerator = ({ statement }) => (
  `<problem>
  <div>${statement}
  </div>
</problem>`
);

const randomizedOlxGenerator = ({ randomizationScript, htmlBody }) => (
  `<div>
  <questioncontent>
    <problem>
      <script type="loncapa/python">
        <![CDATA[
${randomizationScript}
        ]]>
      </script>
      <div id="question-body">
${htmlBody}
      </div>
    </problem>
  </questioncontent>
</div>`
);

export default {
  [questionTypes.Objective]: mcqOlxGenerator,
  [questionTypes.Coding]: codingOlxGenerator,
  [questionSubTypes.Objective.Randomized]: randomizedOlxGenerator,
};
