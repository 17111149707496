import { combineReducers } from 'redux';

import listingsReducer from './listings/ducks';
import detailsReducer from './details/ducks';
import createTemplateReducer from './create/ducks';

export default combineReducers({
  listings: listingsReducer,
  details: detailsReducer,
  create: createTemplateReducer,
});
