import APIClient from '@utils/axios';
import API_URLS from './urls';

export const fetchLTStats = () => (
  APIClient.get(`${API_URLS.STATS}?org=all`)
);

export const fetchOrgStats = () => (
  APIClient.get(`${API_URLS.STATS}`)
);

export const fetchGradingStatus = ({ assessmentId, accessStart, accessEnd }) => {
  const params = {
    access_start: accessStart,
    access_end: accessEnd,
  };

  return APIClient.get(
    `${API_URLS.GRADING_PROGRESS}${encodeURIComponent(assessmentId)}/`,
    { params },
  );
};

export const fetchQuestionsStats = () => (
  APIClient.get(`${API_URLS.QUESTION_STATS}`)
);
