/* eslint-disable no-param-reassign */

import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
  transformArrayToIdMap,
} from '@utils';
import { RUBRIC_EVALUATION_NONE } from 'core/constants/rubricStatus';
import { getLocalDateTime, parseISODateTime } from '@utils/datetime';

// ACTION-TYPES
const pureTypes = {
  FETCH_CANDIDATE_REPORT_REQUEST: null,
  FETCH_CANDIDATE_REPORT_SUCCESS: null,
  FETCH_CANDIDATE_REPORT_FAILURE: null,
  CLEAR_REPORTS_DETAILS: null,

  DOWNLOAD_REPORT_REQUEST: null,
  DOWNLOAD_REPORT_SUCCESS: null,
  DOWNLOAD_REPORT_FAILURE: null,

  EVALUATE_RUBRIC_REQUEST: null,
  EVALUATE_RUBRIC_SUCCESS: null,
  EVALUATE_RUBRIC_FAILURE: null,

  RESET_QUESTION_ATTEMPT_REQUEST: null,
  RESET_QUESTION_ATTEMPT_SUCCESS: null,
  RESET_QUESTION_ATTEMPT_FAILURE: null,
};
export const types = mirrorKeysWithPfx('candidate-report', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchCandidateReport: createAction(types.FETCH_CANDIDATE_REPORT_REQUEST, 'fetchCandidateReport'),
  clearCandidateReport: createAction(types.CLEAR_REPORTS_DETAILS),
  downloadCandidateReport: createAction(types.DOWNLOAD_REPORT_REQUEST, 'downloadCandidateReport'),
  evaluateRubric: createAction(types.EVALUATE_RUBRIC_REQUEST, 'evaluateRubric'),
  resetQuestionAttempt: createAction(types.RESET_QUESTION_ATTEMPT_REQUEST, 'resetQuestionAttempt'),
};

// REDUCER
const INITIAL_STATE = {
  overview: {
    name: '-',
    email: '-',
    assessment: '-',
    isAssessmentExpired: false,
    assessmentTags: [],
    takenOn: null,
    timeTaken: 0,
    rubricPercentage: 0,
    isRubricEvaluated: RUBRIC_EVALUATION_NONE,
    assessmentId: 0,

  },
  reportDetails: {
    totalQuestions: null,
    questions: [],
  },
};

const candidateReportReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_CANDIDATE_REPORT_SUCCESS]: (state, action) => {
    const {
      user: { email, firstName, lastName },
      scheduledAssessment: {
        isGraded,
        title: assessment,
        assessmentTags,
        startDate: takenOn,
        id: assessmentId,
        endDate,
      },
      gradeBreakdown,
      questions,
      percentage,
      pointsTotal,
      timeTaken,
      rubricPercentage,
      isRubricEvaluated,
      totalPercentage,
    } = action.payload;
    const isAssessmentExpired = parseISODateTime(endDate) < getLocalDateTime();
    const gradeBreakdownIdMap = transformArrayToIdMap(gradeBreakdown, 'question');
    const gradedQuestions = questions?.map((question) => (
      { ...question, grade: gradeBreakdownIdMap[question.id] }
    ));

    state.overview = {
      name: `${firstName} ${lastName}`, email, assessment, isAssessmentExpired, assessmentTags, takenOn, percentage, pointsTotal, isGraded, timeTaken, rubricPercentage, isRubricEvaluated, assessmentId, totalPercentage,
    };

    state.reportDetails = { totalQuestions: questions.length, questions: gradedQuestions };
  },
  [types.CLEAR_REPORTS_DETAILS]: () => INITIAL_STATE,
});

export default candidateReportReducer;
