export const loginResponse = {
  redirect_url: null,
  success: true,
};

export const accessToken = {
  expires_in: 36000,
  token_type: 'JWT',
  refresh_token: 'DJAcNs8kcmvNXpLnbBgdnklybYhTnl',
  access_token: `eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJsbXMta2V5Iiwic2NvcGVzIjpbIndyaXR \
                  lIiwicmVhZCIsImVtYWlsIiwicHJvZmlsZSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3 \
                  N0OjE4MDAwL29hdXRoMiIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJnaXZlbl9uYW1lIjo \
                  iIiwiZXhwIjo1MzIzMzUyNzgyLCJhZG1pbmlzdHJhdG9yIjp0cnVlLCJpYXQiOjUzMjMzM \
                  TY3ODIsIm5hbWUiOiIiLCJmYW1pbHlfbmFtZSI6IiIsInN1cGVydXNlciI6dHJ1ZSwiZmlsdGVy \
                  cyI6WyJ1c2VyOm1lIl0sInN1YiI6IjUwY2Y5MmY5YTNkODQ4OWRmOTVlNTgzNTQ5YjkxOWRmIiwi \
                  cHJlZmVycmVkX3VzZXJuYW1lIjoiZWR4IiwiaXNfcmVzdHJpY3RlZCI6ZmFsc2UsImVtYWlsIjoiZWR \
                  4QGV4YW1wbGUuY29tIiwidmVyc2lvbiI6IjEuMi4wIn0.LNawgOwX0sImadw8IyTZ_mjlRwCuAG8GB0dQsqZnRMc`,
  scope: 'write read email profile',
  user_roles: ['Hiring_Manager'],
  payment_plan_limit: {
    description: 'xyz',
    displayName: 'Fremium',
    key: 'lt_fremium_plan',
    limitations: [
      {
        limitValue: 999,
        valueType: 'int',
        key: 'system_users',
        limitation: 'System Users',
      },
      {
        limitValue: ['objective', 'coding'],
        valueType: 'list',
        key: 'question_types',
        limitation: 'Question Types',

      },
      {
        limitValue: 999,
        valueType: 'int',
        key: 'assessments_count',
        limitation: 'Assessments',
      },
      {
        limitValue: 999,
        valueType: 'int',
        key: 'question_creation_count',
        limitation: 'Question Creation',
      },
      {
        limitValue: 999,
        valueType: 'int',
        key: 'enrollments_per_assessment',
      },
    ],
    price: 0,
  },
};

export const logoutResponse = {
  success: true,
  detail: 'Logout Successfully',
};

export const resetPasswordResponse = {
  success: true,
  detail: 'Password update successful',
};

export const forgotPasswordResponse = {
  success: true,
  detail: 'Reset password email has been sent',
};

export const changePasswordResponse = {
  success: true,
  detail: 'Password change successful',
};

export const signupResponse = {
  success: true,
  detail: 'Sign up successful',
};
