/* eslint-disable no-param-reassign */

import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

// ACTION-TYPES
const pureTypes = {
  FETCH_TEMPLATES_REQUEST: null,
  FETCH_TEMPLATES_SUCCESS: null,
  FETCH_TEMPLATES_FAILURE: null,
  CLEAR_TEMPLATES: null,
};

export const types = mirrorKeysWithPfx('templates', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchTemplateListings: createAction(
    types.FETCH_TEMPLATES_REQUEST,
    'fetchTemplates',
  ),
  clearTemplates: createAction(types.CLEAR_TEMPLATES),
};

// REDUCER
const INITIAL_STATE = {
  templates: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

const listingsReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_TEMPLATES_SUCCESS]: (state, action) => {
    state.templates = action.payload;
  },
  [types.CLEAR_TEMPLATES]: (state) => {
    state.templates = INITIAL_STATE.templates;
  },
});

export default listingsReducer;
