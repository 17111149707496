import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchApiSaga } from '@utils';

import { actions as billingActions } from '../billing/ducks';
import { types } from './ducks';

function* createOrUpdatePlanApiSaga(action) {
  const response = yield call(fetchApiSaga, action);
  if (response.success) {
    yield put(billingActions.fetchPaymentMethodsListing());
  }
}

export default function* CheckoutSaga() {
  yield takeLatest(types.FETCH_PLAN_DETAILS_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_UPCOMING_INVOICE_REQUEST, fetchApiSaga);
  yield takeLatest(types.SUBSCRIBE_PLAN_REQUEST, createOrUpdatePlanApiSaga);
  yield takeLatest(types.CHANGE_PLAN_REQUEST, createOrUpdatePlanApiSaga);
}
