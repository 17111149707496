import { takeLatest, takeEvery } from 'redux-saga/effects';
import { fetchApiSaga } from '@utils';
import { types } from './ducks';

export default function* rubricTemplatesListingSaga() {
  yield takeLatest(
    types.FETCH_RUBRIC_TEMPLATES_REQUEST,
    fetchApiSaga,
  );
  yield takeEvery(types.DELETE_RUBRIC_TEMPLATE_REQUEST, fetchApiSaga);
}
