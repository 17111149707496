import {
  createAction,
} from '@utils';

import { getActionKey } from '../utils';

const initialReducerState = {};

const clearUIProptype = 'CLEAR_UI_STATES_REQUEST';

export const actions = {
  clearUIStates: createAction(clearUIProptype),
};

export default function uiReducer(state = initialReducerState, action) {
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action.type);
  if (matches === null && action.type !== clearUIProptype) {
    return state;
  }

  const actionStatus = matches[2];
  const actionKey = getActionKey(action.type);

  switch (action.type) {
    case clearUIProptype: {
      const { [action.payload]: Null, ...rest } = state;
      return rest;
    }
    default:
      return {
        ...state,
        [actionKey]: actionStatus,
      };
  }
}
