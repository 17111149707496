/* eslint-disable no-param-reassign */

import { createAction, mirrorKeysWithPfx, reducerFactory } from '@utils';

// ACTION-TYPES
const pureTypes = {
  FETCH_QUESTIONS_REQUEST: null,
  FETCH_QUESTIONS_SUCCESS: null,
  FETCH_QUESTIONS_FAILURE: null,

  CREATE_TEMPLATE_REQUEST: null,
  CREATE_TEMPLATE_SUCCESS: null,
  CREATE_TEMPLATE_FAILURE: null,
  CLEAR_TEMPLATE_REQUEST: null,
  RESET_STATE: null,
};
export const types = mirrorKeysWithPfx('assessments-create', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchQuestionsList: createAction(types.FETCH_QUESTIONS_REQUEST, 'fetchQuestions'),
  resetState: createAction(types.RESET_STATE),
  createTemplate: createAction(types.CREATE_TEMPLATE_REQUEST, 'createTemplate'),
  clearTemplate: createAction(types.CLEAR_TEMPLATE_REQUEST),
};

// REDUCER
const INITIAL_STATE = {
  questionsList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  isTemplateCreated: false,
  newlyCreatedTemplateId: null,
};

const createTemplateReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_QUESTIONS_SUCCESS]: (state, action) => {
    state.questionsList = action.payload;
  },
  [types.CREATE_TEMPLATE_REQUEST]: (state) => {
    state.isAssessmentRequestCreated = false;
  },
  [types.CREATE_TEMPLATE_SUCCESS]: (state, { payload }) => {
    state.isTemplateCreated = true;
    state.newlyCreatedTemplateId = payload.id;
  },
  [types.CLEAR_TEMPLATE_REQUEST]: (state) => {
    state.isTemplateCreated = INITIAL_STATE.isTemplateCreated;
  },
  [types.RESET_STATE]: (state) => {
    state.questionsList = INITIAL_STATE.questionsList;
  },
});

export default createTemplateReducer;
