import { takeEvery } from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { types } from './ducks';
import {
  downloadReportSaga as downloadCandidateReportSaga,
} from '../shared/reportDownload/report-download-saga';

export default function* candidateReportSaga() {
  yield takeEvery(types.FETCH_CANDIDATE_REPORT_REQUEST, fetchApiSaga);
  yield takeEvery(types.DOWNLOAD_REPORT_REQUEST, downloadCandidateReportSaga);
  yield takeEvery(types.EVALUATE_RUBRIC_REQUEST, fetchApiSaga);
  yield takeEvery(types.RESET_QUESTION_ATTEMPT_REQUEST, fetchApiSaga);
}
