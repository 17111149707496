import APIClient from '@utils/axios';
import { toISO } from '@utils/datetime';

import parseQueryParams from '@utils/query-params';

import API_URLS from './urls';

const headers = {
  'Content-Type': 'application/json',
};

export const fetchAssessments = (data) => {
  let url = data.pageSize
    ? `${API_URLS.ASSESSMENTS}?page=${data.page}&page_size=${data.pageSize}`
    : `${API_URLS.ASSESSMENTS}?page=${data.page}&page_size=0`;

  url = data.searchText
    ? `${url}&search=${data.searchText}`
    : url;

  url = data.sorting
    ? `${url}&ordering=${data.sorting}`
    : url;

  let assessmentFilters = data.filtering;
  assessmentFilters = assessmentFilters
    ? parseQueryParams(assessmentFilters)
    : {};

  if (assessmentFilters?.duration_to) {
    assessmentFilters.duration_to *= 60;
  }
  if (assessmentFilters?.duration_from) {
    assessmentFilters.duration_from *= 60;
  }

  assessmentFilters = new URLSearchParams(assessmentFilters);
  url = assessmentFilters
    ? `${url}&${assessmentFilters}`
    : url;

  url = `${url}&is_deleted=false`;

  return APIClient.get(url);
};

export const fetchAssessmentDetails = (assessmentId) => (
  APIClient.get(`${API_URLS.ASSESSMENTS}${assessmentId}/`)
);

export const updateAssessment = ({
  assessmentId,
  startDateTime,
  endDateTime,
  assessmentTags,
  duration,
  randomize,
  shuffling,
  showAnswer,
  title,
  isTimedAssessment,
  maxAttempts,
  instructions,
  feedbackFormUrl,
  showProgressPage,
  resetAttempt,
  reminder,
  showCustomInstructions,
}) => {
  const requestData = {
    assessmentTags: JSON.stringify(assessmentTags),
    duration: !isTimedAssessment ? 0 : (duration || 0),
    randomize,
    shuffling,
    showAnswer,
    title,
    maxAttempts,
    instructions,
    showCustomInstructions,
    feedbackFormUrl,
    showProgressPage,
    resetAttempt,
    reminder: !isTimedAssessment ? 0 : (reminder || 0),
    startDate: toISO(startDateTime),
    endDate: toISO(endDateTime),
  };

  return APIClient.put(
    `${API_URLS.ASSESSMENTS}${assessmentId}/`,
    requestData,
    { headers },
  );
};

export const deleteAssessment = (assessmentId) => (
  APIClient.delete(`${API_URLS.ASSESSMENTS}${assessmentId}/`)
);
