// eslint-disable-next-line
export const ROLES = {
  HiringManager: 'Hiring_Manager',
  Author: 'Author',
  Contributor: 'Contributor',
};

export const ROLES_LIST = Object.values(ROLES);

export const ALLOWED_ROLES = (allowedRoles) => ROLES_LIST.filter(
  (role) => allowedRoles.indexOf(role) !== -1,
);
