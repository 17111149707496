import axios from 'axios';
import changeObjectCase from 'change-object-case';

import { API_HOST, IS_PRODUCTION } from '../constants';

const changeCaseOptions = {
  throwOnConflict: true,
  recursive: true,
  arrayRecursive: true,
};

const reportContentTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf;',
];

// Setting `axios` GLOBAL DEFAULTS
axios.defaults.setTimeout = 10000;

axios.defaults.transformRequest = [
  (data, headers) => (
    headers['Content-Type'] === 'x-www-form-urlencoded'
      ? data
      : data && JSON.stringify(changeObjectCase.snakeKeys(data, changeCaseOptions))
  ),
];

axios.defaults.transformResponse = [
  (data, headers) => (
    reportContentTypes.includes(headers['content-type'])
      ? data
      : data && changeObjectCase.toCamel(JSON.parse(data), changeCaseOptions)
  ),
];

const createAxios = ({ baseURL }) => {
  const axiosInstance = axios.create({ baseURL });

  axiosInstance.interceptors.request.use(
    (request) => {
      request.headers.Authorization = `JWT ${localStorage.getItem('token')}`;
      return request;
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (err) => {
      if (!IS_PRODUCTION) {
        // eslint-disable-next-line no-console
        console.error('😨❌ Something went wrong.\n', err);
      }
      if (err?.response?.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user_roles');
      }
      return Promise.reject(err);
    },
  );
  return axiosInstance;
};

const APIClient = createAxios({ baseURL: API_HOST });

export default APIClient;
