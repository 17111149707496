import { fork } from 'redux-saga/effects';
import templatesSagas from 'containers/templates/sagas';
import AssessmentsSaga from 'containers/assessments/sagas';
import reportsSaga from 'containers/reports/sagas';
import questionsSaga from 'containers/questions/sagas';
import teamSaga from 'containers/team/sagas';
import candidateReportSaga from 'containers/candidate-report/sagas';
import billingSaga from 'containers/billing/sagas';
import CheckoutSaga from 'containers/checkout/sagas';
import rubricTemplatesSaga from 'containers/rubric-templates/sagas';

import loginSaga from 'containers/shared/auth/sagas';
import dashboardSaga from 'containers/dashboard/sagas';

export default function* rootSaga() {
  yield fork(templatesSagas);
  yield fork(AssessmentsSaga);
  yield fork(reportsSaga);
  yield fork(questionsSaga);
  yield fork(candidateReportSaga);
  yield fork(dashboardSaga);
  yield fork(loginSaga);
  yield fork(teamSaga);
  yield fork(billingSaga);
  yield fork(CheckoutSaga);
  yield fork(rubricTemplatesSaga);
}
