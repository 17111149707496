import { combineReducers } from 'redux';

import templatesReducer from 'containers/templates/reducer';
import candidateReportReducer from 'containers/candidate-report/ducks';
import dashboardReducer from 'containers/dashboard/ducks';
import questionsReducer from 'containers/questions/reducer';
import reportsReducer from 'containers/reports/reducer';
import assessmentsReducer from 'containers/assessments/reducer';
import billingReducer from 'containers/billing/ducks';
import checkoutReducer from 'containers/checkout/ducks';
import teamReducer from 'containers/team/ducks';
import rubricTemplatesReducer from 'containers/rubric-templates/reducer';

import loginReducer from 'containers/shared/auth/ducks';
import uiReducer from 'containers/shared/uiEffects';
import errorReducer from 'containers/shared/errorTracking';

export default combineReducers({
  templates: templatesReducer,
  dashboard: dashboardReducer,
  reports: reportsReducer,
  questions: questionsReducer,
  auth: loginReducer,
  assessments: assessmentsReducer,
  billing: billingReducer,
  checkout: checkoutReducer,
  team: teamReducer,
  candidateReport: candidateReportReducer,
  rubricTemplates: rubricTemplatesReducer,
  ui: uiReducer,
  error: errorReducer,
});
