/* eslint-disable no-param-reassign */

import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

// ACTION-TYPES
const pureTypes = {
  FETCH_TEMPLATE_DETAILS_REQUEST: null,
  FETCH_TEMPLATE_DETAILS_SUCCESS: null,
  FETCH_TEMPLATE_DETAILS_FAILURE: null,

  SCHEDULE_ASSESSMENT_REQUEST: null,
  SCHEDULE_ASSESSMENT_SUCCESS: null,
  SCHEDULE_ASSESSMENT_FAILURE: null,

  FETCH_ASSESSMENT_COUNT_REQUEST: null,
  FETCH_ASSESSMENT_COUNT_SUCCESS: null,
  FETCH_ASSESSMENT_COUNT_FAILURE: null,

  CLEAR_TEMPLATE_DETAILS: null,
  CLEAR_IS_SCHEDULED: null,
  CLEAR_NEXT_URL: null,
};
export const types = mirrorKeysWithPfx('templates-detail', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchTemplateDetails: createAction(types.FETCH_TEMPLATE_DETAILS_REQUEST, 'fetchTemplateDetails'),
  scheduleAssessment: createAction(types.SCHEDULE_ASSESSMENT_REQUEST, 'scheduleAssessment'),
  fetchAssessmentCount: createAction(types.FETCH_ASSESSMENT_COUNT_REQUEST, 'fetchAssessmentsCount'),
  clearTemplateDetails: createAction(types.CLEAR_TEMPLATE_DETAILS),
  clearIsScheduled: createAction(types.CLEAR_IS_SCHEDULED),
  clearNextUrl: createAction(types.CLEAR_NEXT_URL),
};

// REDUCER
const INITIAL_STATE = {
  data: {},
  isScheduled: false,
  nextUrl: '#',
  assessmentCount: 0,
};

const detailsReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_TEMPLATE_DETAILS_SUCCESS]: (state, action) => {
    state.data = action.payload;
  },
  [types.SCHEDULE_ASSESSMENT_REQUEST]: (state) => {
    state.isScheduled = false;
  },
  [types.SCHEDULE_ASSESSMENT_SUCCESS]: (state, { payload }) => {
    state.isScheduled = true;
    state.nextUrl = (
      `/assessments/${payload.id}`
    );
  },
  [types.CLEAR_TEMPLATE_DETAILS]: (state) => {
    state.data = {};
    state.assessmentCount = 0;
  },
  [types.CLEAR_IS_SCHEDULED]: (state) => {
    state.isScheduled = INITIAL_STATE.isScheduled;
  },
  [types.CLEAR_NEXT_URL]: (state) => {
    state.nextUrl = INITIAL_STATE.nextUrl;
  },
  [types.FETCH_ASSESSMENT_COUNT_SUCCESS]: (state, { payload }) => {
    state.assessmentCount = payload.count;
  },
});

export default detailsReducer;
