const API_URLS = Object.freeze({
  TEMPLATES: '/litmustest/core/api/assessment-templates/',
  ORG_MEMBERS: '/litmustest/core/api/admin_users/',
  ENROLLMENTS: '/litmustest/core/api/v2/enrolments/',
  CANDIDATE_REPORT: '/litmustest/core/api/v2/candidate-reports/',
  QUESTIONS: '/litmustest/core/api/question/',
  EXPORT_QUESTIONS: '/litmustest/core/api/export_questions/',
  IMPORT_QUESTIONS: '/litmustest/core/api/question/uploadfile/',
  REPORTS: '/litmustest/core/api/v2/reports/',

  STATS: '/litmustest/stats/api/enrollments/',
  QUESTION_STATS: '/litmustest/stats/api/question-stats/',

  ACCESS_TOKEN: '/litmustest_organization/access_token/',
  LOGIN: '/litmustest_organization/login/',
  SIGNUP: '/litmustest_organization/signup/',
  LOGOUT: '/litmustest_organization/logout/',
  CHANGE_PASSWORD: '/litmustest_organization/password-reset/',
  FORGOT_PASSWORD: '/litmustest_organization/password-change-request/',
  RESET_PASSWORD: '/litmustest_organization/token_password_reset/',
  VERIFY_ACCOUNT: '/litmustest_organization/token_account_verify/',
  RESET_DEFAULT_PASSWORD: '/litmustest_organization/default-password-reset/',

  ASSESSMENTS: '/litmustest/core/api/v2/scheduled_assessments/',
  ASSESTS: '/litmustest/core/api/v2/assets/',

  USER_ROLES: '/litmustest/roles/api/user-roles/',

  PAYMENT_PLANS: '/litmustest/billing/api/payment-plan/',

  CURRENT_PLAN: '/litmustest/billing/api/current-plan/',
  SUBSCRIBE_PLAN: '/litmustest/billing/api/subscription/',
  CANCEL_PLAN: '/litmustest/billing/api/subscription/cancel/',
  REACTIVATE_PLAN: '/litmustest/billing/api/subscription/reactivate/',
  CHANGE_PLAN: '/litmustest/billing/api/subscription/change_subscription/',

  PAYMENT_METHODS: '/litmustest/billing/api/payment-method/',

  UPCOMING_INVOICE: '/litmustest/billing/api/subscription/get_upcoming_invoice/',

  PAYMENT_HISTORY: '/litmustest/billing/api/payment-history/',
  EVALUATE_RUBRIC: '/litmustest/core/api/evaluate-rubric/',
  RUBRIC_TEMPLATES: '/litmustest/core/api/rubric-templates/',
});

export default API_URLS;
