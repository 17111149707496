import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { IS_PRODUCTION, DISABLE_MSW } from './core/constants';

if (!IS_PRODUCTION && !DISABLE_MSW) {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
