import APIClient from '@utils/axios';
import { toISO } from '@utils/datetime';

import API_URLS from './urls';

const headers = {
  'Content-Type': 'application/json',
};

export const fetchTemplates = (data) => {
  let url = data.pageSize
    ? `${API_URLS.TEMPLATES}?page=${data.page}&page_size=${data.pageSize}`
    : `${API_URLS.TEMPLATES}?page=${data.page}&page_size=0`;

  url = data.searchText
    ? `${url}&search=${data.searchText}`
    : url;

  url = data.sorting
    ? `${url}&ordering=${data.sorting}`
    : url;

  url = data.filtering
    ? `${url}&${data.filtering}`
    : url;

  return APIClient.get(url);
};

export const fetchTemplateDetails = (assessmentId) => (
  APIClient.get(`${API_URLS.TEMPLATES}${assessmentId}/`)
);

export const createTemplate = (data) => (
  APIClient.post(API_URLS.TEMPLATES, data, { headers })
);

export const editTemplate = (data) => {
  const { assessmentId, ...payload } = data;
  return APIClient.put(`${API_URLS.TEMPLATES}${assessmentId}/`, payload, { headers });
};

export const scheduleAssessment = (data) => {
  const {
    questionPool,
    startDateTime,
    endDateTime,
    assessmentTags,
    duration,
    assessmentId,
    title,
    showAnswer,
    randomize,
    shuffling,
    maxAttempts,
    questionList,
    instructions,
    feedbackFormUrl,
    reminder,
    showProgressPage,
    resetAttempt,
    showCustomInstructions,
  } = data;
  let questionIds = [];
  let assessmentTemplate = null;

  if (assessmentId) {
    questionIds = questionPool.map((q) => q.id);
    assessmentTemplate = assessmentId;
  } else questionIds = questionList;

  const startDate = toISO(startDateTime);
  const endDate = toISO(endDateTime);

  return APIClient.post(
    API_URLS.ASSESSMENTS,
    {
      questionIds,
      startDate,
      endDate,
      assessmentTags: JSON.stringify(assessmentTags),
      duration,
      title,
      assessmentTemplate,
      showAnswer,
      randomize,
      shuffling,
      maxAttempts,
      reminder,
      instructions: instructions?.trim() === '' ? null : instructions?.trim(),
      showCustomInstructions,
      feedbackFormUrl: feedbackFormUrl?.trim() === '' ? null : feedbackFormUrl?.trim(),
      showProgressPage,
      resetAttempt,
    },
    { headers },
  );
};

export const fetchAssessmentsCount = () => {
  // We can use the listing endpoint to get the count.
  // To reduce data usage, we only ask for one record.
  // Ideally, a separate endpoint for this purpose would be required.
  const url = `${API_URLS.ASSESSMENTS}?page=1&page_size=1&is_deleted=false`;

  return APIClient.get(url);
};
