import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from 'components/CircularProgress';

import useStyles from './styles';

const FormLayout = ({ children, loading }) => {
  const classes = useStyles();

  return (
    <>
      <div id="main" className={classes.mainContent}>
        {
          loading
            ? (
              <div className={classes.circularProgressContainer}>
                <CircularProgress />
              </div>
            ) : children
        }
      </div>
    </>
  );
};

FormLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loading: PropTypes.bool,
};

FormLayout.defaultProps = {
  children: null,
  loading: false,
};

export default FormLayout;
