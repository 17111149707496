import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

const pureTypes = {
  FETCH_RUBRIC_TEMPLATES_REQUEST: null,
  FETCH_RUBRIC_TEMPLATES_SUCCESS: null,
  FETCH_RUBRIC_TEMPLATES_FAILURE: null,
  CLEAR_RUBRIC_TEMPLATES: null,

  DELETE_RUBRIC_TEMPLATE_REQUEST: null,
  DELETE_RUBRIC_TEMPLATE_SUCCESS: null,
  DELETE_RUBRIC_TEMPLATE_FAILURE: null,
};

export const types = mirrorKeysWithPfx('rubricTemplates', pureTypes);

export const actions = {
  fetchRubricTemplates: createAction(
    types.FETCH_RUBRIC_TEMPLATES_REQUEST,
    'fetchRubricTemplates',
  ),
  clearRubricTemplates: createAction(types.CLEAR_RUBRIC_TEMPLATES),
  deleteRubricTemplate: createAction(types.DELETE_RUBRIC_TEMPLATE_REQUEST, 'deleteRubricTemplate'),
};

const INITIAL_STATE = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const rubricTemplatesListingReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_RUBRIC_TEMPLATES_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [types.CLEAR_RUBRIC_TEMPLATES]: () => INITIAL_STATE,
});

export default rubricTemplatesListingReducer;
