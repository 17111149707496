import React, { Suspense } from 'react';
import {
  Router,
  Switch,
} from 'react-router-dom';

import CircularProgress from 'components/CircularProgress';

import { browserHistory } from '@utils/history';

import Route from './Route';

import { ROUTES } from './constants';
import {
  Login,
  ResetPassword,
  VerifyAccount,
  Signup,
  AssessmentDetails,
  TemplatesListings,
  EditTemplate,
  CreateTemplate,
  CandidateReport,
  RubricEvaluation,
  Dashboard,
  ReportsDetails,
  ReportsListings,
  QuestionsListing,
  QuestionDetails,
  QuestionEdit,
  AssessmentsListings,
  Team,
  Profile,
  Billing,
  AssessmentsDetails,
  QuestionsCreate,
  Checkout,
  RubricTemplatesListing,
  RubricTemplatesCreate,
  RubricTemplatesDetails,
  RubricTemplatesEdit,
} from './lazyContainers';
import NotFoundPage from './404';
import NotAccessiblePage from './403';

const Routes = () => (
  <Router history={browserHistory}>
    <Suspense fallback={<div><CircularProgress /></div>}>
      <Switch>
        <Route path={ROUTES.login} component={Login} restricted={false} />
        <Route path={ROUTES.resetPassword} component={ResetPassword} restricted={false} />
        <Route path={ROUTES.verifyAccount} component={VerifyAccount} restricted={false} />
        <Route path={ROUTES.signup} component={Signup} restricted={false} />
        <Route exact path={ROUTES.resetDefaultPassword} component={ResetPassword} />
        <Route path={ROUTES.dashboard} component={Dashboard} />
        <Route exact path={ROUTES.templates} component={TemplatesListings} />
        <Route exact path={ROUTES.templateDetails} component={AssessmentDetails} />
        <Route path={ROUTES.editTemplate} component={EditTemplate} />
        <Route path={ROUTES.createTemplate} component={CreateTemplate} />
        <Route path={ROUTES.candidateReport} component={CandidateReport} />
        <Route path={ROUTES.rubricEvaluation} component={RubricEvaluation} />
        <Route exact path={ROUTES.assessments} component={AssessmentsListings} />
        <Route
          exact
          path={ROUTES.assessmentDetails}
          component={AssessmentsDetails}
        />
        <Route
          exact
          path="/"
          component={Dashboard}
        />
        <Route exact path={ROUTES.reports} component={ReportsListings} />
        <Route path={ROUTES.assessmentReport} component={ReportsDetails} />
        <Route exact path={ROUTES.questions} component={QuestionsListing} />
        <Route path={ROUTES.questionCreate} component={QuestionsCreate} />
        <Route exact path={ROUTES.questionDetails} component={QuestionDetails} />
        <Route path={ROUTES.questionEdit} component={QuestionEdit} />
        <Route path={ROUTES.team} component={Team} />
        <Route exact path={ROUTES.profile} component={Profile} />
        <Route exact path={ROUTES.billing} component={Billing} />
        <Route path={ROUTES.checkout} component={Checkout} />
        <Route exact path={ROUTES.rubricTemplates} component={RubricTemplatesListing} />
        <Route path={ROUTES.rubricTemplateCreate} component={RubricTemplatesCreate} />
        <Route exact path={ROUTES.rubricTemplateDetails} component={RubricTemplatesDetails} />
        <Route path={ROUTES.rubricTemplateEdit} component={RubricTemplatesEdit} />

        <Route path={ROUTES.notFound} component={NotFoundPage} />
        <Route path={ROUTES.notAccessible} component={NotAccessiblePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  </Router>
);

export default Routes;
