import changeObjectCase from 'change-object-case';

import APIClient from '@utils/axios';
import parseQueryParams from '@utils/query-params';

import API_URLS from './urls';

const changeCaseOptions = {
  throwOnConflict: true,
  recursive: true,
  arrayRecursive: true,
};

export const fetchReports = (data) => {
  let url = data.pageSize
    ? `${API_URLS.REPORTS}?page=${data.page}&page_size=${data.pageSize}`
    : `${API_URLS.REPORTS}?page=${data.page}&page_size=0`;

  url = data.searchText
    ? `${url}&search=${data.searchText}`
    : url;

  url = data.sorting
    ? `${url}&ordering=${data.sorting}`
    : url;

  url = data.filtering
    ? `${url}&${data.filtering}`
    : url;

  return APIClient.get(url);
};

export const fetchReportDetails = (id) => APIClient.get(`${API_URLS.REPORTS}${id}/`);

export const fetchCandidateReport = (id) => (
  APIClient.get(`${API_URLS.CANDIDATE_REPORT}${id}/`)
);

export const downloadCandidateReport = ({ id, fileName }) => (
  APIClient.get(
    `${API_URLS.CANDIDATE_REPORT}${id}/download/`,
    {
      responseType: 'blob',
      'Content-Disposition': `inline; filename="${fileName}"`,
    },
  )
);

export const downloadReport = ({ id, fileName, rows }) => (
  APIClient.post(
    `${API_URLS.REPORTS}${id}/download/`,
    {
      selectedRows: rows,
    },
    {
      responseType: 'blob',
      'Content-Disposition': `inline; filename="${fileName}"`,
    },
  )
);

export const startGrading = ({ id, selectedRows }) => {
  const url = `${API_URLS.REPORTS}${id}/gradeRubric/`;
  const data = {
    selectedRows,
  };
  return APIClient.post(url, data);
};

export const fetchGradedCandidatesReport = ({
  id,
  page,
  pageSize,
  searchText,
  sorting,
  filtering,
}) => {
  let url = pageSize
    ? `${API_URLS.REPORTS}${id}/attempts/?page=${page}&page_size=${pageSize}`
    : `${API_URLS.REPORTS}${id}/attempts/?page=1&page_size=5`;

  url = sorting
    ? `${url}&ordering=${sorting}`
    : url;

  url = searchText
    ? `${url}&search=${searchText}`
    : url;

  let reportFilters = filtering;
  reportFilters = reportFilters
    ? parseQueryParams(reportFilters)
    : {};

  if (reportFilters?.percentage_to) {
    reportFilters.percentage_to /= 100;
  }
  if (reportFilters?.percentage_from) {
    reportFilters.percentage_from /= 100;
  }
  if (reportFilters?.total_percentage_to) {
    reportFilters.total_percentage_to /= 100;
  }
  if (reportFilters?.total_percentage_from) {
    reportFilters.total_percentage_from /= 100;
  }

  reportFilters = new URLSearchParams(reportFilters);

  url = reportFilters
    ? `${url}&${reportFilters}`
    : url;

  return APIClient.get(url);
};

export const resetAttempt = ({ id, rows }) => {
  const url = `${API_URLS.REPORTS}${id}/reset/`;
  const data = {
    selectedRows: rows,
  };
  return APIClient.post(url, data);
};

export const resetQuestionAttempt = ({ id, questionId }) => {
  const url = `${API_URLS.REPORTS}${id}/reset_question/`;
  const data = {
    questionId,
  };
  return APIClient.post(url, data);
};

export const evaluateRubric = (data) => {
  const response = APIClient.post(
    API_URLS.EVALUATE_RUBRIC,
    changeObjectCase.snakeKeys(data, changeCaseOptions),
  );
  return response;
};

export const recalculateScores = ({ id }) => APIClient.get(`${API_URLS.REPORTS}${id}/recalculateScores/`);
