/* eslint-disable no-param-reassign */

import { createAction, mirrorKeysWithPfx, reducerFactory } from '@utils';

// ACTION-TYPES
const pureTypes = {
  LOGIN_REQUEST: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAILURE: null,

  LOGOUT_REQUEST: null,
  LOGOUT_SUCCESS: null,
  LOGOUT_FAILURE: null,

  SIGNUP_REQUEST: null,
  SIGNUP_SUCCESS: null,
  SIGNUP_FAILURE: null,

  CHANGE_PASSWORD_REQUEST: null,
  CHANGE_PASSWORD_SUCCESS: null,
  CHANGE_PASSWORD_FAILURE: null,

  FETCH_USER_DETAILS_REQUEST: null,
  FETCH_USER_DETAILS_SUCCESS: null,
  FETCH_USER_DETAILS_FAILURE: null,

  FORGOT_PASSWORD_REQUEST: null,
  FORGOT_PASSWORD_SUCCESS: null,
  FORGOT_PASSWORD_FAILURE: null,

  RESET_PASSWORD_REQUEST: null,
  RESET_PASSWORD_SUCCESS: null,
  RESET_PASSWORD_FAILURE: null,

  VERIFY_ACCOUNT_REQUEST: null,
  VERIFY_ACCOUNT_SUCCESS: null,
  VERIFY_ACCOUNT_FAILURE: null,

  UPDATE_USER_DETAILS_REQUEST: null,
  UPDATE_USER_DETAILS_SUCCESS: null,
  UPDATE_USER_DETAILS_FAILURE: null,
};
export const types = mirrorKeysWithPfx('auth', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  loginRequest: createAction(types.LOGIN_REQUEST, 'login'),
  loginFailure: createAction(types.LOGIN_FAILURE),
  loginSuccess: createAction(types.LOGIN_SUCCESS),

  logoutRequest: createAction(types.LOGOUT_REQUEST, 'logout'),
  logoutFailure: createAction(types.LOGOUT_FAILURE),
  logoutSuccess: createAction(types.LOGOUT_SUCCESS),

  signupRequest: createAction(types.SIGNUP_REQUEST, 'signup'),
  signupFailure: createAction(types.SIGNUP_FAILURE),
  signupSuccess: createAction(types.SIGNUP_SUCCESS),

  changeRequest: createAction(types.CHANGE_PASSWORD_REQUEST, 'changePassword'),
  changeFailure: createAction(types.CHANGE_PASSWORD_SUCCESS),
  changeSuccess: createAction(types.CHANGE_PASSWORD_FAILURE),

  forgotRequest: createAction(types.FORGOT_PASSWORD_REQUEST, 'forgotPassword'),
  forgotFailure: createAction(types.FORGOT_PASSWORD_SUCCESS),
  forgotSuccess: createAction(types.FORGOT_PASSWORD_FAILURE),

  resetRequest: createAction(types.RESET_PASSWORD_REQUEST, 'resetPassword'),
  resetFailure: createAction(types.RESET_PASSWORD_SUCCESS),
  resetSuccess: createAction(types.RESET_PASSWORD_FAILURE),

  verifyRequest: createAction(types.VERIFY_ACCOUNT_REQUEST, 'verifyAccount'),
  verifyFailure: createAction(types.VERIFY_ACCOUNT_SUCCESS),
  verifySuccess: createAction(types.VERIFY_ACCOUNT_FAILURE),

  fetchUserDetails: createAction(types.FETCH_USER_DETAILS_REQUEST, 'fetchUserDetails'),
  fetchUserDetailsSuccess: createAction(types.FETCH_USER_DETAILS_SUCCESS),
  fetchUserDetailsFailure: createAction(types.FETCH_USER_DETAILS_FAILURE),

  updateUserDetails: createAction(types.UPDATE_USER_DETAILS_REQUEST, 'updateUserDetails'),
  updateUserDetailsSuccess: createAction(types.UPDATE_USER_DETAILS_SUCCESS),
  updateUserDetailsFailure: createAction(types.UPDATE_USER_DETAILS_FAILURE),
};

// REDUCER
const INITIAL_STATE = {
  userInfo: localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {},
  userRoles: localStorage.user_roles ? localStorage.user_roles : [],
  userRouteAccess: localStorage.userRouteAccess ? JSON.parse(localStorage.userRouteAccess) : {},
};

const loginReducer = reducerFactory(INITIAL_STATE, {
  [types.LOGIN_SUCCESS]: (state, action) => {
    state.userRoles = action.payload;
  },
  [types.LOGOUT_SUCCESS]: (state) => {
    state.userInfo = {};
    state.userRoles = [];
    state.userRouteAccess = {};

    localStorage.removeItem('token');
    localStorage.removeItem('user_roles');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userRouteAccess');
    localStorage.removeItem('currentPlan');
  },
  [types.FETCH_USER_DETAILS_SUCCESS]: (state, action) => {
    state.userInfo = action.payload;
    state.userRoles = action.payload.userRoles;
    state.userRouteAccess = action.payload.userRouteAccess;
  },
});

export default loginReducer;
