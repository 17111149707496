import React from 'react';
import PropTypes from 'prop-types';
import {
  Route as RRRoute,
  useLocation,
  Redirect,
} from 'react-router-dom';

import parseQueryParams from '@utils/query-params';
import { isAuthenticated, isTokenExpired } from '@utils';
import { selectUserDetails, selectUserRoles } from 'containers/shared/auth/selectors';
import { useSelector } from 'react-redux';
import { ROUTE_DETAILS, ROUTES } from './constants';
import NotFoundPage from './404';

const Route = ({
  restricted,
  component: Component,
  ...rest
}) => {
  const location = useLocation();
  const isLoggedIn = isAuthenticated();
  const tokenExpired = isTokenExpired();
  const userRoles = useSelector(selectUserRoles);
  const userDetails = useSelector(selectUserDetails);
  const { hasDefaultPassword } = userDetails;

  const parsedParams = parseQueryParams(location.search);

  function routeStructure(url) {
    if (url === '/' && isLoggedIn) {
      return '/dashboard';
    }
    if (url.substr(-1) === '/') {
      return url.substr(0, url.length - 1);
    }
    return url;
  }

  function hasUserAccess() {
    const regex = /^\/([^/]+)/;
    const url = routeStructure(regex.exec(location.pathname)?.[0] || '/');
    if (typeof ROUTE_DETAILS[url] !== 'undefined') {
      const { access } = ROUTE_DETAILS[url];
      if (access !== null) {
        const filteredArray = access.filter((n) => userRoles.indexOf(n) !== -1);
        return (filteredArray.length > 0);
      }
    }
    return true;
  }

  if (tokenExpired) {
    localStorage.removeItem('token');
    localStorage.removeItem('user_roles');
    return <Redirect to={{ pathname: ROUTES.login, state: { from: location } }} />;
  }

  if (!location.pathname.match(ROUTES.resetDefaultPassword) && hasDefaultPassword) {
    return <Redirect to={ROUTES.resetDefaultPassword} />;
  }

  if (location.pathname.match(ROUTES.resetDefaultPassword) && !hasDefaultPassword) {
    return <Redirect to={ROUTES.dashboard} />;
  }

  if (location.pathname.match('/login') && isLoggedIn) {
    if (parsedParams?.nextUrl) {
      return <Redirect to={parsedParams?.nextUrl} />;
    }
    return <Redirect to={ROUTES.dashboard} />;
  }

  if (location.pathname.match('/litmustest_organization/token_password_reset') && isLoggedIn) {
    return <Redirect to={ROUTES.dashboard} />;
  }

  if (location.pathname.match('/signup') && isLoggedIn) {
    return <Redirect to={ROUTES.dashboard} />;
  }

  if (!isLoggedIn && restricted) {
    const { path: currentPath, location: nextLocation } = rest;

    const nextUrl = `${nextLocation?.pathname}${encodeURIComponent(nextLocation?.search)}`;
    const isCurrentPathValid = ROUTE_DETAILS[currentPath];

    if (isCurrentPathValid && nextUrl) {
      const redirectUrl = `?nextUrl=${nextUrl}`;
      return (
        <Redirect to={{
          search: redirectUrl,
          pathname: ROUTES.login,
          state: { from: location },
        }}
        />
      );
    }
    return <Redirect to={{ pathname: ROUTES.login, state: { from: location } }} />;
  }

  if (isLoggedIn && !hasUserAccess()) {
    return <Redirect to={ROUTES.notAccessible} />;
  }

  return (
    <RRRoute
      {...rest}
      render={(props) => {
        const slugName = rest?.path?.match(/:([a-z]*)/);
        // handle edge case if we end up calling with slug
        // i.e. calling assessment details as 'assessment/:id'

        // eslint-disable-next-line react/prop-types
        if (slugName && `:${slugName[1]}` === props.match.params[slugName[1]]) {
          return <NotFoundPage {...props} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

Route.propTypes = {
  component: PropTypes.func,
  restricted: PropTypes.bool,
};

Route.defaultProps = {
  component: null,
  restricted: true,
};

export default Route;
