import React from 'react';
import { Link } from 'react-router-dom';

import { Grid, Typography } from 'components/MaterialUI';

import { isAuthenticated } from '@utils';

import { actions } from 'containers/shared/auth/ducks';
import { useDispatch } from 'react-redux';
import useStyles from './styles';
import { ROUTES } from '../constants';

const NotAccessiblePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleLogout = () => {
    dispatch(actions.logoutRequest());
  };

  const [text, link] = isAuthenticated()
    ? ['Dashboard', ROUTES.dashboard] : ['Login', ROUTES.login];

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      justifyContent="center"
      className={classes.root}
    >
      <Typography variant="h3" className={classes.fourOhFour}>403</Typography>
      <Typography variant="h4" className={classes.missing} gutterBottom>ACCESS FORBIDDEN</Typography>
      <Typography>
        You do not have permission to open the page you are looking for.
        <br />
        Go to&nbsp;
        <Link to={link}>{text}</Link>
        &nbsp;
        <br />
        OR
        <br />
        <button type="button" className={classes.logoutLink} onClick={handleLogout}>Logout</button>
        &nbsp; to login with an other user again.
      </Typography>
    </Grid>
  );
};

export default NotAccessiblePage;
