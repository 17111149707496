export const checkQuestionType = (question) => (
  Object.prototype.hasOwnProperty.call(question, 'earnedScore')
    ? 'OBJECTIVE'
    : 'CODING'
);

export const isObjectiveQuestion = (question) => (
  Object.prototype.hasOwnProperty.call(question, 'earnedScore')
);

export const calculateTestCasesWeightage = (testCases, totalPossibleScore) => {
  const newTestCasesArray = testCases.map((testCase) => ({
    ...testCase,
    weightage: (testCase.points / totalPossibleScore) * 100,
  }));

  return newTestCasesArray;
};
