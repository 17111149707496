import { ROUTE_DETAILS } from 'core/routes/constants';

/**
 * Returns True if user has access to the ROUTE
 * @param {string} route use ROUTES from 'routes/constants'
 * @param {*} userRoles use selectUserRoles from 'shared/auth'
 * @returns True if user has access to the route
 */
const hasUserAccess = (route, userRoles = []) => {
  const routeDetails = ROUTE_DETAILS?.[route];
  if (!routeDetails) return false;

  const allowedRoles = routeDetails?.access;
  if (!allowedRoles) return true;

  const filteredArray = allowedRoles.filter((n) => userRoles.indexOf(n) !== -1);
  return (filteredArray.length > 0);
};

export default hasUserAccess;
