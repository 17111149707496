import changeObjectCase from 'change-object-case';

import APIClient from '@utils/axios';
import { constructFormData } from '@utils/common';
import questionTypes, { questionSubTypes } from '@utils/question-types';
import olxGenerators from '@utils/olx-generators';

import { PLATFORM_NAME } from 'core/constants/organization';
import {
  calculatePossibleTestCasesScore,
  calculatePossibleRubricScore,
  calculateScoringMethod,
} from 'containers/questions/create/utils';

import API_URLS from './urls';

const headers = {
  'Content-Type': 'x-www-form-urlencoded',
};

const changeCaseOptions = {
  throwOnConflict: true,
  recursive: true,
  arrayRecursive: true,
};

export const uploadQuestionsFile = (payload) => (
  APIClient.post(API_URLS.IMPORT_QUESTIONS, payload, { headers })
);
export const fetchQuestionDetails = (questionId) => APIClient(`${API_URLS.QUESTIONS}${questionId}/`);

export const fetchQuestions = (data) => {
  let url = data.pageSize
    ? `${API_URLS.QUESTIONS}?page=${data.page}&page_size=${data.pageSize}`
    : `${API_URLS.QUESTIONS}?page=${data.page}&page_size=0`;

  url = data.searchText
    ? `${url}&search=${data.searchText}`
    : url;

  url = data.filtering
    ? `${url}&${data.filtering}`
    : url;

  url = !url.includes('ordering') && data.sorting
    ? `${url}&ordering=${data.sorting}`
    : url;

  if (url.includes('is_deleted')) {
    url = url.includes('ordering')
      ? url : `${url}&ordering=-deleted`;
    url = `${url}&is_edited=false`;
  } else {
    url = `${url}&is_deleted=false`;
  }
  if (url.includes('approved_status') && !url.includes('is_contributed')) {
    url = `${url}&is_contributed=true`;
  } else if (data.isHiringManager && !url.includes('is_contributed')) {
    url = `${url}&is_contributed=false`;
  }

  return APIClient.get(url);
};

export const fetchQuestionsCount = () => {
  const url = `${API_URLS.QUESTIONS}question_count/`;

  return APIClient.get(url);
};

function testCasesToMetadata(testCases, questionType, subCategory) {
  const metadata = {
    sample: {},
    staff: {},
  };

  if (
    questionType !== questionTypes.Coding
    || subCategory === questionSubTypes.Coding.DesignProblem
  ) {
    return metadata;
  }

  let sampleCount = 1;
  let staffCount = 1;

  testCases?.forEach((testCase) => {
    const testCaseData = {
      input: testCase?.input,
      output: testCase?.output,
      points: testCase?.points,
    };
    if (testCase.type === 'sample') {
      metadata.sample = {
        ...metadata.sample,
        [sampleCount.toString()]: testCaseData,
      };
      sampleCount += 1;
    } else {
      metadata.staff = {
        ...metadata.staff,
        [staffCount.toString()]: testCaseData,
      };
      staffCount += 1;
    }
  });

  return metadata;
}

function rubricToMetaData(rubricCriterias) {
  return rubricCriterias?.map((criteria, i) => ({
    ...criteria,
    order_num: i,
    options: criteria.options?.map((option, j) => ({
      ...option,
      order_num: j,
    })),
    is_automated: criteria.isAutomated,
  }));
}

export const createQuestion = async (payload) => {
  const {
    editID,
    title,
    statement,
    difficulty,
    questionType,
    options,
    questionTags,
    testCases,
    rubricCriterias,
    feedbackPrompt,
    feedbackDefaultText,
    subCategory,
    possibleScore,
    randomizationScript,
    htmlBody,
  } = payload;

  const isCoding = questionType === questionTypes.Coding;
  const isRandomized = subCategory === questionSubTypes.Objective.Randomized;
  const isDesignProblem = subCategory === questionSubTypes.Coding.DesignProblem;
  const hasSubcategory = isRandomized || isDesignProblem;

  const metadata = testCasesToMetadata(testCases, questionType);

  if (isCoding && rubricCriterias.length > 0) {
    metadata.rubric = {
      feedback_prompt: feedbackPrompt,
      feedback_default_text: feedbackDefaultText,
      criterias: rubricToMetaData(rubricCriterias),
      possible_score: calculatePossibleRubricScore(rubricCriterias),
      scoring_method: calculateScoringMethod(rubricCriterias),
    };
  }

  const content = isRandomized
    ? (
      olxGenerators[subCategory]({
        randomizationScript,
        htmlBody,
      })
    )
    : (
      olxGenerators[questionType]({
        statement,
        options,
      })
    );

  const data = {
    title,
    content,
    difficulty,
    isRandomized,
    category: questionType,
    platform: PLATFORM_NAME,
    metadata: JSON.stringify(metadata),
    questionTags: JSON.stringify(questionTags),
    possibleScore: isCoding
      ? calculatePossibleTestCasesScore(testCases)
      : possibleScore,
  };

  if (hasSubcategory) {
    data.subCategory = subCategory;
  }

  const transformedData = changeObjectCase.snakeKeys(data, changeCaseOptions);
  const response = editID
    ? APIClient.put(`${API_URLS.QUESTIONS}${editID}/`, constructFormData(transformedData), { headers })
    : APIClient.post(API_URLS.QUESTIONS, constructFormData(transformedData), { headers });
  return response;
};

export const deleteQuestion = (questionId) => (
  APIClient.delete(`${API_URLS.QUESTIONS}${questionId}/`)
);

export const restoreQuestion = (questionId) => (
  APIClient.post(`${API_URLS.QUESTIONS}${questionId}/restore/`)
);

export const reevaluateQuestion = (questionId) => {
  const data = {
    question: questionId,
  };
  return APIClient.post(`${API_URLS.QUESTIONS}reevaluate/`, constructFormData(data), { headers });
};

export const reviewQuestion = (data) => (
  APIClient.post(`${API_URLS.QUESTIONS}review/`, constructFormData(
    changeObjectCase.snakeKeys(data, changeCaseOptions),
  ), { headers })
);

export const exportQuestions = ({ filters, fileName }) => {
  let url = `${API_URLS.QUESTIONS}download/?page_size=0`;

  if (filters.selectedRows) {
    url = `${url}&id=${filters.selectedRows}`;
  } else {
    url = filters.searchText
      ? `${url}&search=${filters.searchText}`
      : url;

    url = filters.filtering
      ? `${url}&${filters.filtering}`
      : url;

    url = !url.includes('ordering') && filters.sorting
      ? `${url}&ordering=${filters.sorting}`
      : url;

    if (url.includes('is_deleted')) {
      url = url.includes('ordering')
        ? url : `${url}&ordering=-deleted`;
      url = `${url}&is_edited=false`;
    } else {
      url = `${url}&is_deleted=false`;
    }
  }

  return APIClient.get(
    url,
    {
      responseType: 'blob',
      'Content-Disposition': `inline; filename="${fileName}"`,
    },
  );
};
