import {
  takeLatest,
} from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { types } from './ducks';

export default function* templateSaga() {
  yield takeLatest(types.FETCH_TEMPLATES_REQUEST, fetchApiSaga);
}
