import produce from 'immer';

/**
 * Mirror the key as value and prepend the prefix with type
 * to generate unique action types. Avoid repetitions in the action types.
 *
 * @param {string} prefix - String to be prepended to each action.
 * @param {object} types - An object containing actions as keys.
 * @returns {object} An object containing the new actionTypes.
 */
export const mirrorKeysWithPfx = (prefix, types) => {
  const actionTypes = {};
  Object.keys(types).forEach((type) => { actionTypes[type] = `${prefix}/${type}`; });
  return actionTypes;
};

/**
 * Create a curried action creator.
 *
 * Unique use case:
 * When a service is passed, the Fn args are set
 * in the payload.
 *
 * Example
 * fetchDetails = createAction(type.A, 'fetchDetails')([param1, param2])
 * fetchDetails = createAction(type.A, 'fetchDetails')(param1)
 *
 * @param {string} type - Action type.
 * @param {string} service - Name of the service to be called.
 * @returns {Function} An action function that takes payload.
 */
export const createAction = (type, service) => (payload) => ({
  type, service, payload,
});

/**
 * Produce a reducer, with built-in Immer support. It will also
 * enable the support to write object-like reducers, for better performance.
 *
 * @param {any} initialState
 * @param {object} handlers - An object containing action-types as keys and corresponding methods.
 * @returns {object} A traditional redux reducer.
 */
export const reducerFactory = (initialState, handlers) => (
  // eslint-disable-next-line consistent-return
  produce((state = initialState, action) => {
    const handler = handlers[action.type];

    if (handler) {
      // edgeCase: handler can return an entirely override state
      // with its return value.
      // commonFlow: handler method modifies the state
      const newState = handler(state, action);
      if (newState) {
        return newState;
      }
    }
  }, initialState)
);
