import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import {
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import useStyles, { StyledListItem as ListItem } from './styles';

const NavItem = ({
  title,
  link,
  icon: IconComponent,
}) => {
  const classes = useStyles();

  return (
    <Link to={link} className={classes.navLinks}>
      <ListItem
        button
        key={link}
        selected={window.location.href.includes(link) || (window.location.pathname === '/' && title === 'Dashboard')}
      >
        <ListItemIcon className={classes.listIcon}>
          <IconComponent fontSize="small" className={classes.navIcon} />
        </ListItemIcon>
        <ListItemText
          className={classes.listItem}
          primary={title}
        />
      </ListItem>
    </Link>
  );
};

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NavItem;
