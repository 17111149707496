/* eslint-disable no-param-reassign */

import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

// ACTION-TYPES
const pureTypes = {
  SUBSCRIBE_PLAN_REQUEST: null,
  SUBSCRIBE_PLAN_SUCCESS: null,
  SUBSCRIBE_PLAN_FAILURE: null,

  CHANGE_PLAN_REQUEST: null,
  CHANGE_PLAN_SUCCESS: null,
  CHANGE_PLAN_FAILURE: null,

  FETCH_PLAN_DETAILS_REQUEST: null,
  FETCH_PLAN_DETAILS_SUCCESS: null,
  FETCH_PLAN_DETAILS_FAILURE: null,

  FETCH_UPCOMING_INVOICE_REQUEST: null,
  FETCH_UPCOMING_INVOICE_SUCCESS: null,
  FETCH_UPCOMING_INVOICE_FAILURE: null,

  CLEAR_PLAN_DETAILS: null,
  CLEAR_UPCOMING_INVOICE: null,
};

export const types = mirrorKeysWithPfx('checkout', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  subscribePlan: createAction(types.SUBSCRIBE_PLAN_REQUEST, 'subscribePlan'),
  changePlan: createAction(types.CHANGE_PLAN_REQUEST, 'changePlan'),
  fetchPlanDetails: createAction(types.FETCH_PLAN_DETAILS_REQUEST, 'fetchPlanDetails'),
  fetchUpcomingInvoice: createAction(types.FETCH_UPCOMING_INVOICE_REQUEST, 'fetchUpcomingInvoice'),
  clearPlanDetails: createAction(types.CLEAR_PLAN_DETAILS),
  clearUpcomingInvoice: createAction(types.CLEAR_UPCOMING_INVOICE),
};

// REDUCERS
const INITIAL_STATE = {
  planDetails: {},
  upcomingInvoice: {},
};

const checkoutReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_PLAN_DETAILS_SUCCESS]: (state, { payload }) => {
    state.planDetails = payload;
  },
  [types.FETCH_UPCOMING_INVOICE_SUCCESS]: (state, { payload }) => {
    state.upcomingInvoice = payload;
  },
  [types.CLEAR_PLAN_DETAILS]: (state) => {
    state.planDetails = INITIAL_STATE.planDetails;
  },
  [types.CLEAR_UPCOMING_INVOICE]: (state) => {
    state.upcomingInvoice = INITIAL_STATE.upcomingInvoice;
  },
});

export default checkoutReducer;
