import { faker } from '@faker-js/faker';

import {
  random, range, sample, sampleSize,
} from 'lodash';
import { assessmentTemplateList } from './assessment-templates';

const POSSIBLE_TAGS = [
  'OOP',
  'DSA',
  'problem solving',
  'general ability',
  '101',
];

const SHOW_ANSWER_OPTIONS = [
  'never',
  'past_due',
  'correct_or_past_due',
  'finished',
  'closed',
  'attempted',
  'answered',
  'always',
];

const RANDOMIZE_OPTIONS = ['never', 'per_student', 'onreset', 'always'];

const assessmentTemplateCount = assessmentTemplateList.length;

export const scheduledAssessmentList = range(
  1,
  assessmentTemplateCount * 2 + 1,
).map((number) => {
  const assessmentTemplate = assessmentTemplateList[number % assessmentTemplateCount];
  const startDate = faker.date
    .between(
      faker.date.past(2).toISOString(),
      faker.date.future(2).toISOString(),
    )
    .toISOString();
  const endDate = faker.date
    .between(startDate, faker.date.future(2, startDate))
    .toISOString();
  const created = faker.date.past(0.1, startDate).toISOString();

  return {
    id: number,
    created,
    start_date: startDate,
    end_date: endDate,
    assessment_template: number % 20,
    total_enrolled_candidates: random(10, 51),
    is_graded: new Date() > new Date(endDate),
    duration: random(0, 1) ? random(15, 120) : 0,
    mean_score: 0,
    title: `${assessmentTemplate.title} ${
      Math.floor(number / assessmentTemplateCount) + 1
    }`,
    question_pool: assessmentTemplate.question_pool,
    show_answer: sample(SHOW_ANSWER_OPTIONS),
    randomize: sample(RANDOMIZE_OPTIONS),
    assessment_tags: JSON.stringify(
      sampleSize(POSSIBLE_TAGS, random(1, 3, false)),
    ),
    max_attempts: random(0, 3, false),
    // All data on the dashboard should be from only the org of the user,
    // so no need to generate the organization.
    organization: 1,
  };
});

export const scheduledAssessmentCreateResponse = (req) => scheduledAssessmentList.find((
  { id },
) => req.body.assessment_template === id);

export const scheduledAssessmentDeleteResponse = undefined;
export const scheduledAssessmentEditResponse = undefined;
