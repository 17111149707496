import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

import Routes from './core/routes';

import store from './core/store';
import theme from './core/theme';

function App() {
  return (
    <div className="litmustest">
      <header className="litmustest" />
      <CssBaseline />

      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <Routes />
            <ToastContainer />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
