export default [
  {
    key: 'optional',
    value: 'optional',
    label: 'Optional',
  },
  {
    key: 'required',
    value: 'required',
    label: 'Required',
  },
];
