// eslint-disable-next-line
export const getPaymentPlans = () => (
  {
    'count': 3,
    'next': null,
    'previous': null,
    'results': [
      {
        'id': 1,
        'plan_limitation': [
          {
            'id': 1,
            'key': 'system_users',
            'value_type': 'int',
            'display_name': 'Users',
            'created': '2022-08-24T14:26:59.457724Z',
            'modified': '2022-10-31T19:21:28.209036Z',
            'limit_value': '10',
            'payment_plan': 1,
            'limitation': 1,
          },
          {
            'id': 3,
            'key': 'assessments_count',
            'value_type': 'int',
            'display_name': 'Assessments',
            'created': '2022-08-24T14:26:59.486084Z',
            'modified': '2022-09-06T10:50:45.344443Z',
            'limit_value': '30',
            'payment_plan': 1,
            'limitation': 3,
          },
          {
            'id': 4,
            'key': 'question_creation_count',
            'value_type': 'int',
            'display_name': 'Questions',
            'created': '2022-08-24T14:26:59.492100Z',
            'modified': '2022-09-15T22:58:39.296020Z',
            'limit_value': '150',
            'payment_plan': 1,
            'limitation': 4,
          },
          {
            'id': 5,
            'key': 'enrollments_per_assessment',
            'value_type': 'int',
            'display_name': 'Enrollments',
            'created': '2022-08-24T14:26:59.498108Z',
            'modified': '2022-08-24T14:26:59.498108Z',
            'limit_value': '10',
            'payment_plan': 1,
            'limitation': 5,
          },
          {
            'id': 6,
            'key': 'question_types',
            'value_type': 'list',
            'display_name': 'Question Types',
            'created': '2022-08-29T09:35:41.697038Z',
            'modified': '2022-09-15T22:54:47.223864Z',
            'limit_value': '["objective"]',
            'payment_plan': 1,
            'limitation': 2,
          },
          {
            'id': 6,
            'key': 'rubric',
            'valueType': 'int',
            'displayName': 'Rubrics',
            'hidden': false,
            'created': '2023-12-13T13:18:12.716897Z',
            'modified': '2023-12-13T13:18:12.716897Z',
            'limitValue': '10',
            'paymentPlan': 1,
            'limitation': 6,
          },
        ],
        'created': '2022-08-24T14:26:59.447448Z',
        'modified': '2023-03-01T12:17:54.855233Z',
        'key': 'lt_fremium_plan',
        'active': true,
        'stripe_price_id': 'price_1MP6MWJVDwOK48OFFvUVHZsQ',
        'display_name': 'Freemium',
        'price': '0.00',
        'currency': 'USD',
        'interval': 'Unlimited',
        'interval_count': null,
        'description': 'Offers free access to LitmusTest with limited usage',
        'metadata': '{"created": 1673451432, "custom_unit_amount": null, "product": "prod_N9LVGtG9HPrJ79", "type": "one_time", "transform_quantity": null, "unit_amount_decimal": "0", "billing_scheme": "per_unit", "id": "price_1MP6MWJVDwOK48OFFvUVHZsQ", "tiers_mode": null, "lookup_key": null, "currency": "usd", "tax_behavior": "unspecified", "active": true, "object": "price", "livemode": false, "unit_amount": 0, "metadata": {"description": "Offers free access to LitmusTest with limited usage", "key": "lt_fremium_plan", "display_name": "Freemium"}, "nickname": null, "recurring": null}',
      },
      {
        'id': 24,
        'plan_limitation': [
          {
            'id': 1,
            'key': 'system_users',
            'value_type': 'int',
            'display_name': 'Users',
            'created': '2022-08-24T14:26:59.457724Z',
            'modified': '2022-10-31T19:21:28.209036Z',
            'limit_value': '10',
            'payment_plan': 1,
            'limitation': 1,
          },
          {
            'id': 3,
            'key': 'assessments_count',
            'value_type': 'int',
            'display_name': 'Assessments',
            'created': '2022-08-24T14:26:59.486084Z',
            'modified': '2022-09-06T10:50:45.344443Z',
            'limit_value': '30',
            'payment_plan': 1,
            'limitation': 3,
          },
          {
            'id': 4,
            'key': 'question_creation_count',
            'value_type': 'int',
            'display_name': 'Questions',
            'created': '2022-08-24T14:26:59.492100Z',
            'modified': '2022-09-15T22:58:39.296020Z',
            'limit_value': '150',
            'payment_plan': 1,
            'limitation': 4,
          },
          {
            'id': 5,
            'key': 'enrollments_per_assessment',
            'value_type': 'int',
            'display_name': 'Enrollments',
            'created': '2022-08-24T14:26:59.498108Z',
            'modified': '2022-08-24T14:26:59.498108Z',
            'limit_value': '10',
            'payment_plan': 1,
            'limitation': 5,
          },
          {
            'id': 6,
            'key': 'question_types',
            'value_type': 'list',
            'display_name': 'Question Types',
            'created': '2022-08-29T09:35:41.697038Z',
            'modified': '2022-09-15T22:54:47.223864Z',
            'limit_value': '["objective", "coding"]',
            'payment_plan': 1,
            'limitation': 2,
          },
        ],
        'created': '2023-01-18T11:15:27.011629Z',
        'modified': '2023-03-01T12:18:41.371282Z',
        'key': 'lt_enterprise',
        'active': true,
        'stripe_price_id': 'price_1MPn17JVDwOK48OFh49duRgc',
        'display_name': 'Enterprise',
        'price': '1000.00',
        'currency': 'USD',
        'interval': 'month',
        'interval_count': 1,
        'description': 'It offers the most robust set of features and resources to ensure the smooth operation and growth of your website.',
        'metadata': '{"created": 1673615397, "custom_unit_amount": null, "product": "prod_N9LVGtG9HPrJ79", "type": "recurring", "transform_quantity": null, "unit_amount_decimal": "100000", "billing_scheme": "per_unit", "id": "price_1MPn17JVDwOK48OFh49duRgc", "tiers_mode": null, "lookup_key": null, "currency": "usd", "tax_behavior": "unspecified", "active": true, "object": "price", "livemode": false, "unit_amount": 100000, "metadata": {"description": "It offers the most robust set of features and resources to ensure the smooth operation and growth of your website.", "key": "lt_enterprise", "display_name": "Enterprise"}, "nickname": null, "recurring": {"aggregate_usage": null, "trial_period_days": null, "interval_count": 1, "usage_type": "licensed", "interval": "month"}}',
      },
      {
        'id': 25,
        'plan_limitation': [
          {
            'id': 1,
            'key': 'system_users',
            'value_type': 'int',
            'display_name': 'Users',
            'created': '2022-08-24T14:26:59.457724Z',
            'modified': '2022-10-31T19:21:28.209036Z',
            'limit_value': '10',
            'payment_plan': 1,
            'limitation': 1,
          },
          {
            'id': 3,
            'key': 'assessments_count',
            'value_type': 'int',
            'display_name': 'Assessments',
            'created': '2022-08-24T14:26:59.486084Z',
            'modified': '2022-09-06T10:50:45.344443Z',
            'limit_value': '30',
            'payment_plan': 1,
            'limitation': 3,
          },
          {
            'id': 4,
            'key': 'question_creation_count',
            'value_type': 'int',
            'display_name': 'Questions',
            'created': '2022-08-24T14:26:59.492100Z',
            'modified': '2022-09-15T22:58:39.296020Z',
            'limit_value': '150',
            'payment_plan': 1,
            'limitation': 4,
          },
          {
            'id': 5,
            'key': 'enrollments_per_assessment',
            'value_type': 'int',
            'display_name': 'Enrollments',
            'created': '2022-08-24T14:26:59.498108Z',
            'modified': '2022-08-24T14:26:59.498108Z',
            'limit_value': '10',
            'payment_plan': 1,
            'limitation': 5,
          },
          {
            'id': 6,
            'key': 'question_types',
            'value_type': 'list',
            'display_name': 'Question Types',
            'created': '2022-08-29T09:35:41.697038Z',
            'modified': '2022-09-15T22:54:47.223864Z',
            'limit_value': '["objective", "coding"]',
            'payment_plan': 1,
            'limitation': 2,
          },
        ],
        'created': '2023-03-01T12:22:10.228508Z',
        'modified': '2023-03-01T12:22:10.228508Z',
        'key': 'lt_premium',
        'active': true,
        'stripe_price_id': 'price_1MPPItJVDwOK48OFVsY0Msnl',
        'display_name': 'Premium',
        'price': '15.99',
        'currency': 'USD',
        'interval': 'month',
        'interval_count': 1,
        'description': 'It includes a range of advanced features and resources to support and grow your online presence.',
        'metadata': '{"unit_amount": 1599, "id": "price_1MPPItJVDwOK48OFVsY0Msnl", "active": true, "billing_scheme": "per_unit", "created": 1673524243, "transform_quantity": null, "currency": "usd", "object": "price", "custom_unit_amount": null, "metadata": {"description": "It includes a range of advanced features and resources to support and grow your online presence.", "display_name": "Premium", "key": "lt_premium"}, "livemode": false, "type": "recurring", "product": "prod_N9LVGtG9HPrJ79", "unit_amount_decimal": "1599", "tax_behavior": "unspecified", "tiers_mode": null, "nickname": null, "recurring": {"interval": "month", "aggregate_usage": null, "trial_period_days": null, "interval_count": 1, "usage_type": "licensed"}, "lookup_key": null}',
      },
    ],
  }
);

export const getCurrentPaymentPlan = () => ({
  'defaultPaymentMethod': null,
  'nextBillingDate': null,
  'currentPlanDetails': {
    'displayName': 'Fremium',
    'description': 'Mock plan',
    'interval': 'Unlimited',
    'cancelAtEndPeriod': false,
    'stripeSubscriptionId': 'sub_1PEqq2JVDwOK48OFcyn0NzCX',
    'key': 'lt_fremium_plan',
    'planLimitation': [
      {
        'id': 1,
        'key': 'system_users',
        'valueType': 'int',
        'displayName': 'Users',
        'hidden': false,
        'created': '2023-10-11T08:35:35.358386Z',
        'modified': '2023-10-11T08:35:35.358386Z',
        'limitValue': '1',
        'paymentPlan': 1,
        'limitation': 1,
      },
      {
        'id': 2,
        'key': 'question_types',
        'valueType': 'list',
        'displayName': 'Question Types',
        'hidden': false,
        'created': '2023-10-11T08:35:35.363658Z',
        'modified': '2023-10-11T08:35:35.363658Z',
        'limit_value': '["objective", "coding"]',
        'paymentPlan': 1,
        'limitation': 2,
      },
      {
        'id': 3,
        'key': 'assessments_count',
        'valueType': 'int',
        'displayName': 'Assessments',
        'hidden': false,
        'created': '2023-10-11T08:35:35.368345Z',
        'modified': '2023-10-11T08:35:35.368345Z',
        'limitValue': '1',
        'paymentPlan': 1,
        'limitation': 3,
      },
      {
        'id': 4,
        'key': 'question_creation_count',
        'valueType': 'int',
        'displayName': 'Question',
        'hidden': false,
        'created': '2023-10-11T08:35:35.375335Z',
        'modified': '2023-10-11T08:35:35.375335Z',
        'limitValue': '15',
        'paymentPlan': 1,
        'limitation': 4,
      },
      {
        'id': 5,
        'key': 'enrollments_per_assessment',
        'valueType': 'int',
        'displayName': 'Enrollments',
        'hidden': false,
        'created': '2023-10-11T08:35:35.382979Z',
        'modified': '2023-10-11T08:35:35.382979Z',
        'limitValue': '3',
        'paymentPlan': 1,
        'limitation': 5,
      },
      {
        'id': 6,
        'key': 'rubric',
        'valueType': 'int',
        'displayName': 'Rubrics',
        'hidden': false,
        'created': '2023-12-13T13:18:12.716897Z',
        'modified': '2023-12-13T13:18:12.716897Z',
        'limitValue': '10',
        'paymentPlan': 1,
        'limitation': 6,
      },
    ],
    'isFreemium': false,
    'price': 0,
  },
  'cardDetails': {},
  'customerBalance': 0,
});

export const paymentHistoryData = {
  count: 5,
  results: [
    {
      'displayName': 'Premium',
      'date': '2024-01-10T07:00:20Z',
      'status': 'paid',
      'amount': '399.00',
      'pdfLink': 'https://pay.stripe.com/invoice/acct_1MrGAjChePoypoZr/test_YWNjdF8xTXJHQWpDaGVQb3lwb1pyLF9QTGQyOUNTeUhmTFY1NTlHam1YMHl4b3hwM0ltenFILDk1NDE0NDY002003r4he1fx/pdf?s=ap',
      'metaData': {},
      'paymentMethod': {
        'expYear': 2031,
        'expMonth': 2,
        'last4': '1117',
        'cardBrand': 'discover',
        'default': true,
      },
    },
    {
      'displayName': 'Premium',
      'date': '2023-12-10T07:01:10Z',
      'status': 'paid',
      'amount': '399.00',
      'pdfLink': 'https://pay.stripe.com/invoice/acct_1MrGAjChePoypoZr/test_YWNjdF8xTXJHQWpDaGVQb3lwb1pyLF9QQTEyeHlrY21EZGNBNFpQZzZSQThLSWNmMGMzQVBzLDkyNzM2MDky0200x7uMgES5/pdf?s=ap',
      'metaData': {},
      'paymentMethod': {
        'expYear': 2031,
        'expMonth': 2,
        'last4': '1117',
        'cardBrand': 'discover',
        'default': true,
      },
    },
    {
      'displayName': 'Premium',
      'date': '2023-11-23T12:43:15Z',
      'status': 'paid',
      'amount': '139.68',
      'pdfLink': 'https://pay.stripe.com/invoice/acct_1MrGAjChePoypoZr/test_YWNjdF8xTXJHQWpDaGVQb3lwb1pyLF9QM2ppN3hZR2VhSlJsSDVsdTdDb3phOXdWYzBPUzdDLDkxMjg0MTk20200TVR2h5HP/pdf?s=ap',
      'metaData': {},
      'paymentMethod': {
        'expYear': 2031,
        'expMonth': 2,
        'last4': '1117',
        'cardBrand': 'discover',
        'default': true,
      },
    },
    {
      'displayName': 'Pro',
      'date': '2023-11-23T12:43:02Z',
      'status': 'paid',
      'amount': '-139.68',
      'pdfLink': 'https://pay.stripe.com/invoice/acct_1MrGAjChePoypoZr/test_YWNjdF8xTXJHQWpDaGVQb3lwb1pyLF9QM2ppaWtyVlFkNWRRZFhHMHZHWE9UNUJzUzlaeENBLDkxMjg0MTgy0200cQSs5D9b/pdf?s=ap',
      'metaData': {},
      'paymentMethod': {
        'expYear': 2031,
        'expMonth': 2,
        'last4': '1117',
        'cardBrand': 'discover',
        'default': true,
      },
    },
    {
      'displayName': 'Premium',
      'date': '2023-11-20T09:11:03Z',
      'status': 'paid',
      'amount': '165.91',
      'pdfLink': 'https://pay.stripe.com/invoice/acct_1MrGAjChePoypoZr/test_YWNjdF8xTXJHQWpDaGVQb3lwb1pyLF9QMlljZDE4ZlJROXJMelZJQTBDSVJkU2dsOWVGSlBZLDkxMDEyMjY00200TATYRVQi/pdf?s=ap',
      'metaData': {},
      'paymentMethod': {
        'expYear': 2031,
        'expMonth': 2,
        'last4': '1117',
        'cardBrand': 'discover',
        'default': true,
      },
    },
  ],
};

export const paymentMethodsData = {
  'count': 5,
  'next': null,
  'previous': null,
  'results': [
    {
      'id': 41,
      'created': '2023-09-21T10:53:18.629168Z',
      'modified': '2023-09-21T10:53:18.629168Z',
      'paymentMethodId': 'pm_1NskVVChePoypoZrOmfmf2nL',
      'paymentMethodType': 'card',
      'cardBrand': 'discover',
      'country': 'US',
      'expMonth': 2,
      'expYear': 2031,
      'last4': '1117',
      'metadata': '{\'livemode\': false, \'created\': 1695293597, \'billing_details\': {\'email\': null, \'phone\': null, \'address\': {\'line1\': null, \'line2\': null, \'city\': null, \'state\': null, \'postal_code\': null, \'country\': null}, \'name\': null}, \'id\': \'pm_1NskVVChePoypoZrOmfmf2nL\', \'object\': \'payment_method\', \'card\': {\'exp_year\': 2031, \'last4\': \'1117\', \'fingerprint\': \'ntoCNxnafQWNqNoq\', \'wallet\': null, \'funding\': \'credit\', \'networks\': {\'available\': [\'discover\'], \'preferred\': null}, \'brand\': \'discover\', \'checks\': {\'address_line1_check\': null, \'address_postal_code_check\': null, \'cvc_check\': \'pass\'}, \'three_d_secure_usage\': {\'supported\': true}, \'exp_month\': 2, \'country\': \'US\', \'generated_from\': null}, \'type\': \'card\', \'customer\': \'cus_NcvoROKznRrx9W\', \'metadata\': {}}',
      'default': true,
      'customer': 3,
    },
    {
      'id': 40,
      'created': '2023-09-21T10:29:36.770781Z',
      'modified': '2023-09-21T10:29:36.770781Z',
      'paymentMethodId': 'pm_1Nsk8YChePoypoZrJTGd4AnH',
      'paymentMethodType': 'card',
      'cardBrand': 'visa',
      'country': 'US',
      'expMonth': 1,
      'expYear': 2024,
      'last4': '1111',
      'metadata': '{\'livemode\': false, \'created\': 1695292175, \'billing_details\': {\'email\': null, \'phone\': null, \'address\': {\'line1\': null, \'line2\': null, \'city\': null, \'state\': null, \'postal_code\': null, \'country\': null}, \'name\': null}, \'id\': \'pm_1Nsk8YChePoypoZrJTGd4AnH\', \'object\': \'payment_method\', \'card\': {\'exp_year\': 2024, \'last4\': \'1111\', \'fingerprint\': \'PX57vSQWrIZq73Nn\', \'wallet\': null, \'funding\': \'credit\', \'networks\': {\'available\': [\'visa\'], \'preferred\': null}, \'brand\': \'visa\', \'checks\': {\'address_line1_check\': null, \'address_postal_code_check\': null, \'cvc_check\': \'pass\'}, \'three_d_secure_usage\': {\'supported\': true}, \'exp_month\': 1, \'country\': \'US\', \'generated_from\': null}, \'type\': \'card\', \'customer\': \'cus_NcvoROKznRrx9W\', \'metadata\': {}}',
      'default': false,
      'customer': 3,
    },
    {
      'id': 33,
      'created': '2023-05-19T10:47:51.000187Z',
      'modified': '2023-05-19T10:47:51.000187Z',
      'paymentMethodId': 'pm_1N9QqfChePoypoZrs2bCLvrA',
      'paymentMethodType': 'card',
      'cardBrand': 'mastercard',
      'country': 'US',
      'expMonth': 2,
      'expYear': 2052,
      'last4': '4444',
      'metadata': '{\'billing_details\': {\'address\': {\'country\': null, \'postal_code\': null, \'city\': null, \'line2\': null, \'state\': null, \'line1\': null}, \'phone\': null, \'email\': null, \'name\': null}, \'created\': 1684493269, \'object\': \'payment_method\', \'card\': {\'country\': \'US\', \'last4\': \'4444\', \'fingerprint\': \'qTh6ecxB42RnZidP\', \'wallet\': null, \'three_d_secure_usage\': {\'supported\': true}, \'exp_month\': 2, \'checks\': {\'address_postal_code_check\': null, \'address_line1_check\': null, \'cvc_check\': \'pass\'}, \'exp_year\': 2052, \'brand\': \'mastercard\', \'funding\': \'credit\', \'generated_from\': null, \'networks\': {\'preferred\': null, \'available\': [\'mastercard\']}}, \'livemode\': false, \'customer\': \'cus_NcvoROKznRrx9W\', \'id\': \'pm_1N9QqfChePoypoZrs2bCLvrA\', \'type\': \'card\', \'metadata\': {}}',
      'default': false,
      'customer': 3,
    },
    {
      'id': 32,
      'created': '2023-05-19T10:46:58.908268Z',
      'modified': '2023-05-19T10:46:58.908268Z',
      'paymentMethodId': 'pm_1N9QppChePoypoZrqYkWt2uo',
      'paymentMethodType': 'card',
      'cardBrand': 'visa',
      'country': 'US',
      'expMonth': 4,
      'expYear': 2070,
      'last4': '4242',
      'metadata': '{\'billing_details\': {\'address\': {\'country\': null, \'postal_code\': null, \'city\': null, \'line2\': null, \'state\': null, \'line1\': null}, \'phone\': null, \'email\': null, \'name\': null}, \'created\': 1684493217, \'object\': \'payment_method\', \'card\': {\'country\': \'US\', \'last4\': \'4242\', \'fingerprint\': \'x1f3pzKJ5dKnDJCy\', \'wallet\': null, \'three_d_secure_usage\': {\'supported\': true}, \'exp_month\': 4, \'checks\': {\'address_postal_code_check\': null, \'address_line1_check\': null, \'cvc_check\': \'pass\'}, \'exp_year\': 2070, \'brand\': \'visa\', \'funding\': \'credit\', \'generated_from\': null, \'networks\': {\'preferred\': null, \'available\': [\'visa\']}}, \'livemode\': false, \'customer\': \'cus_NcvoROKznRrx9W\', \'id\': \'pm_1N9QppChePoypoZrqYkWt2uo\', \'type\': \'card\', \'metadata\': {}}',
      'default': false,
      'customer': 3,
    },
    {
      'id': 29,
      'created': '2023-05-19T06:37:00.348964Z',
      'modified': '2023-05-19T06:37:00.348964Z',
      'paymentMethodId': 'pm_1N9MvuChePoypoZrHMyrqsxQ',
      'paymentMethodType': 'card',
      'cardBrand': 'visa',
      'country': 'US',
      'expMonth': 4,
      'expyear': 2030,
      'last4': '4242',
      'metadata': '{\'object\': \'payment_method\', \'type\': \'card\', \'customer\': \'cus_NcvoROKznRrx9W\', \'livemode\': false, \'billing_details\': {\'address\': {\'city\': null, \'country\': null, \'line1\': null, \'line2\': null, \'postal_code\': null, \'state\': null}, \'phone\': null, \'email\': null, \'name\': null}, \'id\': \'pm_1N9MvuChePoypoZrHMyrqsxQ\', \'created\': 1684478218, \'card\': {\'brand\': \'visa\', \'checks\': {\'address_line1_check\': null, \'address_postal_code_check\': null, \'cvc_check\': \'pass\'}, \'last4\': \'4242\', \'fingerprint\': \'x1f3pzKJ5dKnDJCy\', \'three_d_secure_usage\': {\'supported\': true}, \'exp_year\': 2030, \'wallet\': null, \'networks\': {\'preferred\': null, \'available\': [\'visa\']}, \'exp_month\': 4, \'funding\': \'credit\', \'generated_from\': null, \'country\': \'US\'}, \'metadata\': {}}',
      'default': false,
      'customer': 3,
    },
  ],
};

export const getUnlimitedAccessPaymentPlan = () => ({
  'defaultPaymentMethod': 'null',
  'nextBillingDate': null,
  'currentPlanDetails': {
    'planLimitation': [
      {
        'id': 35,
        'key': 'question_types',
        'valueType': 'list',
        'displayName': 'Question Types',
        'hidden': false,
        'created': '2023-12-13T13:18:31.361314Z',
        'modified': '2023-12-13T13:18:31.361314Z',
        'limitValue': '["objective", "coding"]',
        'paymentPlan': 6,
        'limitation': 2,
      },
      {
        'id': 34,
        'key': 'rubric',
        'valueType': 'int',
        'displayName': 'Rubrics',
        'hidden': false,
        'created': '2023-12-13T13:18:12.716897Z',
        'modified': '2023-12-13T13:18:12.716897Z',
        'limitValue': '10',
        'paymentPlan': 6,
        'limitation': 6,
      },
    ],
    'cancelAtEndPeriod': false,
    'stripeSubscriptionId': 'null',
    'price': 0,
    'interval': 'Unlimited',
    'isFreemium': true,
    'displayName': 'Litmus Users',
    'key': 'litmus_users',
  },
  'cardDetails': {
    'cardBrand': 'mastercard',
    'last4': '1234',
    'expMonth': 12,
    'expYear': 2026,
  },
  'customerBalance': 0,
});
