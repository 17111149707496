import { createSelector } from 'reselect';

import { selectIsLoading, selectStatus } from '../uiEffects/selectors';
import { selectHasError, selectErrorStatus } from '../errorTracking/selectors';
import { types } from './ducks';

export const selectUserDetails = (state) => state.auth.userInfo;

export const selectUserRoles = (state) => state.auth.userRoles;

/**
 * Returns an Object with user access list.
 * The keys are created using ROUTES from 'routes/constants'
 * @returns {Object}
 */
export const selectUserRouteAccess = (state) => state.auth.userRouteAccess;

export const selectUserName = createSelector(selectUserDetails, (userInfo) => {
  let username = '';

  if (userInfo && userInfo.firstName && userInfo.lastName) {
    username = `${userInfo.firstName} ${userInfo.lastName}`;
  } else if (userInfo && userInfo.username) {
    username = userInfo.username;
  }

  return username;
});

export const selectIsLoginLoading = (state) => (
  selectIsLoading(state, [types.LOGIN_REQUEST])
);

export const selectIsLoginFailed = (state) => (
  selectHasError(state, [types.LOGIN_REQUEST])
);

export const selectLoginFailedErrors = (state) => (
  selectErrorStatus(state, types.LOGIN_REQUEST)
);

export const selectIsLogoutLoading = (state) => (
  selectIsLoading(state, [types.LOGOUT_REQUEST])
);

export const selectIsSignupLoading = (state) => (
  selectIsLoading(state, [types.SIGNUP_REQUEST])
);

export const selectSignupStatus = (state) => (
  selectStatus(state, types.SIGNUP_REQUEST)
);

export const selectIsSignupFailed = (state) => (
  selectHasError(state, [types.SIGNUP_REQUEST])
);

export const selectSignupFailedErrors = (state) => (
  selectErrorStatus(state, types.SIGNUP_REQUEST)
);

export const selectIsChangePasswordLoading = (state) => (
  selectIsLoading(state, [types.CHANGE_PASSWORD_REQUEST])
);

export const selectChangePasswordStatus = (state) => (
  selectStatus(state, types.CHANGE_PASSWORD_REQUEST)
);

export const selectChangePasswordFailedErrors = (state) => (
  selectErrorStatus(state, types.CHANGE_PASSWORD_REQUEST)
);

export const selectIsForgotPasswordLoading = (state) => (
  selectIsLoading(state, [types.FORGOT_PASSWORD_REQUEST])
);

export const selectForgotPasswordStatus = (state) => (
  selectStatus(state, types.FORGOT_PASSWORD_REQUEST)
);

export const selectForgotPasswordFailedErrors = (state) => (
  selectErrorStatus(state, types.FORGOT_PASSWORD_REQUEST)
);

export const selectIsResetPasswordLoading = (state) => (
  selectIsLoading(state, [types.RESET_PASSWORD_REQUEST])
);

export const selectResetPasswordStatus = (state) => (
  selectStatus(state, types.RESET_PASSWORD_REQUEST)
);

export const selectResetPasswordFailedErrors = (state) => (
  selectErrorStatus(state, types.RESET_PASSWORD_REQUEST)
);

export const selectVerifyAccountFailedErrors = (state) => (
  selectErrorStatus(state, types.VERIFY_ACCOUNT_REQUEST)
);

export const selectIsVerifyAccountLoading = (state) => (
  selectIsLoading(state, [types.VERIFY_ACCOUNT_REQUEST])
);

export const selectVerifyAccountStatus = (state) => (
  selectStatus(state, types.VERIFY_ACCOUNT_REQUEST)
);

export const selectIsUpdateUserDetailsLoading = (state) => (
  selectIsLoading(state, [types.UPDATE_USER_DETAILS_REQUEST])
);

export const selectUpdateUserDetailsStatus = (state) => (
  selectStatus(state, types.UPDATE_USER_DETAILS_REQUEST)
);

export const selectUpdateUserDetailsFailedErrors = (state) => (
  selectErrorStatus(state, types.UPDATE_USER_DETAILS_REQUEST)
);
