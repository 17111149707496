import { createSelector } from 'reselect';

import { getActionKey } from '../utils';

const selectUIState = (state) => state.ui;

const selectAction = (_, actionType) => actionType;
const selectActions = (_, actionTypes) => actionTypes;

/**
 * Check if a certain action has an active fetch request.
 *
 * @param {Object} state -
 * @param {Array} actions - An array of actionTypes
 * @returns {Boolean}
 */
export const selectIsLoading = createSelector(
  [selectUIState, selectActions],
  (uiState, actionTypes) => (
    actionTypes.every((actionType) => uiState[getActionKey(actionType)] === 'REQUEST')
  ),
);

/**
 * Get the status of an action type's fetch request.
 *
 * @param {Object} state -
 * @param {string} action - An actionType string
 * @returns {string}
 */
export const selectStatus = createSelector(
  [selectUIState, selectAction],
  (uiState, actionType) => (
    uiState[getActionKey(actionType)]
  ),
);
