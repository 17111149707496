import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';

const pureTypes = {
  CREATE_RUBRIC_TEMPLATE_REQUEST: null,
  CREATE_RUBRIC_TEMPLATE_SUCCESS: null,
  CREATE_RUBRIC_TEMPLATE_FAILURE: null,
  CLEAR_NEWLY_CREATED_RUBRIC_TEMPLATE_ID: null,
};

export const types = mirrorKeysWithPfx('rubricTemplates', pureTypes);

export const actions = {
  createRubricTemplate: createAction(
    types.CREATE_RUBRIC_TEMPLATE_REQUEST,
    'createRubricTemplate',
  ),
  clearNewlyCreatedRubricTemplateId: createAction(
    types.CLEAR_NEWLY_CREATED_RUBRIC_TEMPLATE_ID,
  ),
};

const INITIAL_STATE = {
  newlyCreatedRubricTemplateId: null,
};

const rubricTemplatesCreateReducer = reducerFactory(INITIAL_STATE, {
  [types.CREATE_RUBRIC_TEMPLATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    newlyCreatedRubricTemplateId: payload.id,
  }),
  [types.CLEAR_NEWLY_CREATED_RUBRIC_TEMPLATE_ID]: () => INITIAL_STATE,
});

export default rubricTemplatesCreateReducer;
