import {
  takeLeading,
} from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { types } from './ducks';

export default function* editTemplateSaga() {
  yield takeLeading(types.EDIT_TEMPLATE_REQUEST, fetchApiSaga);
}
