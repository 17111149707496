/* eslint-disable no-param-reassign */

import {
  createAction,
  mirrorKeysWithPfx,
  reducerFactory,
} from '@utils';
import { getLocalDateTime, parseISODateTime } from '@utils/datetime';
import { RUBRIC_EVALUATION_IN_PROCESS } from 'core/constants/rubricStatus';

// ACTION-TYPES
const pureTypes = {
  FETCH_REPORT_DETAILS_REQUEST: null,
  FETCH_REPORT_DETAILS_SUCCESS: null,
  FETCH_REPORT_DETAILS_FAILURE: null,
  CLEAR_REPORTS_DETAILS: null,

  FETCH_GRADED_CANDIDATES_REQUEST: null,
  FETCH_GRADED_CANDIDATES_SUCCESS: null,
  FETCH_GRADED_CANDIDATES_FAILURE: null,
  CLEAR_CANDIDATES: null,

  FETCH_GRADING_STATUS_REQUEST: null,
  FETCH_GRADING_STATUS_SUCCESS: null,
  FETCH_GRADING_STATUS_FAILURE: null,
  CLEAR_GRADING_STATUS: null,

  DOWNLOAD_REPORT_REQUEST: null,
  DOWNLOAD_REPORT_SUCCESS: null,
  DOWNLOAD_REPORT_FAILURE: null,

  RESET_ATTEMPT_REQUEST: null,
  RESET_ATTEMPT_SUCCESS: null,
  RESET_ATTEMPT_FAILURE: null,

  RECALCULATE_SCORES_REQUEST: null,
  RECALCULATE_SCORES_SUCCESS: null,
  RECALCULATE_SCORES_FAILURE: null,

  CLEAR_IS_RESET: null,

  CLEAR_RESET_ATTEMPT: null,

  START_GRADING_REQUEST: null,
  START_GRADING_SUCCESS: null,
  START_GRADING_FAILURE: null,
};
export const types = mirrorKeysWithPfx('report-detail', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchReportDetails: createAction(types.FETCH_REPORT_DETAILS_REQUEST, 'fetchReportDetails'),
  clearReportDetails: createAction(types.CLEAR_REPORTS_DETAILS),
  fetchGradedCandidates: createAction(types.FETCH_GRADED_CANDIDATES_REQUEST, 'fetchGradedCandidatesReport'),
  clearCandidates: createAction(types.CLEAR_CANDIDATES),
  downloadReport: createAction(types.DOWNLOAD_REPORT_REQUEST, 'downloadReport'),
  resetAttempt: createAction(types.RESET_ATTEMPT_REQUEST, 'resetAttempt'),
  clearIsReset: createAction(types.CLEAR_IS_RESET),
  recalculateScores: createAction(types.RECALCULATE_SCORES_REQUEST, 'recalculateScores'),
  clearResetAttempt: createAction(types.CLEAR_RESET_ATTEMPT),
  startGrading: createAction(types.START_GRADING_REQUEST, 'startGrading'),
};

// REDUCER
const INITIAL_STATE = {
  summary: {
    displayName: '',
    assessmentTime: 0,
    assessmentTags: [],
    isAssessmentExpired: false,
  },
  candidates: [],
  count: 0,
  gradingStatus: null,
  isRubricBeingEvaluated: false,
};

const detailsReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_REPORT_DETAILS_SUCCESS]: (state, { payload }) => {
    state.summary = payload;
    const isAssessmentExpired = parseISODateTime(payload.endDate) < getLocalDateTime();
    state.summary.isAssessmentExpired = isAssessmentExpired;
  },
  [types.CLEAR_REPORTS_DETAILS]: (state) => {
    state.summary = INITIAL_STATE.summary;
  },
  [types.FETCH_GRADED_CANDIDATES_SUCCESS]: (state, { payload }) => {
    const attemptsEvaluating = payload.results.filter(
      (candidate) => candidate.isRubricEvaluated === RUBRIC_EVALUATION_IN_PROCESS,
    );
    state.isRubricBeingEvaluated = attemptsEvaluating.length > 0;
    state.count = payload.count;
    state.candidates = payload.results;
  },
  [types.CLEAR_CANDIDATES]: (state) => {
    state.candidates = INITIAL_STATE.candidates;
    state.count = 0;
  },
});

export default detailsReducer;
