import { faker } from '@faker-js/faker';

import { random, range, sampleSize } from 'lodash';
import { scheduledAssessmentList } from './scheduled-assessments';

const users = range(1, 201).map(() => ({
  email: faker.unique(faker.internet.email),
  username: faker.unique(faker.internet.userName),
  is_email_sent: !!random(0, 1),
  first_name: faker.name.findName(),
  last_name: faker.name.lastName(),
}));

const enrollmentsMap = {};
scheduledAssessmentList.forEach((scheduledAssessment) => {
  enrollmentsMap[scheduledAssessment.id] = sampleSize(
    users,
    scheduledAssessment.total_enrolled_candidates,
  );
});

export { enrollmentsMap };

export const unenrollResponse = {
  message:
    'Unenrol task started successfully for scheduled assessment: <id> and users: [<email>, <email>, ...]',
};

export const enrollResponse = {
  message: 'Enrolment task started successfully for scheduled assessment: <id>',
};
