import { ROLES, ROLES_LIST, ALLOWED_ROLES } from 'containers/team/roles';

const ROUTES = Object.freeze({
  login: '/login',
  signup: '/signup',
  resetPassword: '/litmustest_organization/token_password_reset/:token',
  verifyAccount: '/litmustest_organization/token_account_verify/:token',
  resetDefaultPassword: '/reset-default-password',
  dashboard: '/dashboard',
  templates: '/templates',
  templateDetails: '/templates/:id(\\d+)?',
  editTemplate: '/templates/:id(\\d+)?/edit',
  createTemplate: '/create-template',
  scheduleAssessment: '/schedule-assessments',
  assessments: '/assessments',
  assessmentDetails: '/assessments/:id(\\d+)?',
  reports: '/reports',
  assessmentReport: '/reports/:id(\\d+)?',
  rubricEvaluationBase: '/report/rubric',
  rubricEvaluation: '/report/rubric/:id(\\d+)?/',
  candidateReportBase: '/candidate-report',
  candidateReport: '/candidate-report/:id(\\d+)?',
  questions: '/questions',
  questionDetails: '/questions/:id(\\d+)?',
  questionCreate: '/questions/create',
  questionEditBase: '/questions/edit',
  questionEdit: '/questions/edit/:id(\\d+)?',
  team: '/team',
  profileRoot: '/profile',
  profile: '/profile/:tab?',
  billingRoot: '/billing',
  billing: '/billing/:tab?',
  checkout: '/checkout',
  rubricTemplates: '/rubric-templates',
  rubricTemplateCreate: '/rubric-templates/create',
  rubricTemplateDetails: '/rubric-templates/:id(\\d+)?',
  rubricTemplateEdit: '/rubric-templates/edit/:id(\\d+)?',
  notFound: '/404',
  notAccessible: '/403',
});

// access -> null represents a public link
const ROUTE_DETAILS = Object.freeze({
  [ROUTES.login]: { access: null },
  [ROUTES.signup]: { access: null },
  [ROUTES.notFound]: { access: null },
  [ROUTES.resetDefaultPassword]: { access: null },
  [ROUTES.resetPassword]: { access: null },
  [ROUTES.verifyAccount]: { access: null },
  [ROUTES.dashboard]: {
    access: ROLES_LIST,
  },
  [ROUTES.templates]: {
    access: ALLOWED_ROLES([
      ROLES.HiringManager,
      ROLES.Author,
    ]),
  },
  [ROUTES.templateDetails]: {
    access: ALLOWED_ROLES([
      ROLES.HiringManager,
      ROLES.Author,
    ]),
  },
  [ROUTES.editTemplate]: {
    access: ALLOWED_ROLES([
      ROLES.HiringManager,
      ROLES.Author,
    ]),
  },
  [ROUTES.createTemplate]: {
    access: ALLOWED_ROLES([
      ROLES.HiringManager,
      ROLES.Author,
    ]),
  },
  [ROUTES.scheduleAssessment]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.candidateReport]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.assessments]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.assessmentDetails]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.reports]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.assessmentReport]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.rubricEvaluation]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.questions]: {
    access: ROLES_LIST,
  },
  [ROUTES.questionDetails]: {
    access: ROLES_LIST,
  },
  [ROUTES.questionEdit]: {
    access: ROLES_LIST,
  },
  [ROUTES.questionCreate]: {
    access: ROLES_LIST,
  },
  [ROUTES.profile]: {
    access: ROLES_LIST,
  },
  [ROUTES.team]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.billingRoot]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.billing]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.checkout]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.rubricTemplates]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.rubricTemplateCreate]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.rubricTemplateDetails]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
  [ROUTES.rubricTemplateEdit]: {
    access: ALLOWED_ROLES([ROLES.HiringManager]),
  },
});

export { ROUTE_DETAILS, ROUTES };
