import APIClient from '@utils/axios';
import parseQueryParams from '@utils/query-params';

import { MIN_PAGINATION_DATA, INITIAL_PAGE_NUMBER } from 'core/constants/datatables';

import API_URLS from './urls';

const headers = {
  'Content-Type': 'application/json',
};

export const fetchEnrollments = (data) => {
  const parsedParams = parseQueryParams(window.location.search);

  let url = `${API_URLS.ENROLLMENTS}${data.id}/`;

  url = `${url}?page=${parsedParams.page ?? INITIAL_PAGE_NUMBER}`;

  url = `${url}&page_size=${parsedParams.pageSize ?? MIN_PAGINATION_DATA}`;

  url = parsedParams.searchText
    ? `${url}&search=${parsedParams.searchText}`
    : url;

  url = parsedParams.sorting
    ? `${url}&ordering=${parsedParams.sorting}`
    : url;

  url = parsedParams.filtering
    ? `${url}&${parsedParams.filtering}`
    : url;

  return APIClient.get(url);
};

export const enrollCandidates = ({
  assessmentId,
  candidateEmails,
  enrollmentIds,
  startEmailBlock,
  endEmailBlock,
  subject,
  isTimed,
  showDuration,
}) => {
  const accounts = [];
  candidateEmails && Object.values(candidateEmails).forEach((email) => {
    accounts.push({
      email,
    });
  });
  enrollmentIds && Object.values(enrollmentIds).forEach((id) => {
    accounts.push({
      id,
    });
  });
  const data = {
    accounts,
    startEmailBlock,
    endEmailBlock,
    subject,
    isTimed,
    showDuration,
  };
  return APIClient.post(`${API_URLS.ENROLLMENTS}${assessmentId}/enrol/`, data, { headers });
};

export const unenrollCandidates = ({
  ids,
  assessmentId,
  sendEmail,
  isEmail,
}) => {
  const payload = {
    ids,
    sendEmail,
    isEmail,
  };
  return APIClient.post(`${API_URLS.ENROLLMENTS}${assessmentId}/unenrol/`, payload, { headers });
};
