const options = [
  {
    label: 'Good',
    name: 'Good',
    points: '5',
    explanation: 'More than 5 occurences',
  },
  {
    label: 'Average',
    name: 'Average',
    points: '3',
    explanation: 'Less than 5 occurences',
  },
  {
    label: 'Bad',
    name: 'Bad',
    points: '0',
    explanation: 'No occurence',
  },
];

export const automatedCriteriasOptions = [
  {
    key: 'indentation',
    value: 'indentation',
    label: 'Indentation',
  },
  {
    key: 'unnecessary_code',
    value: 'unnecessary_code',
    label: 'Unnecessary Code',
  },
  {
    key: 'file_closed_or_not',
    value: 'file_closed_or_not',
    label: 'File closed or not',
  },
  {
    key: 'standard_library_usage',
    value: 'standard_library_usage',
    label: 'Standard Library Usage',
  },
  {
    key: 'commenting',
    value: 'commenting',
    label: 'Commenting',
  },
  {
    key: 'readability',
    value: 'readability',
    label: 'Readability',
  },
  {
    key: 'naming_convention',
    value: 'naming_convention',
    label: 'Naming Convention',
  },
];

const automatedCriterias = {
  indentation: {
    name: 'indentation',
    label: 'Indentation',
    prompt: 'Indentation',
    options,
  },
  unnecessary_code: {
    name: 'unnecessary_code',
    label: 'Unnecessary Code',
    prompt: 'Unnecessary Code',
    options,
  },
  file_closed_or_not: {
    name: 'file_closed_or_not',
    label: 'File closed or not',
    prompt: 'File closed or not',
    options,
  },
  standard_library_usage: {
    name: 'standard_library_usage',
    label: 'Standard Library Usage',
    prompt: 'Standard Library Usage',
    options,
  },
  commenting: {
    name: 'commenting',
    label: 'Commenting',
    prompt: 'Commenting',
    options,
  },
  readability: {
    name: 'readability',
    label: 'Readability',
    prompt: 'Readability',
    options,
  },
  naming_convention: {
    name: 'naming_convention',
    label: 'Naming Convention',
    prompt: 'Naming Convention',
    options,
  },
};
export default automatedCriterias;
