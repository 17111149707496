import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 210;

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    borderWidth: '6px 0',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    background: theme.palette.primary.main,
  },
  mainContent: {
    padding: theme.spacing(3),
    flexGrow: 1,
    background: theme.palette.common.white,
    borderRadius: '23px 0 0 23px',
    maxWidth: 'calc(100% - 210px)',
  },
  logo: {
    height: 26,
    margin: theme.spacing('auto', 'auto', 2),
    marginTop: 28,
    display: 'block',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: '100%',
    margin: '6px 0',
    background: theme.palette.primary.main,
    borderRight: 0,
    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
      backgroundColor: theme.palette.scrollBar.track,
    },
    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: theme.palette.scrollBar.secondary,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.scrollBar.hover,
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: theme.palette.scrollBar.primary,
    },
  },
  subheader: {
    color: theme.palette.light.main,
  },
  nav: {
    padding: theme.spacing(5, 0),
  },
  navDivider: {
    backgroundColor: 'rgb(255, 255, 255, 0.5)',
    marginTop: 3,
    marginBottom: 3,
  },
  circularProgressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));
