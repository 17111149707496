import APIClient from '@utils/axios';
import { constructFormData } from '@utils/common';
import changeObjectCase from 'change-object-case';
import API_URLS from './urls';

const changeCaseOptions = {
  throwOnConflict: true,
  recursive: true,
  arrayRecursive: true,
};

const headers = {
  'Content-Type': 'x-www-form-urlencoded',
};

export const login = (payload) => (
  APIClient.post(API_URLS.ACCESS_TOKEN, constructFormData(payload), { headers })
);

export const signup = (payload) => (
  APIClient.post(
    API_URLS.SIGNUP,
    constructFormData(
      changeObjectCase.snakeKeys(
        payload,
        changeCaseOptions,
      ),
    ),
    { headers },
  )
);

export const accessToken = (payload) => (
  APIClient.post(API_URLS.ACCESS_TOKEN, constructFormData(payload), { headers })
);

export const logout = () => (
  APIClient.get(API_URLS.LOGOUT)
);

export const changePassword = (payload) => (
  APIClient.post(
    API_URLS.CHANGE_PASSWORD,
    constructFormData(
      changeObjectCase.snakeKeys(
        payload,
        changeCaseOptions,
      ),
    ),
    { headers },
  )
);

export const forgotPassword = (payload) => (
  APIClient.post(API_URLS.FORGOT_PASSWORD, constructFormData(payload), { headers })
);

export const resetPassword = (payload) => {
  const { defaultPasswordReset, token, ...values } = payload;

  const url = defaultPasswordReset
    ? API_URLS.RESET_DEFAULT_PASSWORD
    : `${API_URLS.RESET_PASSWORD}${token}/`;

  return (
    APIClient.post(
      url,
      constructFormData(
        changeObjectCase.snakeKeys(
          values,
          changeCaseOptions,
        ),
      ),
      { headers },
    )
  );
};

export const verifyAccount = (payload) => {
  const { token } = payload;

  const url = `${API_URLS.VERIFY_ACCOUNT}${token}/`;

  return (
    APIClient.post(
      url,
      constructFormData(
        changeObjectCase.snakeKeys(
          changeCaseOptions,
        ),
      ),
      { headers },
    )
  );
};

export const fetchUserDetails = () => (
  APIClient(`${API_URLS.ORG_MEMBERS}user_details/`)
);

export const updateUserDetails = (payload) => (
  APIClient.patch(
    `${API_URLS.ORG_MEMBERS}update_profile/`,
    constructFormData(
      changeObjectCase.snakeKeys(
        payload,
        changeCaseOptions,
      ),
    ),
    { headers },
  )
);
