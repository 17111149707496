import APIClient from '@utils/axios';

import API_URLS from './urls';

const headers = {
  'Content-Type': 'application/json',
};

// eslint-disable-next-line
export const fetchPlanDetails = (planId) => (
  APIClient.get(`${API_URLS.PAYMENT_PLANS}${planId}/`)
);

// eslint-disable-next-line
export const subscribePlan = ({ plan, paymentMethodId, saveCard }) => {
  const data = {
    paymentPlanId: plan,
    paymentMethodId,
    saveCard,
  };
  return APIClient.post(API_URLS.SUBSCRIBE_PLAN, data, { headers });
};

export const fetchUpcomingInvoice = (planId) => (
  APIClient.get(`${API_URLS.UPCOMING_INVOICE}?payment_plan_id=${planId}`)
);

export const changePlan = ({ plan, paymentMethodId, saveCard }) => {
  const data = {
    paymentPlanId: plan,
    paymentMethodId,
    saveCard,
  };
  return APIClient.post(API_URLS.CHANGE_PLAN, data, { headers });
};
