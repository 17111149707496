import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { fetchCurrentPlan } from 'core/services/billing';

import { actions, types } from './ducks';

export function* currentPlanSaga() {
  let response;
  try {
    response = yield call(fetchCurrentPlan);
  } catch (error) {
    const errorMsg = error?.response?.data?.detail || error?.response?.data;
    yield put(actions.fetchCurrentPlanFailure(errorMsg));
  }

  localStorage.setItem('currentPlan', JSON.stringify(response.data));
  yield put(actions.fetchCurrentPlanSuccess(response.data));
}

function* RefreshAndFetchApiSaga(action) {
  const response = yield call(fetchApiSaga, action);
  if (response.success) {
    yield put(actions.fetchCurrentPlan());
  }
}

export default function* billingSaga() {
  yield takeLatest(types.FETCH_PAYMENT_PLANS_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_PAYMENT_METHODS_REQUEST, fetchApiSaga);
  yield takeLatest(types.ADD_PAYMENT_METHOD_REQUEST, fetchApiSaga);
  yield takeLatest(types.DELETE_PAYMENT_METHOD_REQUEST, fetchApiSaga);
  yield takeLatest(types.SET_DEFAULT_PAYMENT_METHOD_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_CURRENT_PLAN_REQUEST, currentPlanSaga);
  yield takeLatest(types.FETCH_CURRENT_PLAN_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_PAYMENT_HISTORY_REQUEST, fetchApiSaga);
  yield takeLatest(types.CANCEL_PLAN_REQUEST, RefreshAndFetchApiSaga);
  yield takeLatest(types.REACTIVATE_PLAN_REQUEST, RefreshAndFetchApiSaga);
}
