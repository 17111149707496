import { createAction, mirrorKeysWithPfx, reducerFactory } from '@utils';

const pureTypes = {
  CREATE_QUESTION_REQUEST: null,
  CREATE_QUESTION_SUCCESS: null,
  CREATE_QUESTION_FAILURE: null,
  CLEAR_STATE: null,
};

export const types = mirrorKeysWithPfx('questions/create', pureTypes);

export const actions = {
  createQuestion: createAction(
    types.CREATE_QUESTION_REQUEST,
    'createQuestion',
  ),
  clearState: createAction(
    types.CLEAR_STATE,
  ),
};

const INITIAL_STATE = {
  newlyCreatedQuestionId: null,
};

const questionCreateReducer = reducerFactory(INITIAL_STATE, {
  [types.CLEAR_STATE]: () => INITIAL_STATE,
  [types.CREATE_QUESTION_SUCCESS]: (state, action) => ({
    ...state,
    newlyCreatedQuestionId: action.payload.id,
  }),
});

export default questionCreateReducer;
