export default {
  Objective: 'Objective',
  Coding: 'Coding',
};

export const questionSubTypes = {
  Coding: {
    None: 'None',
    DesignProblem: 'design_problem',
  },
  Objective: {
    None: 'None',
    Randomized: 'randomized',
  },
};

export const questionSubTypeLabels = {
  Coding: {
    None: 'None',
    DesignProblem: 'Design Problem',
  },
  Objective: {
    None: 'MCQ',
    Randomized: 'Randomized MCQ',
  },
};
