import { faker } from '@faker-js/faker';
import { random, range, sampleSize } from 'lodash';

import { addAssessmentTemplate, questionsList } from './questions';

const POSSIBLE_TAGS = [
  'OOP',
  'DSA',
  'problem solving',
  'general ability',
  '101',
];

function getRandomAssessmentTemplate(id) {
  const questions = sampleSize(questionsList, random(1, 11));
  const title = faker.lorem.words(random(2, 10));
  questions.forEach((question) => addAssessmentTemplate(question.id, {
    id,
    title,
  }));
  return {
    id,
    title,
    question_pool: questions,
    questions_count: questions.length,
    created: faker.date.past(2).toISOString(),
    assessment_tags: JSON.stringify(sampleSize(POSSIBLE_TAGS, random(1, 3))),
  };
}

export const assessmentTemplateList = range(1, 21)
  .map((number) => getRandomAssessmentTemplate(number))
  .sort(({ created: aCreated }, { created: bCreated }) => new Date(bCreated) - new Date(aCreated));

export const assessmentTemplateCreateResponse = () => getRandomAssessmentTemplate(1);

export const assessmentTemplateUpdateResponse = undefined;
