import { createTheme } from '@material-ui/core/styles';

const primaryDark = '#1B1F22';
const primaryLight = '#FFFBFC';

const muiTheme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
      },
    },
    MuiTypography: {
      colorPrimary: { color: primaryDark },
      colorSecondary: { color: primaryLight },
    },
    MuiList: {
      root: {
        maxHeight: '280px',
      },
    },
    MuiSvgIcon: {
      colorPrimary: { color: '#6b6f73' },
    },
    MUIDataTableToolbar: {
      filterPaper: {
        width: '450px',
      },
      actions: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
      },
    },
    MUIDataTableSelectCell: {
      expandDisabled: {
        visibility: 'hidden',
      },
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: [
      'Merriweather Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'Noto Sans',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji',
    ].join(','),
    fontWeight: 300,
    caption: { color: '#6b6f73' },
    h5: { fontSize: '1.17rem' },
    h6: { fontSize: '1.07rem' },
    body1: {
      fontWeight: 300,
      fontSize: '0.84rem',
    },
    body2: { fontWeight: 300, color: '#6b6f73' },
  },
  palette: {
    primary: { main: 'rgb(81, 92, 143)', bright: 'rgb(105, 117, 171)', 'light': '#DDDEE7' },
    secondary: { main: '#E54F6D' },
    tertiary: { main: '#2CA58D' },
    dark: { primary: primaryDark, secondary: '#6b6f73' },
    light: { main: primaryLight, secondary: '#ffffffcc' },
    error: { main: 'rgb(218, 125, 126)' },
    success: { main: 'rgb(128, 179, 138)' },
    scrollBar: {
      primary: '#6975AB', secondary: '#94A2DE', hover: '#8097FB', track: '#F5F5F5',
    },
  },
});

export default muiTheme;
