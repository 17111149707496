import {
  createAction,
  mirrorKeysWithPfx,
} from '@utils';

// ACTION-TYPES
const pureTypes = {
  EDIT_TEMPLATE_REQUEST: null,
  EDIT_TEMPLATE_SUCCESS: null,
  EDIT_TEMPLATE_FAILURE: null,
};

export const types = mirrorKeysWithPfx('templates', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  editTemplate: createAction(
    types.EDIT_TEMPLATE_REQUEST,
    'editTemplate',
  ),
};
