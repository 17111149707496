import { takeLatest } from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { types } from './ducks';

export default function* dashboardSaga() {
  yield takeLatest(types.FETCH_QUESTIONS_STATS_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_LT_STATS_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_ORG_STATS_REQUEST, fetchApiSaga);
}
