/* eslint-disable no-param-reassign */
import {
  call, put, takeLeading, takeLatest,
} from 'redux-saga/effects';

import {
  login as loginService,
  signup as signupUserService,
  fetchUserDetails as userDetailsService,
  updateUserDetails as updateUserDetailsService,
} from '@services';
import { fetchApiSaga } from '@utils';
import { updateLocation } from '@utils/history';
import createUserRouteAccess from '@utils/user-route-access';

import { currentPlanSaga } from '../../billing/sagas';
import { actions, types } from './ducks';

function* logoutSaga(action) {
  const serviceResponse = yield call(fetchApiSaga, action);

  if (!serviceResponse.success) {
    return;
  }

  yield call(updateLocation, '/login');
  yield put(actions.logoutSuccess());
}

function* updateUserDetailsSaga(action) {
  try {
    const params = Array.isArray(action.payload) ? action.payload : [action.payload];
    const updateUserDetailsResponse = yield call(updateUserDetailsService, ...params);
    if (updateUserDetailsResponse.status !== 200) {
      yield put(actions.updateUserDetailsFailure(updateUserDetailsResponse.data));
    } else {
      yield put(actions.updateUserDetailsSuccess(updateUserDetailsResponse.data));
    }
  } catch (err) {
    const errorMsg = err?.response?.data?.detail || err?.response?.data;
    yield put(actions.updateUserDetailsFailure(errorMsg));
  }
}

function* userDetailsSaga(action) {
  try {
    const userDetailsResponse = yield call(userDetailsService, action);
    if (userDetailsResponse.status !== 200) {
      yield put(actions.fetchUserDetailsFailure(userDetailsResponse.data));
    } else {
      const userRouteAccess = createUserRouteAccess(userDetailsResponse.data?.userRoles);
      localStorage.setItem(
        'userRouteAccess',
        JSON.stringify(userRouteAccess),
      );
      localStorage.setItem(
        'userInfo',
        JSON.stringify(userDetailsResponse.data),
      );

      if (!userDetailsResponse.data.lastLogin) {
        yield put(actions.signupSuccess());
      }

      yield put(actions.fetchUserDetailsSuccess({
        ...userDetailsResponse.data,
        userRouteAccess,
      }));
    }
  } catch (err) {
    const errorMsg = err?.response?.data?.detail || err?.response?.data;
    yield put(actions.fetchUserDetailsFailure(errorMsg));
  }
}

function* loginFlowSaga(action) {
  try {
    const loginResponse = yield call(loginService, action.payload);

    if (!loginResponse.data.accessToken) {
      yield put(actions.loginFailure(loginResponse.data.errorDescription));
    } else {
      localStorage.setItem('token', loginResponse.data.accessToken);
      localStorage.setItem('user_roles', loginResponse.data.userRoles);
      localStorage.setItem('refreshToken', loginResponse.data.refreshToken);
      localStorage.setItem('paymentPlan', JSON.stringify(loginResponse.data.paymentPlanLimit));

      yield call(userDetailsSaga);
      yield call(currentPlanSaga);
      yield put(actions.loginSuccess(loginResponse.data.userRoles));
    }
  } catch (err) {
    const errorMsg = err?.response?.data?.detail || err?.response?.data;
    yield put(actions.loginFailure(errorMsg));
  }
}

function* signupUserSaga(action) {
  const { planId, ...payload } = action.payload;
  try {
    const signupUserResponse = yield call(signupUserService, payload);
    if (signupUserResponse.status !== 201) {
      yield put(actions.signupFailure(signupUserResponse.data));
    } else {
      yield put(actions.signupSuccess());
    }
  } catch (err) {
    const errorMsg = err?.response?.data?.detail || err?.response?.data;
    yield put(actions.signupFailure(errorMsg));
  }
}

export default function* loginSaga() {
  yield takeLeading(types.LOGIN_REQUEST, loginFlowSaga);
  yield takeLeading(types.LOGOUT_REQUEST, logoutSaga);
  yield takeLeading(types.SIGNUP_REQUEST, signupUserSaga);
  yield takeLatest(types.FETCH_USER_DETAILS_REQUEST, userDetailsSaga);
  yield takeLatest(types.UPDATE_USER_DETAILS_REQUEST, updateUserDetailsSaga);
  yield takeLeading(types.CHANGE_PASSWORD_REQUEST, fetchApiSaga);
  yield takeLeading(types.FORGOT_PASSWORD_REQUEST, fetchApiSaga);
  yield takeLeading(types.RESET_PASSWORD_REQUEST, fetchApiSaga);
  yield takeLeading(types.VERIFY_ACCOUNT_REQUEST, fetchApiSaga);
}
