/* eslint-disable no-param-reassign */
import { createAction, mirrorKeysWithPfx, reducerFactory } from '@utils';

// ACTION-TYPES
const pureTypes = {
  FETCH_QUESTION_DETAILS_REQUEST: null,
  FETCH_QUESTION_DETAILS_SUCCESS: null,
  FETCH_QUESTION_DETAILS_FAILURE: null,
  CLEAR_QUESTIONS_DETAILS: null,

  REEVALUATE_QUESTION_REQUEST: null,
  REEVALUATE_QUESTION_SUCCESS: null,
  REEVALUATE_QUESTION_FAILURE: null,

  REVIEW_QUESTION_REQUEST: null,
  REVIEW_QUESTION_SUCCESS: null,
  REVIEW_QUESTION_FAILURE: null,
};
export const types = mirrorKeysWithPfx('questions', pureTypes);

// ACTIONS-CREATORS
export const actions = {
  fetchQuestionDetails: createAction(types.FETCH_QUESTION_DETAILS_REQUEST, 'fetchQuestionDetails'),
  clearQuestionDetails: createAction(types.CLEAR_QUESTIONS_DETAILS),
  reevaluateQuestion: createAction(types.REEVALUATE_QUESTION_REQUEST, 'reevaluateQuestion'),
  reviewQuestion: createAction(types.REVIEW_QUESTION_REQUEST, 'reviewQuestion'),
  reviewSuccess: createAction(types.REVIEW_QUESTION_SUCCESS),
  reviewFailure: createAction(types.REVIEW_QUESTION_FAILURE),
};

// REDUCER
const INITIAL_STATE = {
  data: {
    id: 0,
    estimatedTime: 0,
    difficulty: '',
    createdAt: '',
    tags: [],
    testCases: [],
    statement: '',
    title: '',
  },
};

const questionDetailsReducer = reducerFactory(INITIAL_STATE, {
  [types.FETCH_QUESTION_DETAILS_SUCCESS]: (state, action) => {
    const { qualityChecks } = action.payload;
    qualityChecks.sort((a, b) => {
      if (a.status === 'Failed' && b.status !== 'Failed') {
        return -1;
      }
      if (a.status !== 'Failed' && b.status === 'Failed') {
        return 1;
      }
      return 0;
    });
    state.data = action.payload;
  },
  [types.CLEAR_QUESTIONS_DETAILS]: (state) => {
    state.data = INITIAL_STATE.data;
  },

  [types.REEVALUATE_QUESTION_SUCCESS]: (state, action) => {
    state.data = action.payload;
  },
});

export default questionDetailsReducer;
