import { fork } from 'redux-saga/effects';

import rubricTemplatesListingSaga from './listing/sagas';
import rubricTemplatesCreateSaga from './create/sagas';
import rubricTemplateDetailsSaga from './details/sagas';

export default function* rubricTemplatesSagas() {
  yield fork(rubricTemplatesListingSaga);
  yield fork(rubricTemplatesCreateSaga);
  yield fork(rubricTemplateDetailsSaga);
}
