import { rest } from 'msw';

import { API_HOST } from '../core/constants';
import API_URLS from '../core/services/urls';

import {
  getPaymentPlans,
  getCurrentPaymentPlan,
} from './data/billing';
import {
  assessmentTemplateCreateResponse,
  assessmentTemplateList,
  assessmentTemplateUpdateResponse,
} from './data/assessment-templates';
import {
  candidateAttemptsMap,
  candidateReportsDetailsList,
  scheduledAssessmentReportsList,
} from './data/reports';
import {
  enrollmentsMap,
  unenrollResponse,
  enrollResponse,
} from './data/enrollments';
import { enrollmentStats, questionStats } from './data/stats';
import {
  loginResponse,
  accessToken,
  logoutResponse,
  resetPasswordResponse,
  forgotPasswordResponse,
  changePasswordResponse,
  signupResponse,
} from './data/auth';
import {
  organizationMembers,
  memberAddedSuccess,
  memberUpdatedSuccess,
  userRoles,
  userDetails,
} from './data/members';
import {
  questionsList,
  questionCreatedResponse,
  questionDeletedResponse,
  questionUpdatedResponse,
} from './data/questions';
import {
  scheduledAssessmentList,
  scheduledAssessmentCreateResponse,
  scheduledAssessmentDeleteResponse,
  scheduledAssessmentEditResponse,
} from './data/scheduled-assessments';
import {
  rubricTemplates,
  rubricCreateResponse,
  rubricUpdateResponse,
  rubricDeleteResponse,
} from './data/rubric-templates';

import { detailResponseCreator, listResponseCreator } from './utils';

/**
 * Create a mock request handler.
 *
 * @param {String} partialUrl Path of the url
 * @param {Object | Function } response The call response or a callback which returns the response.
 * @param {Object} options Configurable options.
 *                          It includes `host`, `status`, `method` for the api call.
 * @returns A msw request handler.
 */
const createHandler = (
  partialUrl,
  response,
  { host = API_HOST, method = 'get', status = 200 } = {
    host: API_HOST,
    method: 'get',
    status: 200,
  },
) => rest[method](`${host}${partialUrl}`, (req, res, ctx) => {
  let returnedResponse = null;
  let returnedStatus = status;
  try {
    if (typeof response === 'function') {
      returnedResponse = response(req, res, ctx);
    } else {
      returnedResponse = response;
    }
  } catch (error) {
    returnedResponse = error.response;
    returnedStatus = error.status;
  }
  return res(
    ctx.json(returnedResponse),
    ctx.status(returnedStatus),
    ctx.delay(1500),
  );
});

const handlers = [
  createHandler(API_URLS.LOGIN, loginResponse, { method: 'post' }),
  createHandler(API_URLS.LOGOUT, logoutResponse),
  createHandler(API_URLS.SIGNUP, signupResponse, { method: 'post' }),
  createHandler(API_URLS.ACCESS_TOKEN, accessToken, {
    method: 'post',
  }),
  createHandler(API_URLS.FORGOT_PASSWORD, forgotPasswordResponse, { method: 'post' }),
  createHandler(API_URLS.RESET_PASSWORD, resetPasswordResponse, { method: 'post' }),
  createHandler(API_URLS.CHANGE_PASSWORD, changePasswordResponse, { method: 'post' }),

  createHandler(API_URLS.QUESTIONS, listResponseCreator(questionsList)),
  createHandler(API_URLS.QUESTIONS, questionCreatedResponse, {
    method: 'post',
    status: 201,
  }),
  createHandler(`${API_URLS.QUESTIONS}:id/`, questionUpdatedResponse, {
    method: 'put',
    status: 200,
  }),
  createHandler(
    `${API_URLS.QUESTIONS}:id/`,
    detailResponseCreator(questionsList),
  ),
  createHandler(`${API_URLS.QUESTIONS}:id/`, questionDeletedResponse, {
    method: 'delete',
    status: 204,
  }),

  createHandler(
    API_URLS.TEMPLATES,
    listResponseCreator(assessmentTemplateList),
  ),
  createHandler(
    API_URLS.TEMPLATES,
    assessmentTemplateCreateResponse,
    { method: 'post', status: 201 },
  ),
  createHandler(
    `${API_URLS.TEMPLATES}:id`,
    detailResponseCreator(assessmentTemplateList),
  ),
  createHandler(
    `${API_URLS.TEMPLATES}:id`,
    assessmentTemplateUpdateResponse,
    { method: 'put' },
  ),

  createHandler(
    API_URLS.ASSESSMENTS,
    listResponseCreator(scheduledAssessmentList),
    { host: API_HOST },
  ),
  createHandler(
    API_URLS.ASSESSMENTS,
    scheduledAssessmentCreateResponse,
    {
      host: API_HOST,
      method: 'post',
      status: 201,
    },
  ),
  createHandler(
    `${API_URLS.ASSESSMENTS}:id`,
    detailResponseCreator(scheduledAssessmentList),
    { host: API_HOST },
  ),
  createHandler(
    `${API_URLS.ASSESSMENTS}:id`,
    scheduledAssessmentDeleteResponse,
    {
      host: API_HOST,
      method: 'delete',
      status: 204,
    },
  ),
  createHandler(
    `${API_URLS.ASSESSMENTS}:id`,
    scheduledAssessmentEditResponse,
    {
      host: API_HOST,
      method: 'put',
      status: 200,
    },
  ),

  createHandler(`${API_URLS.ENROLLMENTS}:id`, (req, res, ctx) => listResponseCreator(enrollmentsMap[req.params.id])(req, res, ctx)),
  createHandler(`${API_URLS.ENROLLMENTS}:id/enrol/`, enrollResponse, {
    method: 'post',
  }),
  createHandler(`${API_URLS.ENROLLMENTS}:id/unenrol/`, unenrollResponse, {
    method: 'post',
  }),

  createHandler(
    API_URLS.REPORTS,
    listResponseCreator(scheduledAssessmentReportsList),
  ),
  createHandler(
    `${API_URLS.REPORTS}:id/`,
    detailResponseCreator(scheduledAssessmentReportsList),
  ),
  createHandler(`${API_URLS.REPORTS}:id/attempts/`, (req, res, ctx) => listResponseCreator(candidateAttemptsMap[req.params.id])(req, res, ctx)),

  createHandler(
    `${API_URLS.CANDIDATE_REPORT}:id/`,
    detailResponseCreator(candidateReportsDetailsList),
  ),

  createHandler(
    API_URLS.ORG_MEMBERS,
    listResponseCreator(organizationMembers),
  ),
  createHandler(API_URLS.ORG_MEMBERS, memberAddedSuccess, {
    method: 'post',
  }),
  createHandler(API_URLS.ORG_MEMBERS, memberUpdatedSuccess, {
    method: 'put',
  }),
  createHandler(`${API_URLS.ORG_MEMBERS}user_details/`, userDetails),
  createHandler(
    API_URLS.USER_ROLES,
    userRoles,
  ),

  createHandler(API_URLS.STATS, (req) => (req.url.searchParams.get('org') === 'all'
    ? enrollmentStats.litmustestStats
    : enrollmentStats.orgStats)),
  createHandler(API_URLS.QUESTION_STATS, questionStats),
  createHandler(API_URLS.PAYMENT_PLANS, getPaymentPlans),
  createHandler(API_URLS.CURRENT_PLAN, getCurrentPaymentPlan),

  createHandler(
    API_URLS.RUBRIC_TEMPLATES,
    listResponseCreator(rubricTemplates),
  ),
  createHandler(
    `${API_URLS.RUBRIC_TEMPLATES}:id`,
    detailResponseCreator(rubricTemplates),
  ),
  createHandler(
    API_URLS.RUBRIC_TEMPLATES,
    rubricCreateResponse,
    {
      host: API_HOST,
      method: 'post',
      status: 201,
    },
  ),
  createHandler(
    `${API_URLS.RUBRIC_TEMPLATES}:id`,
    rubricUpdateResponse,
    {
      host: API_HOST,
      method: 'put',
      status: 200,
    },
  ),
  createHandler(
    `${API_URLS.RUBRIC_TEMPLATES}:id`,
    rubricDeleteResponse,
    {
      host: API_HOST,
      method: 'delete',
      status: 204,
    },
  ),
];

export default handlers;
