import { takeEvery, takeLeading, takeLatest } from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { types } from './ducks';
import {
  downloadReportSaga as downloadQuestionsSaga,
} from '../../shared/reportDownload/report-download-saga';

export default function* questionListingSaga() {
  yield takeLatest(types.FETCH_QUESTIONS_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_QUESTIONS_COUNT_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_ASSESSMENT_COUNT_REQUEST, fetchApiSaga);
  yield takeEvery(types.UPLOAD_QUESTIONS_FILE_REQUEST, fetchApiSaga);
  yield takeLeading(types.UPDATE_QUESTIONS_REQUEST, fetchApiSaga);
  yield takeEvery(types.DELETE_QUESTIONS_REQUEST, fetchApiSaga);
  yield takeEvery(types.RESTORE_QUESTIONS_REQUEST, fetchApiSaga);
  yield takeEvery(types.EXPORT_QUESTIONS_REQUEST, downloadQuestionsSaga);
}
