import APIClient from '@utils/axios';

import API_URLS from './urls';

const headers = {
  'Content-Type': 'application/json',
};

export const fetchRubricTemplates = (data) => {
  let url = data.pageSize
    ? `${API_URLS.RUBRIC_TEMPLATES}?page=${data.page}&page_size=${data.pageSize}`
    : `${API_URLS.RUBRIC_TEMPLATES}?page=${data.page}&page_size=0`;

  url = data.searchText
    ? `${url}&search=${data.searchText}`
    : url;

  url = data.sorting
    ? `${url}&ordering=${data.sorting}`
    : url;

  url = data.filtering
    ? `${url}&${data.filtering}`
    : url;

  return APIClient.get(url);
};

function addOrderNumToCriteria(rubricCriterias) {
  const updatedCriterias = [...rubricCriterias];
  let criteriaOrder = 0;
  updatedCriterias.forEach((criteria, i) => {
    updatedCriterias[i].orderNum = criteriaOrder;
    let optionOrder = 0;
    criteria.options.forEach((option, j) => {
      updatedCriterias[i].options[j].orderNum = optionOrder;
      optionOrder += 1;
    });
    criteriaOrder += 1;
  });
  return updatedCriterias;
}

export const createRubricTemplate = (payload) => {
  addOrderNumToCriteria(payload.rubricCriterias);
  const data = {
    ...payload,
    criterias: payload.rubricCriterias,
  };
  delete data.rubricCriterias;
  const { editID } = data;
  delete data.editID;

  return editID
    ? APIClient.put(`${API_URLS.RUBRIC_TEMPLATES}${editID}/`, data, { headers })
    : APIClient.post(API_URLS.RUBRIC_TEMPLATES, data, { headers });
};

export const fetchRubricTemplateDetails = (rubricTemplateId) => (
  APIClient.get(`${API_URLS.RUBRIC_TEMPLATES}${rubricTemplateId}/`)
);

export const deleteRubricTemplate = (rubricTemplateId) => (
  APIClient.delete(`${API_URLS.RUBRIC_TEMPLATES}${rubricTemplateId}/`)
);
