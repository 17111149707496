import {
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { fetchApiSaga } from '@utils';
import { types, actions } from './ducks';

function* enrollmentsUpdateApiSaga(action) {
  const response = yield call(fetchApiSaga, action);
  if (response.success) {
    const { assessmentId } = action.payload;

    yield put(actions.fetchEnrollments({ id: assessmentId }));
  }
}

function* scheduleUpdateApiSaga(action) {
  const response = yield call(fetchApiSaga, action);
  if (response.success) {
    const { assessmentId } = action.payload;

    if (action.payload.hasScheduleChanged) {
      yield put(actions.enrollCandidates(action.payload));
    }

    yield put(actions.fetchEnrollments({ id: assessmentId }));
    yield put(actions.fetchAssessmentDetails(assessmentId));
  }
}

export default function* AssessmentDetailsSaga() {
  yield takeEvery(types.UPDATE_ASSESSMENT_REQUEST, scheduleUpdateApiSaga);
  yield takeEvery(types.ENROLL_CANDIDATES_REQUEST, enrollmentsUpdateApiSaga);
  yield takeEvery(types.UNENROLL_CANDIDATES_REQUEST, enrollmentsUpdateApiSaga);
  yield takeLatest(types.FETCH_ASSESSMENT_DETAILS_REQUEST, fetchApiSaga);
  yield takeLatest(types.DELETE_ASSESSMENT_REQUEST, fetchApiSaga);
  yield takeLatest(types.FETCH_ENROLLMENTS_REQUEST, fetchApiSaga);
}
